import React from "react";
import PropTypes from "prop-types";
import { Pagination, Form } from "react-bootstrap";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import ChevronDoubleRightIcon from "mdi-react/ChevronDoubleRightIcon";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronDoubleLeftIcon from "mdi-react/ChevronDoubleLeftIcon";
import styled from "styled-components";
import {
  colorAccent,
  colorAdditional,
  colorBackground,
  colorFieldsBorder,
  colorIcon,
  colorText,
} from "@/utils/palette";
import { marginRight, marginLeft } from "@/utils/directions";
import { getScreenName } from "../../../helpers";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  updateColumns,
  updateNoOfRecords,
} from "../../../../features/columns/columnsSlice";
import { useEffect } from "react";
import {
  colorBlue,
  colorBlueHover,
  colorLightGray,
} from "../../../../utils/palette";
import { selectStylesNew } from "../../../constants/styles";
import rowSlice from "../../../../features/row/rowSlice";
import { postApi } from "../../../../api/getUser";
import { useAuth } from "../../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";

const ReactTablePagination = ({
  dataLength,
  page,
  gotoPage,
  canPreviousPage,
  pageOptions,
  pageSize,
  pageIndex,
  previousPage,
  nextPage,
  canNextPage,
  setPageSize,
  manualPageSize,
  screen,
  rows,
}) => {
  const { t } = useTranslation("common");
  const arrayPageIndex =
    pageIndex - 2 < 0
      ? pageOptions.slice(0, pageIndex + 3)
      : pageOptions.slice(pageIndex - 2, pageIndex + 3);

  const { order, minDate, maxDate, noOfRecords } = useSelector(
    (state) => state.columns
  );

  const { setLoading } = useAuth();

  const { totalRecords } = useSelector((state) => state.filter);
  const screensArr = [
    "inspectiontasks",
    "inspectionforms",
    "inspectionscheduling",
    "servicetasks",
    "expensetasks",
    "users",
    "vehicles",
    "dailytimesheet",
    "billing",
    "faultcodes",
  ];
  const clientSidePage = screensArr.some((val) => screen?.includes(val));
  //console.log('Client Side:', clientSidePage);
  //console.log(screen);
  //let clientSideRows = dataLength !== rows.length ? rows.length
  let noOfRows = clientSidePage ? rows.length : totalRecords;

  const dispatch = useDispatch();

  const handlePageSizeChange = async (e) => {
    setLoading(true);
    let screenName = getScreenName(screen);
    if (screen?.includes("vehicles")) {
      screenName = "vehicle";
    }
    const defaultRows = Number(e.target.value);
    const apiData = {
      default_rows: defaultRows,
      screen_name: screenName,
      querystring: "insert_default_rows",
    };
    const res = await postApi(apiData, "commonNew");
    if (res.success) {
      dispatch(updateNoOfRecords({ defaultRows }));
      //setPageSize(defaultRows);
    }
  };

  return (
    <PaginationWrap>
      <Pagination>
        <Pagination.First
          disabled={!canPreviousPage}
          className="pagination__item--arrow"
          onClick={() => gotoPage(0)}
        >
          <ChevronDoubleLeftIcon />
        </Pagination.First>
        <Pagination.Prev
          disabled={!canPreviousPage}
          className="pagination__item--arrow"
          onClick={previousPage}
        >
          <ChevronLeftIcon />
        </Pagination.Prev>
        {arrayPageIndex.map((i) => (
          <Pagination.Item
            id="page"
            key={i}
            active={pageIndex === i}
            onClick={() => gotoPage(i)}
          >
            {i + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          disabled={!canNextPage}
          className="pagination__item--arrow"
          onClick={nextPage}
        >
          <ChevronRightIcon />
        </Pagination.Next>
        <Pagination.Last
          disabled={!canNextPage}
          className="pagination__item--arrow"
          onClick={() => gotoPage(pageOptions.length - 1)}
        >
          <ChevronDoubleRightIcon />
        </Pagination.Last>
      </Pagination>
      <PaginationInfo>
        {/* Showing {pageSize * pageIndex + 1} to{" "}
        {pageSize * pageIndex + page.length} of {noOfRows} */}
        {t("showing_from_to_no_of_rows", {
          from: pageSize * pageIndex + 1,
          to: pageSize * pageIndex + page.length,
          noOfRows: screen.includes("parts")
            ? rows.length + Math.abs(totalRecords - rows.length)
            : noOfRows,
        })}
      </PaginationInfo>
      {manualPageSize.length > 1 && (
        <PaginationSelectWrap>
          <PaginationFormSelect
            name="select"
            id="exampleSelect"
            value={pageSize}
            // onChange={(event) => {
            //   const columnNames = order.map((item) => item - 1);
            //   const screenName = getScreenName(screen);
            //   const defaultRows = Number(event.target.value);
            //   const data = { columnNames, screenName, defaultRows };
            //   dispatch(updateColumns(data));

            //   //setPageSize(Number(event.target.value));
            // }}
            onChange={async (e) => handlePageSizeChange(e)}
          >
            {manualPageSize.map((item) => (
              <PaginationFormOption key={item} value={item} id="page-size">
                {/* Show {item} */}
                {t("show_no_of_rows", { noOfRows: item })}
              </PaginationFormOption>
            ))}
          </PaginationFormSelect>
        </PaginationSelectWrap>
      )}
    </PaginationWrap>
  );
};

ReactTablePagination.propTypes = {
  dataLength: PropTypes.number.isRequired,
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  gotoPage: PropTypes.func.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  pageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  manualPageSize: PropTypes.arrayOf(PropTypes.number),
};

ReactTablePagination.defaultProps = {
  manualPageSize: [10, 20, 30, 40],
};

export default ReactTablePagination;

// region STYLES

export const PaginationWrap = styled.div`
  //border-top: 1px solid;
  display: flex;
  align-items: center;
  padding: 8px;
  margin-left: 25px;
  //border-right: 1px solid ${colorLightGray};

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;

    & > * {
      margin: 10px 0;
    }
  }

  .page-item {
    ${marginRight}: 2px;

    .page-link {
      background: transparent;
      border: none;
      color: ${colorAdditional};
      height: 28px;
      font-size: 10px;
      padding: 5px;
      text-align: center;
      min-width: 28px;
      transition: all 0.3s;

      &:hover {
        color: ${colorBlueHover};
        background: transparent;
      }

      &:focus,
      &:active {
        box-shadow: none;
      }

      svg {
        width: 13px;
        height: 13px;
        fill: ${colorAdditional};
        transition: 0.3s;
        position: absolute;
        top: 7px;
        left: 8px;
      }
    }

    &.pagination__item--arrow .page-link {
      //border-radius: 50%;
      border-radius: 5px;
      width: 28px;
      height: 28px;
      //background: ${colorFieldsBorder};

      &:hover {
        background: ${colorLightGray};

        svg {
          fill: white;
        }
      }
    }

    &.pagination__item--arrow.disabled .page-link {
      cursor: default;
      opacity: 0.4;
      background: ${colorFieldsBorder};

      svg {
        fill: ${colorAdditional};
      }
    }

    &.active .page-link {
      background-color: transparent;
      font-weight: bold;
      color: ${colorText};
    }

    &.disabled .page-link svg {
      fill: ${colorIcon};
    }
  }
`;

export const PaginationInfo = styled.div`
  color: ${colorAdditional};
  font-size: 12px;
  ${marginRight}: 0;
  ${marginLeft}: 10px;
`;

export const PaginationSelectWrap = styled(PaginationInfo)`
  ${marginLeft}: 20px;
  color: ${colorAdditional};
`;

export const PaginationFormSelect = styled.select`
  color: ${colorAdditional};
  font-size: 12px;
  cursor: pointer;
  background-color: ${colorBackground};
  padding: 6px 10px;
  outline: none;
  border: 1px solid ${colorLightGray};
  border-radius: 5px;
  background: none;
  box-shadow: none;
  height: 32px;

  //appearance: none;
  //background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  &:focus {
    border-color: ${colorBlueHover};
  }
`;

export const PaginationFormOption = styled.option`
  color: ${colorAdditional};
  font-size: 14px;
`;

// endregion
