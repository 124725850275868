import React, { useRef, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Card, CardBody } from "@/shared/components/Card";
import {
  FormContainer,
  FormGroupLabel,
  FormHeading,
} from "@/shared/components/form/FormElements";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  FormItem,
  FormValueContainer,
  UnitSpan,
} from "../../../shared/components/form/FormElements";
import {
  buttonColorNew,
  colorAdditional,
  colorBackgroundBody,
  colorBlue,
  colorBlueHover,
  colorFormItemHover,
  colorGray,
  colorGreen,
  colorGreenHover,
  colorLightBlue,
  colorLightGray,
  colorTextNew,
  disabledBgColor,
  scrollbarStyles,
} from "../../../utils/palette";
import { useEffect } from "react";
import { post, postApi } from "../../../api/getUser";
import Error from "@/shared/components/form/Error";
import Tooltip from "@/shared/components/Tooltip";
import { useHistory, useLocation } from "react-router";
import FormField from "../../../shared/components/form/FormField";
import FormHeader from "../../../shared/components/form/FormHeader";
import {
  convertToDisplayDate,
  convertToTextDate,
  getDateFnsLocale,
  getDisplayDate,
  getDistanceUnit,
  handleNegative,
  handleNumberInputOnWheelPreventChange,
} from "../../../shared/helpers";
import {
  AddFormButton,
  FilterButton,
} from "../../../shared/components/TableElements";
import { formStyles } from "../../../shared/constants/styles";
import add from "date-fns/add";
import SelectTechnicianPopup from "../../../shared/components/form/SelectTechnicianPopup";
import CheckboxInputComponent from "../../../shared/components/form/CheckboxInputComponent";
import ServiceTasksFormPopup from "../../Services/ServiceTasksFormPopup";
import { updateServiceTaskOptions } from "../../Services/ServicesForm";
import getUnixTime from "date-fns/getUnixTime";
import sub from "date-fns/sub";
import { addMsg } from "../../../features/snackbar/snackbarSlice";
import { useDispatch } from "react-redux";
import { useAuth } from "../../../contexts/AuthContext";
import SubscribeTooltipIcon from "../../../shared/components/SubscribeTooltipIcon";
import SubscribePopup from "../../../shared/components/SubscribePopup";
import ErrorPopup from "../../../shared/components/ErrorPopup";

const RemindersForm = ({ isHorizontal, isAboveError }) => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const location = useLocation();
  const dispatch = useDispatch();
  //const { rowData, screen, type } = location.state;
  const rowData = location?.state?.rowData;
  const screen = location?.state?.screen;
  const type = location?.state?.type;

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const vehicles = vehiclesList.filter((v) => v.veh_active === 1);
  //.slice(1, vehiclesList.length + 1)

  const usersList = useSelector((state) => state.users.list);
  const { userId, orgId, roleId } = useSelector((state) => state.user);
  const { distance } = useSelector((state) => state.units);

  const { tag } = useAuth();

  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);
  const [subscribePopupMsg, setSubscribePopupMsg] = useState(
    t("subscribe_msg")
  );

  //React hook form fields
  const vehicle = watch("vehicle");
  const serviceTask = watch("serviceTask");
  const serviceIntervalOdo = watch("serviceIntervalOdo");
  const serviceIntervalDuration = watch("serviceIntervalDuration");
  const notes = watch("notes");
  const durationSI = watch("durationSI");
  const durationNM = watch("durationNM");
  const notifyMeOdo = watch("notifyMeOdo");
  const notifyMeDuration = watch("notifyMeDuration");
  const dueOnDate = watch("dueOnDate");
  const dueOnOdo = watch("dueOnOdo");
  const userEmail = watch("userEmail");
  //Hook form fields for scheduled WO
  const priority = watch("priority");
  const instructionsForWO = watch("instructionsForWO");

  const [dueOn, setDueOn] = useState({ odo: "", date: "" });

  const [dueOnPreview, setDueOnPreview] = useState({ odo: [], date: [] });
  const [notifyMePreview, setNotifyMePreview] = useState({ odo: [], date: [] });

  const [lastServiced, setLastServiced] = useState("");
  const [lastServicedText, setLastServicedText] = useState({
    header: "",
    desc: "",
    data: "",
  });

  const [schedulePreviewText, setSchedulePreviewText] = useState(
    t("schedule_preview_empty")
  );

  const [showDueOn, setShowDueOn] = useState(false);

  //To toggle error popup while adding/editing form
  const [showErrPopup, setShowErrPopup] = useState(false);

  const [lastServiceOdoData, setLastServiceOdoData] = useState();
  const [lastServiceDateData, setLastServiceDateData] = useState();

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const allServiceTasks = useSelector((state) => state.servicesTasks.list);
  const [serviceTaskOptions, setServiceTaskOptions] = useState([]);

  const firstTimeEdit = useRef(false);

  //State values for Create Work Order
  const [technician, setTechnician] = useState("");
  const [selectedTechnicianText, setSelectedTechnicianText] = useState("");
  const techniciansList = useSelector((state) => state.users.list).filter(
    (u) => u.role_id === 4
  );
  const [showTechnicianPopup, setShowTechnicianPopup] = useState(false);
  const [createWO, setCreateWO] = useState(false);

  //States for Create new service task
  const newTaskInfo = useRef("");
  const [newTaskId, setNewTaskId] = useState("");
  const [showAddTaskFormPopup, setShowAddTaskFormPopup] = useState(false);

  const [lastServiceCalc, setLastServiceCalc] = useState({
    odo: 0,
    date: new Date(),
  });

  const prioritiesOptions = [
    {
      value: 1,
      label: t("low"),
    },
    {
      value: 2,
      label: t("med"),
    },
    {
      value: 3,
      label: t("high"),
    },
  ];

  //initial Effect
  useEffect(() => {
    if (type !== "edit") {
      setValue("durationSI", "days");
      setValue("durationNM", "days");
    }
  }, []);

  //Effects to set Service Task that is newly created
  useEffect(() => {
    if (newTaskId) {
      (async () => {
        const apiData = {
          querystring: "getallservicetask",
          service_veh_id: vehicle.vehicleId,
        };

        const serviceTasksRes = await updateServiceTaskOptions(apiData);
        setServiceTaskOptions(serviceTasksRes.filter((t) => +t.recurring));
      })();
    }
  }, [newTaskId]);

  useEffect(() => {
    if (newTaskId) {
      let newServiceTask = serviceTaskOptions.find(
        (t) => t.service_task_id === newTaskId
      );
      console.log(newTaskId, newServiceTask);
      if (newServiceTask) {
        setValue("serviceTask", newServiceTask);
        setNewTaskId("");
      }
    }
  }, [serviceTaskOptions]);

  //Effect to update technician text on change of technician
  useEffect(() => {
    if (technician) {
      if (roleId === 4) {
        setSelectedTechnicianText(technician.label);
      } else {
        setSelectedTechnicianText(
          `${technician.label} (${
            technician.hourly_rate
          }/hour) - ${technician.starting_time?.slice(
            0,
            5
          )} - ${technician.ending_time?.slice(0, 5)}`
        );
      }
    }
  }, [technician]);

  //Effect to set vehicle in case of edit
  useEffect(() => {
    if (type === "edit" && vehiclesList?.length > 0) {
      firstTimeEdit.current = true;
      const {
        veh_id,
        service_task_id,
        last_date_of_service,
        last_odo_of_service,
      } = rowData;
      const selectedVehicle = vehicles.find(
        (veh) => veh.vehicleId === rowData.veh_id
      );
      setValue("vehicle", selectedVehicle);

      const lastServiceDate = last_date_of_service
        ? new Date(last_date_of_service * 1000)
        : new Date();
      setLastServiceCalc({ odo: last_odo_of_service, date: lastServiceDate });
    }
  }, [vehiclesList]);

  //Update Service Task List with Change in Vehicle
  useEffect(() => {
    (async () => {
      if (vehicle) {
        const apiData = {
          querystring: "getallservicetask",
          service_veh_id: vehicle?.vehicleId,
        };

        const serviceTasksRes = await updateServiceTaskOptions(apiData);

        setServiceTaskOptions(serviceTasksRes.filter((t) => +t.recurring));

        setValue("serviceTask", "");
        setValue("serviceIntervalOdo", "");
        setValue("serviceIntervalDuration", "");
        setValue("notifyMeDuration", "");
        setValue("notifyMeOdo", "");
        setDueOn({ odo: "", date: "" });

        if (type === "edit" && firstTimeEdit.current) {
          setValue(
            "serviceTask",
            serviceTasksRes.find(
              (t) => t.service_task_id === rowData.service_task_id
            )
          );
        }
      }
    })();
  }, [vehicle]);

  //Getting max_odo, lastservice...(form details) on change of vehicle or service task
  useEffect(() => {
    (async () => {
      if (vehicle?.vehicleId && serviceTask?.service_task_id) {
        const res = await postApi(
          {
            querystring: "get_veh_servicetask_last_service_date_v2",
            selected_veh_id: vehicle.vehicleId,
            selected_service_task_id: serviceTask.service_task_id,
          },
          "commonNew"
        );
        console.log("res", res);
        if (res.success) {
          const data = res.lastservicedata;
          const { max_service_odo, service_date } = data;
          setLastServiceOdoData(max_service_odo || 0);

          //Set lastservicecalc only in case of add
          if (type !== "edit") {
            setLastServiceCalc((prev) => ({
              ...prev,
              odo: max_service_odo || 0,
            }));
          }
          if (max_service_odo > 0 && service_date > 0) {
            setLastServiceDateData(new Date(service_date * 1000));
            //Set lastservicecalc only in case of add
            if (type !== "edit") {
              setLastServiceCalc((prev) => ({
                ...prev,
                date: new Date(service_date * 1000),
              }));
            }
            setLastServiced("last-serviced");
          } else if (max_service_odo > 0 && !service_date) {
            setLastServiceDateData(new Date());
            if (type !== "edit") {
              setLastServiceCalc((prev) => ({
                ...prev,
                date: new Date(),
              }));
            }

            setLastServiced("max-odo");
          } else if (!max_service_odo && !service_date) {
            setLastServiceDateData(new Date());
            if (type !== "edit") {
              setLastServiceCalc((prev) => ({
                ...prev,
                date: new Date(),
              }));
            }
            setLastServiced("no-value");
          }
        }

        if (type === "edit" && firstTimeEdit.current) {
          const {
            due_days,
            due_days_unit,
            due_odo,
            days_threshold,
            odo_threshold,
            emails,
            create_wo,
            assigned_technician,
            wo_instructions,
            wo_priority,
            description,
          } = rowData;
          setValue("serviceIntervalOdo", due_odo ? +due_odo : "");

          setValue("serviceIntervalDuration", due_days ? +due_days : "");

          //Confirm whether to send in days or convert to required period.
          //const inspectionIntervalUnit = getPeriodFromUnits(due_days_unit);
          setValue("durationSI", "days");

          setValue("notifyMeOdo", odo_threshold ? +odo_threshold : "");

          setValue("notifyMeDuration", days_threshold ? +days_threshold : "");

          //Confirm whether to send in days or convert to required period.
          //const notifyMeUnit = getPeriodFromUnits(odo_threshold);
          setValue("durationNM", "days");

          //Emails
          if (emails) {
            const userEmails = emails.split(":::");
            if (userEmails.length > 0) {
              let temp = [];
              usersList.forEach((user) => {
                if (userEmails.includes(user.email)) {
                  temp.push(user);
                }
              });
              setValue("userEmail", temp);
            }
          }

          //WO Data
          setCreateWO(create_wo === 1);
          const assignedTech = usersList.find(
            (u) => u.user_id === assigned_technician
          );
          setTechnician(assignedTech);
          setValue(
            "priority",
            prioritiesOptions.find((p) => p.value === wo_priority)
          );
          setValue("instructionsForWO", wo_instructions);

          setValue("notes", description);

          //firstTimeEdit.current = false;
        }
      }
    })();
  }, [serviceTask]);

  //Change Last Serviced Data text based on change in lastServiced
  useEffect(() => {
    if (lastServiced === "last-serviced") {
      setLastServicedText({
        header: t("last_serviced_on"),
        desc: t("last_serviced_on_desc"),
        data: `${lastServiceOdoData} ${getDistanceUnit(
          vehicle?.primary_meter === "Hours" ? "Hours" : distance
        )} (${convertToTextDate(lastServiceDateData)})
          `,
      });
    } else if (lastServiced === "max-odo") {
      setLastServicedText({
        header: t("current_odometer_reading"),
        desc: t("current_reading_desc"),
        data: `${lastServiceOdoData} ${getDistanceUnit(
          vehicle?.primary_meter === "Hours" ? "Hours" : distance
        )}`,
      });
    } else if (lastServiced === "no-value") {
      setLastServicedText({
        header: "",
        desc: t("current_reading_desc"),
        data: "",
      });
    }
  }, [lastServiced, lastServiceOdoData, lastServiceDateData]);

  //Effects to update inspectOn/dueOn(odo and date)
  useEffect(() => {
    if (serviceIntervalOdo >= 0) {
      let temp = +serviceIntervalOdo + +lastServiceCalc.odo;
      temp = temp.toFixed(2);
      setDueOn((prev) => ({
        ...prev,
        odo: temp,
      }));
    }
  }, [serviceIntervalOdo, lastServiceCalc]);

  useEffect(() => {
    const noOfDays = convertToDays(serviceIntervalDuration, durationSI);
    if (lastServiceCalc.date) {
      let temp = add(new Date(lastServiceCalc.date), { days: noOfDays });
      setDueOn((prev) => ({ ...prev, date: temp }));
    }
  }, [serviceIntervalDuration, durationSI, lastServiceCalc]);

  //Schedule Preview-Change in dueOnOdo and notifyMeOdo
  useEffect(() => {
    if (+dueOn.odo > 0) {
      setDueOnPreview((prev) => ({
        ...prev,
        odo: [
          Number(dueOn.odo).toFixed(2),
          (+dueOn.odo + +(serviceIntervalOdo * 1)).toFixed(2),
          (+dueOn.odo + +(serviceIntervalOdo * 2)).toFixed(2),
          (+dueOn.odo + +(serviceIntervalOdo * 3)).toFixed(2),
          (+dueOn.odo + +(serviceIntervalOdo * 4)).toFixed(2),
        ],
      }));
      if (+notifyMeOdo > 0 && +notifyMeOdo < +serviceIntervalOdo) {
        let notifyByOdo = +dueOn.odo - +notifyMeOdo;

        setNotifyMePreview((prev) => ({
          ...prev,
          odo: [
            Number(notifyByOdo).toFixed(2),
            (notifyByOdo + +(serviceIntervalOdo * 1)).toFixed(2),
            (notifyByOdo + +(serviceIntervalOdo * 2)).toFixed(2),
            (notifyByOdo + +(serviceIntervalOdo * 3)).toFixed(2),
            (notifyByOdo + +(serviceIntervalOdo * 4)).toFixed(2),
          ],
        }));
      } else {
        setNotifyMePreview((prev) => ({ ...prev, odo: [] }));
      }
    } else {
      //setDueOdoArray([]);
      setDueOnPreview((prev) => ({ ...prev, odo: [] }));
      setNotifyMePreview((prev) => ({ ...prev, odo: [] }));
    }
    //console.log(dueOdoArray, dueOn);
  }, [dueOn.odo, notifyMeOdo]);

  //Scheduled Preview-dueDate logic for dueDate/serviceIntervalDuration/durationUnit change
  useEffect(() => {
    //console.log(dueOn);
    let temp1 = new Date(dueOn.date);
    let temp2 = new Date(dueOn.date);
    let temp3 = new Date(dueOn.date);
    let temp4 = new Date(dueOn.date);
    let temp5 = new Date(dueOn.date);

    const noOfDays = convertToDays(serviceIntervalDuration, durationSI);

    temp1.setDate(temp1.getDate());
    temp2.setDate(temp2.getDate() + +noOfDays * 1);
    temp3.setDate(temp3.getDate() + +noOfDays * 2);
    temp4.setDate(temp4.getDate() + +noOfDays * 3);
    temp5.setDate(temp5.getDate() + +noOfDays * 4);

    setDueOnPreview((prev) => ({
      ...prev,
      date: [temp1, temp2, temp3, temp4, temp5],
    }));
  }, [dueOn.date, notifyMeDuration, durationNM]);

  useEffect(() => {
    const noOfDaysSI = convertToDays(+serviceIntervalDuration, durationSI);
    const noOfDaysNM = convertToDays(+notifyMeDuration, durationNM);
    const { date } = dueOnPreview;
    if (
      notifyMeDuration &&
      +notifyMeDuration > 0 &&
      dueOnPreview.date.length > 0 &&
      noOfDaysNM < noOfDaysSI
    ) {
      let n1 = new Date(date[0]);
      let n2 = new Date(date[1]);
      let n3 = new Date(date[2]);
      let n4 = new Date(date[3]);
      let n5 = new Date(date[4]);
      n1.setDate(n1.getDate() - noOfDaysNM);
      n2.setDate(n2.getDate() - noOfDaysNM);
      n3.setDate(n3.getDate() - noOfDaysNM);
      n4.setDate(n4.getDate() - noOfDaysNM);
      n5.setDate(n5.getDate() - noOfDaysNM);

      setNotifyMePreview((prev) => ({
        ...prev,
        date: [n1, n2, n3, n4, n5],
      }));
    } else {
      setNotifyMePreview((prev) => ({
        ...prev,
        date: [],
      }));
    }
  }, [dueOnPreview.date, notifyMeDuration, durationNM]);

  //Set Initial NotifyMe Values Based on Service Interval Values
  useEffect(() => {
    if (!serviceIntervalDuration && !serviceIntervalOdo) {
      setSchedulePreviewText(t("schedule_preview_empty"));
    } else if (serviceIntervalDuration > 0 || serviceIntervalOdo > 0) {
      setSchedulePreviewText(
        t("schedule_preview_filled", {
          last_serviced_text:
            lastServiced === "last-serviced"
              ? t("last_service_date_sm")
              : t("current_reading_sm"),
        })
      );
    }
    if (!firstTimeEdit.current && tag !== "free-user") {
      firstTimeEdit.current = false;
      if (serviceIntervalDuration) {
        const noOfDays = convertToDays(serviceIntervalDuration, durationSI);
        if (noOfDays >= 90) {
          setValue("notifyMeDuration", 15);
          setValue("durationNM", "days");
        }
      } else {
        setValue("notifyMeDuration", "");
      }

      if (serviceIntervalOdo) {
        if (serviceIntervalOdo >= 5000) {
          setValue("notifyMeOdo", 250);
        }
      } else {
        setValue("notifyMeOdo", "");
      }
    }
  }, [serviceIntervalDuration, serviceIntervalOdo, durationSI]);

  const validateMaxNotifyMe = (v) => {
    if (v) {
      return (
        convertToDays(+v, durationNM) <
        convertToDays(+serviceIntervalDuration, durationSI)
      );
    } else {
      return true;
    }
  };

  const handleEditIconClick = () => {
    clearErrors("dueOnOdo");
    setValue("dueOnDate", dueOn.date);
    setValue("dueOnOdo", dueOn.odo);
    setShowDueOn(true);
  };

  const handleSaveDueOn = () => {
    if (serviceIntervalOdo) {
      setError("dueOnOdo", {
        types: {
          required: t("required_err"),
          min: t("value_greater_than_interval_err_msg", {
            screenName: t("service_sm"),
          }),
        },
      });

      if (dueOnOdo && +dueOnOdo >= +serviceIntervalOdo) {
        clearErrors("dueOnOdo");
      }

      if (!errors.dueOnOdo) {
        let dueOn = dueOnOdo ? Number(dueOnOdo).toFixed(2) : 0;
        setDueOn({ date: dueOnDate, odo: dueOn });
        setShowDueOn(false);
      }
    } else {
      setDueOn((prev) => ({ ...prev, date: dueOnDate }));
      setShowDueOn(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      let reminderId =
        type === "edit"
          ? rowData?.reminder_id
          : `${vehicle?.vehicleId}_${Date.now()}`;

      const notifyMeDays = notifyMeDuration
        ? convertToDays(notifyMeDuration, durationNM)
        : 0;
      const serviceIntervalDays = serviceIntervalDuration
        ? convertToDays(serviceIntervalDuration, durationSI)
        : 0;

      let emailRecipients = "";
      if (userEmail) {
        userEmail.forEach((item) => {
          emailRecipients += item.email + ":::";
        });
      }
      emailRecipients = emailRecipients.slice(0, emailRecipients.length - 3);

      let lastOdoOfService = lastServiceCalc.odo ? lastServiceCalc.odo : 0;
      let lastDateOfService = lastServiceCalc.date
        ? getUnixTime(lastServiceCalc.date)
        : getUnixTime(new Date());

      if (serviceIntervalDuration) {
        const noOfDays = convertToDays(serviceIntervalDuration, durationSI);
        lastDateOfService = getUnixTime(
          sub(new Date(dueOn.date), { days: noOfDays })
        );
      }
      if (serviceIntervalOdo) {
        lastOdoOfService = +dueOn.odo - +serviceIntervalOdo;
      }

      let dueOnOdoVal = serviceIntervalOdo ? Number(dueOn.odo) : 0;
      let dueOnDateVal = serviceIntervalDuration ? getUnixTime(dueOn.date) : 0;

      let createWorkOrder = createWO ? 1 : 0;
      if (tag === "essential-user") {
        createWorkOrder = 0;
      }

      // const apiData = {
      //   source: "web",
      //   // veh_list_array:
      //   //   type === "edit"
      //   //     ? vehicle.vehicleId
      //   //     : JSON.stringify([vehicle.vehicleId]),
      //   veh_list_array: vehicle.vehicleId,
      //   due_on_odo: dueOnOdoVal,
      //   due_on_date: dueOnDateVal,
      //   timethreshold: +notifyMeDays,
      //   odothreshold: +notifyMeOdo ? +notifyMeOdo : 0,
      //   querystring: "add_reminder",
      //   emails: emailRecipients,
      //   emails_sent: 0,
      //   reminder_id: reminderId,
      //   service_task_id: serviceTask.service_task_id,
      //   expense_task_id: null,
      //   // last_date_of_service: JSON.stringify([lastDateOfService]),
      //   // last_odo_of_service: JSON.stringify([lastOdoOfService]),
      //   last_date_of_service: lastDateOfService,
      //   last_odo_of_service: +lastOdoOfService,
      //   due_days: +serviceIntervalDays ? +serviceIntervalDays : 0,
      //   due_odo: +serviceIntervalOdo ? +serviceIntervalOdo : 0,
      //   operation: type === "edit" ? "update" : "create",
      //   days_threshold: +notifyMeDays,
      //   odo_threshold: +notifyMeOdo ? +notifyMeOdo : 0,
      //   desc: notes ? notes : "",
      //   type: 0,
      //   action: type === "edit" ? 2 : 1,
      //   sync_version: "v3",
      //   create_wo: createWorkOrder,
      //   user_id: userId,
      //   assigned_technician: technician ? technician.user_id : "",
      //   wo_priority: priority ? priority.value : 0,
      //   wo_instructions: instructionsForWO ? instructionsForWO : "",
      // };

      const apiData = {
        sync_version: "v3",
        source: "web",
        querystring: "add_reminder_v2",
        operation: type === "edit" ? "update" : "create",
        reminders: [
          {
            veh_id: vehicle.vehicleId,
            due_on_odo: dueOnOdoVal,
            due_on_date: dueOnDateVal,
            //querystring: "add_reminder",
            emails: emailRecipients,
            emails_sent: 0,
            reminder_id: reminderId,
            service_task_id: serviceTask.service_task_id,
            expense_task_id: null,
            last_date_of_service: lastDateOfService,
            last_odo_of_service: +lastOdoOfService,
            due_days: +serviceIntervalDays || 0,
            due_odo: +serviceIntervalOdo || 0,
            days_threshold: +notifyMeDays,
            odo_threshold: +notifyMeOdo || 0,
            desc: notes || "",
            type: 0,
            sync_version: "v3",
            create_wo: createWorkOrder,
            user_id: userId,
            assigned_technician: technician ? technician.user_id : "",
            wo_priority: priority ? priority.value : 0,
            wo_instructions: instructionsForWO || "",
            action: type === "edit" ? 2 : 1,
          },
        ],
      };

      setSaveBtnLoading(true);
      const response = await post("commonNew", apiData);
      const snackbarMsg =
        type === "edit"
          ? t("record_edited_msg", {
              recordName: t("maintenance", { count: 1 }),
            })
          : t("record_added_msg", {
              recordName: t("maintenance", { count: 1 }),
            });

      if (!response?.success) {
        setSaveBtnLoading(false);
        setShowErrPopup(true);
        return;
      }
      if (response.success === 1) {
        dispatch(addMsg(snackbarMsg));
        setSaveBtnLoading(false);
        history.push("/reminders/maintenancereminders", { fromCancel: false });
      }
    } catch (e) {
      setSaveBtnLoading(false);
      setShowErrPopup(true);
      return;
    }
  };

  const handleCreate = (value) => {
    newTaskInfo.current = { taskName: value, vehicleId: vehicle.vehicleId };
    setShowAddTaskFormPopup(true);
  };

  const handleCancel = () => {
    reset({
      vehicle: "",
      serviceTask: "",
      serviceIntervalOdo: "",
      serviceIntervalDuration,
      notifyMeOdo: "",
      notifyMeDuration: "",
      durationSI: "days",
      durationNM: "days",
      dueOnOdo: "",
      dueOnDate: "",
      userEmail: "",
      notes: "",
    });

    history.push("/reminders/maintenancereminders", { fromCancel: true });
  };

  const inputStyles = {
    //borderColor: colorLightGray,
    color: colorTextNew,
    border: "none",
    borderRadius: 5,
    fontSize: "14px",
    width: "100%",
    outline: "none",
    //width: '200px'
  };

  return (
    <>
      <ServiceTasksFormPopup
        showPopup={showAddTaskFormPopup}
        setShowPopup={setShowAddTaskFormPopup}
        newTaskInfo={newTaskInfo}
        from={"add-maintenance-reminder"}
        //newServiceTaskId={newTaskId}
        setNewServiceTaskId={setNewTaskId}
      />
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={subscribePopupMsg}
        />
      )}
      {showErrPopup && (
        <ErrorPopup showPopup={showErrPopup} setShowPopup={setShowErrPopup} />
      )}
      <FormContainer
        horizontal={false}
        onSubmit={handleSubmit(onSubmit)}
        //style={{ height: "80vh" }}
      >
        <FormHeader
          title={type === "edit" ? t("edit_reminder") : t("add_reminder")}
          //saveButtonText={"Save Reminder"}
          handleCancel={handleCancel}
          disableSave={!serviceTask}
          isSaveLoading={saveBtnLoading}
        />
        {/* </CardBody> */}
        {/* </Card> */}

        <Container>
          <Row>
            <Col
              md={vehicle && serviceTask ? 8 : 12}
              style={{ paddingRight: vehicle && serviceTask ? "0" : "12px" }}
            >
              <Card>
                <CardBody
                  style={{
                    overflowY: "scroll",
                    height: `${window.innerHeight - 150}px`,
                    padding: 0,
                    overflowX: "hidden",
                    //scrollbarStyles,
                  }}
                >
                  <Row className="px-3">
                    <Col md={vehicle && serviceTask ? 6 : 4}>
                      <div>
                        {/* <i className="fi fi-rr-home"></i> */}
                        <FormGroupLabel>{t("vehicle")}</FormGroupLabel>
                        <Controller
                          name="vehicle"
                          control={control}
                          //errors={errors}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              options={vehicles}
                              //value={vehicles.find((c) => c.value === value)}
                              value={value}
                              onChange={onChange}
                              onCreateOption={handleCreate}
                              placeholder={t("vehicle_placeholder")}
                              styles={formStyles}
                              id="vehicle-dropdown"
                            />
                          )}
                          rules={{ required: t("required_err") }}
                        />
                      </div>
                      {errors?.vehicle && (
                        <Error error={errors.vehicle.message} />
                      )}
                    </Col>
                    <Col md={vehicle && serviceTask ? 6 : 4}>
                      <div>
                        <FormGroupLabel>{t("service_task")}</FormGroupLabel>
                        <Controller
                          name="serviceTask"
                          control={control}
                          errors={errors}
                          render={({ field: { onChange, value, ref } }) => (
                            <CreatableSelect
                              options={serviceTaskOptions}
                              value={value}
                              onChange={onChange}
                              onCreateOption={handleCreate}
                              placeholder={t("service_task_placeholder")}
                              styles={formStyles}
                              isDisabled={vehicle ? false : true}
                              components={{ Option: SingleValueOption }}
                              id="service-task-dropdown"
                            />
                          )}
                          rules={{ required: t("required_err") }}
                        />
                      </div>
                      {errors?.serviceTask && (
                        <Error error={errors.serviceTask.message} />
                      )}
                    </Col>
                  </Row>
                  {vehicle && serviceTask && (
                    <>
                      <div className="px-3 pt-3 d-flex flex-column gap-1">
                        {lastServiced !== "no-value" && (
                          <>
                            <p style={{ fontSize: "12px", fontWeight: 300 }}>
                              {lastServicedText.header}
                            </p>
                            <p style={{ fontSize: "14px", fontWeight: 500 }}>
                              {lastServicedText.data}
                            </p>
                          </>
                        )}
                        <Desc>{lastServicedText.desc}</Desc>
                      </div>
                      <hr />
                      <Row className="px-3">
                        <h5>{t("service_interval")}</h5>
                        <FormGroupLabel>
                          {t("service_every")}
                          {/* <Tooltip text="Set a Reminder once in every...">
                            <Info />
                          </Tooltip> */}
                        </FormGroupLabel>
                        <Col md={4}>
                          <FormItem
                          //tabIndex={0}
                          >
                            <FormField
                              name="serviceIntervalOdo"
                              control={control}
                              component="input"
                              type="number"
                              onKeyDown={handleNegative}
                              onWheel={handleNumberInputOnWheelPreventChange}
                              rules={{
                                required:
                                  +serviceIntervalDuration ||
                                  +serviceIntervalOdo
                                    ? false
                                    : t("odo_or_duration_err"),
                                min: {
                                  value: 0,
                                  message: t("greater_than_zero_err"),
                                },
                              }}
                              defaultValue=""
                              isAboveError={isAboveError}
                              placeholder={t("odometer")}
                              style={inputStyles}
                              // onKeyUp={() =>
                              //   setValue(
                              //     "dueOdo",
                              //     +lastServiceOdo + +serviceIntervalOdo
                              //   )
                              // }
                              //onKeyUp={handleSIOdoChange}
                            />
                            <UnitSpan>
                              {getDistanceUnit(
                                vehicle?.primary_meter === "Hours"
                                  ? "Hours"
                                  : distance
                              )}
                            </UnitSpan>
                            {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                          </FormItem>
                          {/* {errors?.serviceIntervalOdo && (
                            <Error error={errors.serviceIntervalOdo.message} />
                          )} */}
                        </Col>
                        <Col
                          md={1}
                          className="d-flex justify-content-center align-items-center p-0"
                        >
                          <span>{t("or")}</span>
                        </Col>
                        <Col md={4}>
                          <FormItem>
                            <FormField
                              name="serviceIntervalDuration"
                              control={control}
                              component="input"
                              type="number"
                              onKeyDown={handleNegative}
                              onWheel={handleNumberInputOnWheelPreventChange}
                              errors={errors}
                              rules={{
                                required:
                                  +serviceIntervalOdo ||
                                  +serviceIntervalDuration
                                    ? false
                                    : t("odo_or_duration_err"),
                                min: {
                                  value: 0,
                                  message: t("greater_than_zero_err"),
                                },
                              }}
                              defaultValue=""
                              isAboveError={isAboveError}
                              placeholder={t("duration")}
                              style={inputStyles}
                              //onKeyUp={handleSIDurationChange}
                            />
                            <TimeSelect
                              {...register("durationSI")}
                              defaultValue="days"
                            >
                              <option value="days">{t("days")}</option>
                              <option value="weeks">{t("weeks")}</option>
                              <option value="months">{t("months")}</option>
                              <option value="years">{t("years")}</option>
                            </TimeSelect>
                          </FormItem>
                          {/* {errors?.serviceIntervalDuration && (
                            <Error
                              error={errors.serviceIntervalDuration.message}
                            />
                          )} */}
                        </Col>
                        <Col
                          md={3}
                          className="d-flex justify-content-start align-items-center p-0"
                        >
                          <span>{t("whichever_comes_first")}</span>
                        </Col>
                      </Row>
                      {(errors.serviceIntervalOdo ||
                        errors.serviceIntervalDuration) && (
                        <Row className="px-3">
                          <Col md={4}>
                            {errors?.serviceIntervalOdo && (
                              <Error
                                error={errors.serviceIntervalOdo.message}
                              />
                            )}
                          </Col>
                          <Col md={1}></Col>
                          <Col md={4}>
                            {errors?.serviceIntervalDuration && (
                              <Error
                                error={errors.serviceIntervalDuration.message}
                              />
                            )}
                          </Col>
                        </Row>
                      )}
                      <Row className="px-3 pt-3">
                        <Col className="d-flex gap-1">
                          <CheckboxInputComponent
                            checked={createWO}
                            setChecked={setCreateWO}
                            label={t("auto_create_wo_when_service_is_due")}
                            id={"create-work-order"}
                            disabled={tag === "essential-user"}
                          />
                          {tag === "essential-user" && (
                            <SubscribeTooltipIcon
                              message={t("subscribe_to_advanced_msg")}
                              handleClick={() => {
                                setSubscribePopupMsg(
                                  t("subscribe_to_advanced_msg")
                                );
                                setShowSubscribePopup(true);
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                      {createWO && (
                        <>
                          <Row className="px-3 pt-3">
                            <Col>
                              {/* <FormGroupLabel>{t("technician")}</FormGroupLabel> */}
                              <FormValueContainer>
                                {/* {!technician && (
                                <p className="p-0 m-0">{NATEXT}</p>
                              )} */}
                                {technician && (
                                  <p style={{ marginRight: "0.5rem" }}>
                                    {selectedTechnicianText}
                                  </p>
                                )}
                                {roleId !== 4 && (
                                  <FilterButton
                                    style={{ display: "block" }}
                                    type="button"
                                    onClick={() => setShowTechnicianPopup(true)}
                                  >
                                    {technician
                                      ? t("change")
                                      : t("select_tech")}
                                  </FilterButton>
                                )}
                              </FormValueContainer>
                            </Col>
                          </Row>
                          <Row className="px-3">
                            <Col md={4}>
                              {/* Priority */}
                              <FormGroupLabel>{t("priority")}</FormGroupLabel>

                              <Controller
                                name="priority"
                                control={control}
                                //errors={errors}
                                render={({
                                  field: { onChange, value, ref },
                                }) => (
                                  <Select
                                    options={prioritiesOptions}
                                    //value={vehicles.find((c) => c.value === value)}
                                    value={value}
                                    onChange={onChange}
                                    //onCreateOption={handleCreate}
                                    placeholder={t("priority_placeholder")}
                                    styles={formStyles}
                                    // isDisabled={
                                    //   (type === "edit" && roleId === 4) ||
                                    //   workOrderStatus === 3
                                    // }
                                  />
                                )}
                                rules={{ required: t("required_err") }}
                              />

                              {errors?.priority && (
                                <Error error={errors.priority.message} />
                              )}
                            </Col>
                          </Row>
                          <Row className="px-3">
                            <Col md={12}>
                              <FormGroupLabel>
                                {t("instructions_for_work_order")}
                              </FormGroupLabel>
                              <FormItem disabled={false}>
                                <FormField
                                  name="instructionsForWO"
                                  control={control}
                                  component="input"
                                  disabled={false}
                                  //errors={errors}

                                  defaultValue=""
                                  //isAboveError={isAboveError}
                                  placeholder={t("instructions_for_work_order")}
                                  style={inputStyles}
                                />
                              </FormItem>
                            </Col>
                          </Row>
                        </>
                      )}
                      <hr />
                      <Row className="px-3">
                        <div className="d-flex align-items-center">
                          <h5>{t("notification_settings")}</h5>
                          {tag === "free-user" && (
                            <div
                              //className="mb-1"
                              style={{ marginBottom: "0.1rem" }}
                            >
                              <SubscribeTooltipIcon
                                message={t("subscribe_msg")}
                                handleClick={() => {
                                  setSubscribePopupMsg(t("subscribe_msg"));
                                  setShowSubscribePopup(true);
                                }}
                              />
                            </div>
                          )}
                        </div>

                        <Desc
                          style={{ paddingTop: "4px" }}
                          className="d-flex gap-1"
                        >
                          <span>{t("notifications_email_msg")}</span>
                          {tag === "free-user" && (
                            <span>{`(${t("paid_feature_str_sm")})`}</span>
                          )}
                        </Desc>
                        <FormGroupLabel>
                          {t("notify_me")}
                          {/* <span>
                            <Tooltip text="Remind me every..." placement="top">
                              <Info />
                            </Tooltip>
                          </span> */}
                        </FormGroupLabel>
                        {/* <FormGroupField> */}
                        <Col>
                          <FormItem
                            disabled={
                              !serviceIntervalOdo || tag === "free-user"
                            }
                          >
                            <FormField
                              name="notifyMeOdo"
                              control={control}
                              component="input"
                              type="number"
                              onKeyDown={handleNegative}
                              onWheel={handleNumberInputOnWheelPreventChange}
                              disabled={
                                !serviceIntervalOdo || tag === "free-user"
                              }
                              //errors={errors}
                              rules={{
                                min: {
                                  value: 0,
                                  message: t("greater_than_zero_err"),
                                },
                                max: {
                                  value: +serviceIntervalOdo - 1,
                                  message: t("greater_than_interval_err", {
                                    type: t("service_sm"),
                                  }),
                                },
                              }}
                              defaultValue=""
                              //isAboveError={isAboveError}
                              placeholder={t("odometer")}
                              style={inputStyles}
                            />
                            <UnitSpan>
                              {getDistanceUnit(
                                vehicle?.primary_meter === "Hours"
                                  ? "Hours"
                                  : distance
                              )}
                            </UnitSpan>
                          </FormItem>
                          {/* {errors?.notifyMeOdo && (
                            <Error error={errors.notifyMeOdo.message} />
                          )} */}
                        </Col>
                        <Col
                          md={1}
                          className="d-flex justify-content-center align-items-center p-0"
                        >
                          <span>{t("or")}</span>
                        </Col>
                        <Col>
                          <FormItem
                            disabled={
                              !serviceIntervalDuration || tag === "free-user"
                            }
                          >
                            <FormField
                              name="notifyMeDuration"
                              control={control}
                              component="input"
                              type="number"
                              onKeyDown={handleNegative}
                              onWheel={handleNumberInputOnWheelPreventChange}
                              disabled={
                                !serviceIntervalDuration || tag === "free-user"
                              }
                              errors={errors}
                              rules={{
                                min: {
                                  value: 0,
                                  message: t("greater_than_zero_err"),
                                },
                                // max: {
                                //   value: getMaxNotifyMeDuration(),
                                //   message:
                                //     "Value cannot be greater than service interval.",
                                // },
                                validate: {
                                  maxValue: (v) =>
                                    validateMaxNotifyMe(v) ||
                                    t("greater_than_interval_err", {
                                      type: t("service_sm"),
                                    }),
                                },
                              }}
                              defaultValue=""
                              isAboveError={isAboveError}
                              placeholder={t("duration")}
                              style={inputStyles}
                            />
                            <TimeSelect
                              {...register("durationNM")}
                              defaultValue="days"
                              disabled={
                                !serviceIntervalDuration || tag === "free-user"
                              }
                              onClick={() => {
                                setError("notifyMeDuration", {
                                  type: "maxValue",
                                });
                              }}
                            >
                              <option value="days">{t("days")}</option>
                              <option value="weeks">{t("weeks")}</option>
                              <option value="months">{t("months")}</option>
                              <option value="years">{t("years")}</option>
                            </TimeSelect>
                          </FormItem>
                        </Col>
                        <Col
                          md={3}
                          className="d-flex justify-content-start align-items-center p-0"
                        >
                          <span>{t("before_service_schedule")}</span>
                        </Col>
                      </Row>
                      {(errors.notifyMeOdo || errors.notifyMeDuration) && (
                        <Row className="px-3">
                          <Col md={4}>
                            {errors?.notifyMeOdo && (
                              <Error error={errors.notifyMeOdo.message} />
                            )}
                          </Col>
                          <Col md={1}></Col>
                          <Col md={4}>
                            {errors?.notifyMeDuration && (
                              <Error error={errors.notifyMeDuration.message} />
                            )}
                          </Col>
                        </Row>
                      )}
                      <Row className="px-3">
                        <FormGroupLabel>
                          {t("send_notification_to")}
                          {/* <Tooltip text={t("premium_feature")}>
                            <DollarIcon className="fi fi-ss-usd-circle"></DollarIcon>
                          </Tooltip> */}
                        </FormGroupLabel>
                        <Col>
                          <Controller
                            name="userEmail"
                            control={control}
                            errors={errors}
                            render={({ field: { onChange, value, ref } }) => (
                              <Select
                                // options={usersList.filter(
                                //   (u) => u.role_id !== 4
                                // )}
                                options={usersList}
                                //value={usersList.find((c) => c.value === value)}
                                value={value}
                                onChange={onChange}
                                placeholder={t("noti_recipients")}
                                styles={formStyles}
                                isMulti
                                closeMenuOnSelect={false}
                                //hideSelectedOptions={false}
                                // components={{
                                //   Option,
                                // }}
                                isDisabled={tag === "free-user"}
                              />
                            )}
                            // rules={{ required: true }}
                          />
                        </Col>
                      </Row>
                      <Row className="px-3">
                        <FormGroupLabel>
                          {t("notification_email_preview")}
                        </FormGroupLabel>
                        {(+serviceIntervalOdo > 0 ||
                          +serviceIntervalDuration > 0) &&
                          tag !== "free-user" && (
                            <EmailPreviewText>
                              {/* {t("email_reminder_msg")}{" "}
                              <strong>{vehicle.label}</strong>{" "}
                              {t("is_due_for_sm")}{" "}
                              <strong>{serviceTask.label}</strong> */}
                              <Trans
                                i18nKey="email_reminder_msg"
                                t={t}
                                values={{
                                  vehicleName: vehicle.label,
                                  taskName: serviceTask.label,
                                }}
                              />
                              {notifyMeOdo > 0 &&
                                notifyMePreview.odo.length > 0 && (
                                  <>
                                    <span> {t("in")} </span>
                                    <strong>{`${notifyMeOdo} ${getDistanceUnit(
                                      vehicle?.primary_meter === "Hours"
                                        ? "Hours"
                                        : distance
                                    )}`}</strong>
                                  </>
                                )}
                              {notifyMeOdo > 0 &&
                                notifyMePreview.odo.length > 0 &&
                                notifyMePreview.date.length > 0 &&
                                serviceIntervalDuration &&
                                ` ${t("or")} `}
                              {notifyMeDuration > 0 &&
                                notifyMePreview.date.length > 0 && (
                                  <>
                                    <span> {t("in")} </span>
                                    <strong>
                                      {convertToDays(
                                        notifyMeDuration,
                                        durationNM
                                      )}{" "}
                                      {t("days_sm", {
                                        count: convertToDays(
                                          notifyMeDuration,
                                          durationNM
                                        ),
                                      })}
                                    </strong>
                                  </>
                                )}
                              {notifyMeOdo > 0 &&
                                notifyMeDuration > 0 &&
                                notifyMePreview.odo.length > 0 &&
                                notifyMePreview.date.length > 0 &&
                                `, ${t("whichever_comes_first")}`}
                              .{" "}
                              {lastServiced === "last-serviced" && (
                                <>
                                  {/* <span>{t("last_service_done_on")}</span>
                                  <strong>
                                    {" "}
                                    {convertToDisplayDate(lastServiceDateData)}
                                  </strong>
                                  {`. `} */}
                                  <Trans
                                    i18nKey="last_service_done_on"
                                    t={t}
                                    values={{
                                      lastServicedDate:
                                        convertToDisplayDate(
                                          lastServiceDateData
                                        ),
                                    }}
                                  />{" "}
                                </>
                              )}
                              {t("email_log_msg_service")}
                            </EmailPreviewText>
                          )}
                        <Col>
                          <Controller
                            name="notes"
                            control={control}
                            errors={errors}
                            render={({ field: { onChange, value, ref } }) => (
                              <Textarea
                                rows={2}
                                id="notes"
                                name="notes"
                                value={value}
                                onChange={onChange}
                                placeholder={t("email_preview_placeholder")}
                                disabled={tag === "free-user"}
                              ></Textarea>
                            )}
                          />
                        </Col>
                      </Row>

                      {/* Create Work Order */}
                      {/* <hr />
                      <Row className="px-3">
                        <h5>{t("create_work_order")}</h5>
                        <Desc style={{ paddingTop: "4px" }}>
                          Create a work order for this service
                        </Desc>
                        <Col className={createWO ? "pt-3" : "py-3"}>
                          <CheckboxInputComponent
                            checked={createWO}
                            setChecked={setCreateWO}
                            label={"Create a Work Order"}
                            id={"create-work-order"}
                          />
                        </Col>
                      </Row> */}

                      {showTechnicianPopup && (
                        <SelectTechnicianPopup
                          showPopup={showTechnicianPopup}
                          setShowPopup={setShowTechnicianPopup}
                          technician={technician}
                          setTechnician={setTechnician}
                          techniciansList={techniciansList}
                        />
                      )}
                    </>
                  )}
                  {/* </FormContainer> */}
                </CardBody>
              </Card>
            </Col>

            {vehicle && serviceTask && (
              <Col md={4}>
                <Card>
                  <CardBody>
                    <div>
                      <FormHeading>{t("schedule_preview")}</FormHeading>
                      <Desc>{schedulePreviewText}</Desc>
                      {((serviceIntervalOdo && +serviceIntervalOdo > 0) ||
                        (serviceIntervalDuration &&
                          +serviceIntervalDuration > 0)) && (
                        <>
                          <ServicesPreviewHeading>
                            {t("next_5_services_due_on")}
                            {/* <span style={{ fontSize: "12px" }}>
                              {" "}
                              ({t("whichever_comes_first")})
                            </span> */}
                          </ServicesPreviewHeading>
                          <hr style={{ margin: "5px 0" }} />

                          {[0, 1, 2, 3, 4].map((num) => {
                            return (
                              <React.Fragment key={num}>
                                <DueOnWrap>
                                  {+serviceIntervalOdo > 0 && dueOn.odo && (
                                    <span>{`${
                                      dueOnPreview.odo[num]
                                    } ${getDistanceUnit(
                                      vehicle?.primary_meter === "Hours"
                                        ? "Hours"
                                        : distance
                                    )}`}</span>
                                  )}
                                  {+serviceIntervalDuration > 0 &&
                                    +serviceIntervalOdo > 0 && (
                                      <span> {t("or")} </span>
                                    )}
                                  <span>
                                    {+serviceIntervalDuration > 0 && dueOn.date
                                      ? convertToTextDate(
                                          dueOnPreview.date[num]
                                        )
                                      : ""}
                                  </span>
                                  {num === 0 && (
                                    <EditIcon
                                      className="fi fi-rr-pencil"
                                      onClick={handleEditIconClick}
                                    ></EditIcon>
                                  )}
                                </DueOnWrap>
                                {(notifyMePreview.date.length > 0 ||
                                  notifyMePreview.odo.length > 0) && (
                                  <NotifyMeWrap>
                                    <i className="fi fi-rr-clock"></i>
                                    <span>{t("notify_by")} </span>

                                    {!!notifyMeOdo &&
                                      +notifyMeOdo > 0 &&
                                      +notifyMeOdo < +serviceIntervalOdo && (
                                        <span>
                                          {notifyMePreview.odo[num]}{" "}
                                          {getDistanceUnit(
                                            vehicle?.primary_meter === "Hours"
                                              ? "Hours"
                                              : distance
                                          )}
                                        </span>
                                      )}

                                    {+notifyMeDuration > 0 &&
                                      +notifyMeOdo > 0 &&
                                      +notifyMeOdo < +serviceIntervalOdo &&
                                      convertToDays(
                                        notifyMeDuration,
                                        durationNM
                                      ) <
                                        convertToDays(
                                          serviceIntervalDuration,
                                          durationSI
                                        ) && <span> {t("or")} </span>}
                                    {notifyMeDuration > 0 &&
                                      notifyMePreview.date.length > 0 && (
                                        <span>
                                          {/* {getDisplayDate(
                                            getUnixTime(
                                              notifyMePreview.date[num]
                                            )
                                          )} */}
                                          {convertToTextDate(
                                            notifyMePreview.date[num]
                                          )}
                                        </span>
                                      )}
                                  </NotifyMeWrap>
                                )}
                                <hr style={{ margin: "5px 0" }} />
                              </React.Fragment>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
          <Modal
            show={showDueOn}
            onHide={() => setShowDueOn(!showDueOn)}
            centered
          >
            <Modal.Header>
              <p>{t("next_service_due_on")}</p>
              {/* <ActionIconWrap onClick={() => setShowDueOn(!showDueOn)}>
                <CrossSmallIcon />
              </ActionIconWrap> */}
              <CloseIcon
                className="fi fi-rr-cross-small"
                onClick={() => setShowDueOn(!showDueOn)}
              ></CloseIcon>
            </Modal.Header>
            <Modal.Body>
              <Row>
                {dueOn.odo && serviceIntervalOdo && (
                  <Col md={5}>
                    {/* <FormItem>
                      <input
                        type="number"
                        placeholder={t("odometer")}
                        value={dueOnOdo}
                        onChange={(e) => setDueOnOdo(e.target.value)}
                        style={{
                          border: "none",
                          outline: "none",
                          margin: "0 0 0 8px",
                          width: "100%",
                        }}
                      />
                      <UnitSpan>Km</UnitSpan>
                    </FormItem> */}
                    <FormItem
                    //onFocus={() => setDueOnOdoFocus(true)}
                    //onBlur={() => setDueOnOdoFocus(false)}
                    //focus={dueOnOdoFocus}
                    >
                      <FormField
                        name="dueOnOdo"
                        control={control}
                        component="input"
                        type="number"
                        onKeyDown={handleNegative}
                        onWheel={handleNumberInputOnWheelPreventChange}
                        errors={errors}
                        //rules={{ required: "This is required field" }}
                        rules={{
                          required: t("required_err"),
                          // min: {
                          //   value: +dueOn.odo,
                          //   message: t("negative_value_err_msg"),
                          // },
                        }}
                        defaultValue={dueOn.odo}
                        isAboveError={isAboveError}
                        placeholder={t("odometer")}
                        style={{
                          ...inputStyles,
                          padding: "5px 10px",
                          lineHeight: 0,
                        }}
                      />
                      <UnitSpan>
                        {getDistanceUnit(
                          vehicle?.primary_meter === "Hours"
                            ? "Hours"
                            : distance
                        )}
                      </UnitSpan>
                    </FormItem>
                    {/* {errors?.dueOnOdo && (
                      <Error error={errors.dueOnOdo.message} />
                    )} */}
                  </Col>
                )}
                {dueOn.odo &&
                  serviceIntervalOdo &&
                  serviceIntervalDuration &&
                  dueOn.date && (
                    <Col
                      md={2}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <span>{t("or")}</span>
                    </Col>
                  )}
                {dueOn.date && serviceIntervalDuration && (
                  <Col md={5} style={{ cursor: "pointer" }}>
                    {/* <DatePicker
                      selected={dueOnDate}
                      onChange={(e) => setDueOnDate(e)}
                      customInput={<CustomInput />}
                      minDate={new Date(dueOn.date)}
                    /> */}
                    <Controller
                      name="dueOnDate"
                      control={control}
                      defaultValue={dueOn.date}
                      render={({ field: { onChange, value, ref } }) => (
                        <DatePicker
                          selected={value}
                          onChange={onChange}
                          customInput={<CustomInput />}
                          placeholderText={t("due_on_date")}
                          locale={getDateFnsLocale()}
                          dateFormat={"P"}
                          showMonthDropdown
                          showYearDropdown
                          //minDate={new Date(dueOn.date)}
                        />
                      )}
                      //rules={{ required: true }}
                    />
                  </Col>
                )}
              </Row>
              {/* {(dueOnValidation.date.isError ||
                dueOnValidation.odo.isError) && (
                <Row className="px-3">
                  {dueOnValidation.date.isError && (
                    <Col md={4}>
                      <Error error={dueOnValidation.date.errorMsg} />
                    </Col>
                  )}
                  {<Col md={1}></Col>}
                  {dueOnValidation.odo.isError && (
                    <Col md={4}>
                      <Error error={dueOnValidation.odo.errorMsg} />
                    </Col>
                  )}
                </Row>
              )} */}
              {/* <Row className="px-3"> */}
              {errors?.dueOnOdo && errors?.dueOnOdo?.types && (
                <Error
                  error={
                    dueOnOdo
                      ? errors.dueOnOdo.types.min
                      : errors.dueOnOdo.types.required
                  }
                />
              )}
              {/* </Row> */}
            </Modal.Body>
            <Modal.Footer className="d-flex align-items-center justify-content-center">
              <AddFormButton
                type="button"
                id="save-button"
                onClick={handleSaveDueOn}
              >
                {t("save")}
              </AddFormButton>
            </Modal.Footer>
          </Modal>
        </Container>
      </FormContainer>
    </>
  );
};

RemindersForm.propTypes = {
  isHorizontal: PropTypes.bool,
  isAboveError: PropTypes.bool,
};

RemindersForm.defaultProps = {
  isHorizontal: false,
  isAboveError: false,
};

export default RemindersForm;

export const TimeSelect = styled.select`
  //padding: 1px;
  height: 34px;
  text-align: center;
  border-radius: 5px;
  //border-color: ${colorBackgroundBody};
  background: ${colorBackgroundBody};
  border: none;
  shadow: none;
  //background: none;
  color: #787985;
  font-size: 14px;
  cursor: pointer;

  ${(p) => p.header && `width: ${p.header}px`};

  option {
    background: white;
  }

  &:focus {
    border: none;
    outline: none;
  }
`;

export const CustomInput = React.forwardRef((props, ref) => {
  return (
    <CustomDatePickDiv
      onClick={props.onClick}
      ref={ref}
      tabIndex={0}
      disabled={props.disabled}
      clearable={props.clearable}
    >
      <label
        onClick={props.onClick}
        ref={ref}
        style={{ marginTop: "3px", fontWeight: 400 }}
      >
        {props.value || (
          <span style={{ fontSize: "12px", color: colorAdditional }}>
            {props.placeholder}
          </span>
        )}
      </label>
      {/* <Calendar /> */}
      <i className="fi fi-rr-calendar" style={{ marginBottom: "2px" }}></i>
    </CustomDatePickDiv>
  );
});

export const DatePickerDiv = styled.div`
  width: 100%;
  //cursor: pointer;
`;

export const CustomDatePickDiv = styled.div`
  border: 1px solid ${colorLightGray};
  position: relative;
  border-radius: 5px;
  box-shadow: none;
  height: 36px;
  background: none;
  font-size: 14px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  input::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }

  span {
    color: ${colorAdditional};
  }

  ${(p) =>
    !p.disabled &&
    `
    cursor: pointer;
  &:hover {
    border-color: ${colorFormItemHover};
  }

  &:focus {
    outline: none;
    border-color: ${colorLightBlue};
  }
  `};

  svg {
    width: 22px;
    height: 32px;
  }

  i {
    position: relative;
    top: 2px;
    margin-right: ${(p) => (p.clearable ? "24px" : "12px")};
    color: ${colorGray};
  }

  label {
    margin: 5px 0 5px 10px;
    cursor: pointer;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    cursor: pointer;
  }

  ${(p) =>
    p.disabled &&
    `background: ${disabledBgColor};
  `}
`;

export const Textarea = styled.textarea`
  margin-bottom: 8px;
`;

export const DueOnWrap = styled.div`
  font-size: 14px;
  font-weight: 500;
  padding: 0 10px;
`;

export const NotifyMeWrap = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${colorBlueHover};
  padding: 0 10px;

  i {
    position: relative;
    top: 1px;
    margin-right: 4px;
  }
`;

export const ServicesPreviewHeading = styled.h5`
  font-size: 14px;
  font-weight: 400;
  padding: 8px 0;
`;

export const Desc = styled.p`
  font-size: 12px;
  color: ${colorGray};
  font-weight: 400;
`;

const DollarIconWrap = styled.span`
  cursor: pointer;
  margin-left: 2px;
  svg {
    margin-top: -2px;
    //padding: 3px;
    width: 16px;
    height: 16px;
    fill: #85bb65;
    &:hover {
      fill: ${colorGreen};
    }
  }
`;

export const EmailPreviewText = styled.p`
  font-weight: 300;
  padding-bottom: 8px;
  // span {
  //   font-weight: 500;
  // }
`;

export const EditIconWrap = styled.span`
  cursor: pointer;
  margin-left: 8px;
  svg {
    display: inline-block;
    fill: ${colorGray};
    //margin-left: 2px;
    margin-top: -4px;
    //padding: 2px;
    width: 14px;
    height: 13px;
    &:hover {
      fill: ${colorLightGray};
    }
  }
`;

export const EditIcon = styled.i`
  cursor: pointer;
  color: ${colorGray};
  position: relative;
  font-size: 14px;
  top: 1px;
  margin-left: 8px;
  &:hover {
    color: ${colorLightGray};
  }
`;

// export const DollarIcon = styled.i`
//   cursor: pointer;
//   color: ${colorGreenHover};
//   position: relative;
//   font-size: 16px;
//   top: 3px;
//   margin-left: 4px;
//   &:hover {
//     color: ${colorGreen};
//   }
// `;

export const convertToDays = (input, duration) => {
  switch (duration) {
    case "days":
      return input * 1;
    case "weeks":
      return input * 7;
    case "months":
      return input * 30;
    case "years":
      return input * 365;
  }
};

export const CloseIcon = styled.i`
  color: ${colorGray};
  cursor: pointer;
  font-size: 24px;
  position: relative;
  top: 2px;
  &:hover {
    color: ${colorLightGray};
  }
`;

export const SingleValueOption = (props) => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const screen = location?.pathname;
  //console.log(screen);
  let label = "";
  if (screen?.includes("maintenancereminders")) {
    label = t("create_new_service_task");
  }
  if (screen?.includes("renewals")) {
    label = t("create_new_expense_task");
  }
  if (screen?.includes("vehicles")) {
    label = t("create_new_veh_type");
  }
  if (props?.selectProps?.customProps?.type === "group") {
    label = t("create_new_group");
  }

  if (props.data.__isNew__) {
    return (
      <>
        <hr style={{ margin: 0, padding: 0 }} />
        <components.Option {...props}>
          <span
            style={{ color: buttonColorNew, fontWeight: 300, fontSize: "12px" }}
          >
            {label}
            <span style={{ fontWeight: 500 }}> "{props.data.value}"</span>
          </span>
        </components.Option>
      </>
    );
  }
  return <components.Option {...props}>{props.data.label}</components.Option>;
};
