import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ScrollableDiv,
  Table,
} from "../../../../shared/components/TableElements";
import styled from "styled-components";
import {
  CheckBoxInput,
  HintText,
} from "../../../../shared/components/form/FormElements";
import {
  PaginationFormOption,
  PaginationFormSelect,
  PaginationInfo,
  PaginationSelectWrap,
  PaginationWrap,
} from "../../../../shared/components/table/components/ReactTablePagination";
import { Pagination } from "react-bootstrap";
import { scrollbarStyles } from "../../../../utils/palette";
import { NATEXT, providerNameMapping } from "../../../../shared/constants";

const ImportVehiclesPopup = ({ provider, vehicles, setVehicles }) => {
  const { t } = useTranslation("common");

  const [selectAll, setSelectAll] = useState(true);

  //const [paginatedVehicles, setPaginatedVehicles] = useState([]);

  //Pagination
  //   const pageSize = 30;
  //   const [noOfPages, setNoOfPages] = useState(
  //     Math.ceil(vehicles?.length / pageSize)
  //   );

  //   const [pageIndex, setPageIndex] = useState(0);
  //   const [pageIndexArr, setPageIndexArr] = useState([
  //     ...Array(noOfPages).keys(),
  //   ]);
  //   const [totalRecords, setTotalRecords] = useState(vehicles?.length);

  const manualPageSize = [10, 25, 50, 100];

  const [pageSize, setPageSize] = useState(10);
  const noOfPages = Math.ceil(vehicles?.length / pageSize);

  const [pageIndex, setPageIndex] = useState(0);
  const pageIndexArr = [...Array(noOfPages).keys()];
  const totalRecords = vehicles?.length;

  const colMapping = {
    3: [
      { key: "vehicle_name", label: t("name") },
      { key: "make", label: t("make") },
      { key: "model", label: t("model") },
      { key: "license_no", label: t("license") },
      { key: "vin_no", label: t("vin") },
    ],
  };

  const providerName = providerNameMapping[provider];
  const cols = colMapping[3];

  //   useEffect(() => {
  //     if (vehicles?.length > 0) {
  //       const pages = vehicles?.length / pageSize;
  //       setNoOfPages(pages);

  //       const pageIndexArray = [...Array(pages).keys()];
  //       setPageIndexArr(pageIndexArray);

  //       setTotalRecords(vehicles?.length);

  //       const temp = vehicles?.slice(
  //         pageIndex * pageSize,
  //         (pageIndex + 1) * pageSize
  //       );

  //       console.log("pageIndex", pageIndex);
  //       console.log("pageSize", pageSize);
  //       console.log("temp", temp);
  //       setPaginatedVehicles(temp);
  //     }
  //   }, [vehicles]);

  useEffect(() => {
    if (vehicles?.length > 0) {
      setVehicles((prev) => {
        let temp = [...prev];
        let result = temp.map((v) => ({ ...v, isChecked: selectAll }));
        return result;
      });
    }
  }, [selectAll]);

  const handleSelectAllChange = () => {
    setSelectAll((prev) => !prev);
  };

  const handleSelectChange = (vehicle) => {
    setVehicles((prev) => {
      let temp = [...prev];
      let result = temp.map((v) => {
        if (v.veh_id === vehicle.veh_id) {
          return { ...v, isChecked: !v.isChecked };
        } else {
          return v;
        }
      });
      if (result.every((d) => d.isChecked)) {
        setSelectAll(true);
      }
      if (result.every((d) => !d.isChecked)) {
        setSelectAll(false);
      }
      return result;
    });
  };

  return (
    <div className="w-100">
      <p>{t("assets_found_in_provider_msg", { providerName })}</p>
      <HintText>{t("existing_veh_not_imported_note")}</HintText>
      <ScrollableDiv $height={`${window.innerHeight - 296}px`}>
        <Table bordered className="table-hover">
          <thead>
            <tr>
              <th>
                <CheckBoxInput
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              </th>
              {cols.map((col) => (
                <th key={col.key} className="px-2">{col.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {vehicles
              ?.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
              ?.map((v) => (
                <tr key={v.veh_id} onClick={() => handleSelectChange(v)}>
                  <td>
                    <CheckBoxInput
                      type="checkbox"
                      checked={v.isChecked}
                      readOnly
                      //onChange={(e) => handleBatchClick(e, batch)}
                    />
                  </td>
                  {cols.map((col) => (
                    <td key={col.key}>{v[col.key] || NATEXT}</td>
                  ))}
                </tr>
              ))}
          </tbody>
        </Table>
      </ScrollableDiv>
      <PaginationWrap className="my-2 mx-2 p-0">
        <Pagination>
          <Pagination.First
            disabled={pageIndex === 0}
            className="pagination__item--arrow"
            onClick={async () => {
              setPageIndex(0);
            }}
          >
            {/* <ChevronDoubleLeftIcon /> */}
            <Icon className="fi fi-rr-angle-double-small-left"></Icon>
          </Pagination.First>
          <Pagination.Prev
            disabled={pageIndex === 0}
            className="pagination__item--arrow"
            onClick={async () => {
              let temp = pageIndex;
              setPageIndex((prev) => prev - 1);
            }}
          >
            {/* <ChevronLeftIcon /> */}
            <Icon className="fi fi-rr-angle-small-left"></Icon>
          </Pagination.Prev>
          {pageIndexArr?.map((i) => (
            <Pagination.Item
              id="page"
              key={i}
              active={pageIndex === i}
              onClick={async () => {
                setPageIndex(i);
              }}
            >
              {i + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            disabled={pageIndex === pageIndexArr.length - 1}
            className="pagination__item--arrow"
            onClick={async () => {
              let temp = pageIndex;
              setPageIndex((prev) => prev + 1);
            }}
          >
            {/* <ChevronRightIcon /> */}
            <Icon className="fi fi-rr-angle-small-right"></Icon>
          </Pagination.Next>
          <Pagination.Last
            disabled={pageIndex === pageIndexArr.length - 1}
            className="pagination__item--arrow"
            onClick={async () => {
              setPageIndex(pageIndexArr.length - 1);
            }}
          >
            {/* <ChevronDoubleRightIcon /> */}
            <Icon className="fi fi-rr-angle-double-small-right"></Icon>
          </Pagination.Last>
        </Pagination>
        <PaginationInfo>
          {/* Showing {pageSize * pageIndex + 1} to{" "}
        {pageSize * pageIndex + page.length} of {noOfRows} */}
          {t("showing_from_to_no_of_rows", {
            from: totalRecords > 0 ? pageSize * pageIndex + 1 : 0,
            to: Math.min(pageSize * (pageIndex + 1), totalRecords),
            noOfRows: totalRecords,
          })}
        </PaginationInfo>
        <PaginationSelectWrap>
          <PaginationFormSelect
            name="select"
            id="exampleSelect"
            value={pageSize}
            onChange={(e) => {
              setPageSize(e.target.value);
              setPageIndex(0);
            }}
          >
            {manualPageSize.map((item) => (
              <PaginationFormOption key={item} value={item} id="page-size">
                {/* Show {item} */}
                {t("show_no_of_rows", { noOfRows: item })}
              </PaginationFormOption>
            ))}
          </PaginationFormSelect>
        </PaginationSelectWrap>
      </PaginationWrap>
    </div>
  );
};

export default ImportVehiclesPopup;

//styles

// const ScrollableDiv = styled.div`
//   height: ${(p) => p.$height};
//   overflow: auto;
//   display: flex;
//   justify-content: center;
//   ${scrollbarStyles}
// `;

export const Icon = styled.i`
  position: relative;
  top: 2px;
`;
