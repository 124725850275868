import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { Col, Modal, Row } from "react-bootstrap";

import defaultVehicleIcon from "@/shared/img/defaultVehicleIcon.png";
import {
  colorBlue,
  colorGray,
  colorGrayNew,
  colorGreenHover,
  colorLightBlue,
  colorLightGray,
  colorYellowNew,
  scrollbarStyles,
} from "../../../../../utils/palette";

import FillupsRightPanel from "./FillupsRightPanel";
import {
  AddFormButton,
  ColumnHeading,
  CustomLoader,
  FilterButton,
  ActionIconContainer,
} from "../../../TableElements";
import DailyMileageRightPanel from "./DailyMileageRightPanel";
import { useRef } from "react";
import ServicesRightPanel from "./ServicesRightPanel";
import InspectionSubmissionsRightPanel from "./InspectionSubmissionsRightPanel";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { NATEXT, s3Url } from "../../../../constants";
import {
  getDistanceFactor,
  getDistanceFactorForEff,
  getVolumeFactor,
  getVolumeFactorForEff,
} from "../../../../helpers";
import { getVehicles, post, postApi } from "../../../../../api/getUser";
import { DeletePopup } from "../DeletePopup";
import IssuesRightPanel from "./IssuesRightPanel";
import { updateVehiclesList } from "../../../../../features/vehicles/vehiclesSlice";
import { useDispatch } from "react-redux";
import { DisplayIcon } from "../../../../../components/Issues/IssuesForm";
import { Trans, useTranslation } from "react-i18next";
import { addMsg } from "../../../../../features/snackbar/snackbarSlice";
import getUnixTime from "date-fns/getUnixTime";
import { OptionsMenu } from "../MoreOptions/MoreOptions";
import ExpensesRightPanel from "./ExpensesRightPanel";
import UserManagementRightPanel from "./UserManagementRightPanel";
import { UserStatus } from "../../../../../components/UserManagement/UserManagementTable";
import InfoPopup from "../../../InfoPopup";
import WorkOrdersRightPanel from "./WorkOrdersRightPanel";
import { WorkOrderStatus } from "../../../../../components/WorkOrders/WorkOrdersTable";
import WOSidePanelDropdown from "../../../../../components/WorkOrders/components/WOSidePanelDropdown";
import VehiclesRightPanel from "./VehiclesRightPanel";
import { VehicleStatus } from "../../../../../components/Vehicles/VehiclesTable";
import TripsRightPanel from "./TripsRightPanel";
import ActionPopup from "../../../ActionPopup";
import Tooltip from "../../../Tooltip";
import { useAuth } from "../../../../../contexts/AuthContext";
import SubscribePopup from "../../../SubscribePopup";
import SubscribeTooltipIcon from "../../../SubscribeTooltipIcon";
import FaultCodesRightPanel from "./FaultCodesRightPanel";
import PartInfoComponent from "./components/PartInfoComponent";
import PartsFormPopup from "../../../../../components/Inventory/Parts/PartsFormPopup";
import EditPartPopup from "../../../../../components/Inventory/Parts/EditPartPopup";
import PartsRightPanel from "./PartsRightPanel";
import UsageRightPanel from "./UsageRightPanel";
import Snackbar from "../../../Snackbar";
import { useReactToPrint } from "react-to-print";
import InspectionPrintComponent from "./components/InspectionPrintComponent";

const RightPanel = ({
  rightPanelData,
  setRightPanelData,
  screen,
  height,
  pauses,
  setSnackbarMsg,
}) => {
  const rowData = rightPanelData.content.original;
  const history = useHistory();
  const { t } = useTranslation("common");

  const users = useSelector((state) => state.users.list);
  const { pathname } = useLocation();

  const { tag } = useAuth();
  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);
  const [subscribePopupErrMsg, setsubscribePopupErrMsg] = useState("");

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const { roleId, userId } = useSelector((state) => state.user);
  const { distance, consumption, volume } = useSelector((state) => state.units);

  console.log("rowData", rowData);

  const [currentVehicle, setCurrentVehicle] = useState("");

  const [readOnly, setReadOnly] = useState(false);
  const fixedPanelRef = useRef(null);
  const [fixedPanelHeight, setFixedPanelHeight] = useState(92);

  const [showEditIcon, setShowEditIcon] = useState(true);
  const [showDeleteIcon, setShowDeleteIcon] = useState(true);

  //Work order logs
  const [logs, setLogs] = useState([]);

  //State to determine whether to show logs or old pause table
  const [showLogs, setShowLogs] = useState(false);

  //For showing info popup during save
  const [vehicleUpdErrData, setVehicleUpdErrData] = useState({
    message: "",
    icon: { className: "fi fi-rr-triangle-warning", color: "red" },
  });
  const [vehicleUpdErr, setVehicleUpdErr] = useState(false);

  const [showDeletePopup, setShowDeletePopup] = useState(false);

  //These two states are for Info Popup which is shown in case record cannot be deleted
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [infoData, setInfoData] = useState({});

  let singleDeleteApiData = {};

  //Needed for work orders side panel and wo sidepanel dropdown
  const [serviceTasks, setServiceTasks] = useState([]);

  //To toggle parts form popup
  const [showPartsForm, setShowPartsForm] = useState(false);
  //To toggle edit part
  const [showEditPart, setShowEditPart] = useState(false);
  //add-batch, edit-part
  const [mode, setMode] = useState("");

  if (screen.includes("submissions")) {
    // singleDeleteApiData = {
    //   inspection_id: rowData.row_id,
    //   querystring: getSingleDeleteQuery(screen),
    // };
    singleDeleteApiData = {
      delete_ids: rowData?.inspection_id,
      querystring: "multiple_delete",
      sub_query_string: "INSPECTIONS",
    };
  } else if (screen.includes("issues")) {
    // singleDeleteApiData = {
    //   issue_id: rowData.issue_id,
    //   action: 3,
    //   querystring: "addEditIssues",
    // };
    singleDeleteApiData = {
      delete_ids: rowData?.issue_id,
      querystring: "multiple_delete",
      sub_query_string: "ISSUES",
    };
  } else if (screen.includes("expenses/expenses")) {
    // singleDeleteApiData = {
    //   expense_id: rowData?.expense_id,
    //   querystring: "delete_expense",
    //   //action: 3,
    // };
    singleDeleteApiData = {
      delete_ids: rowData?.expense_id,
      querystring: "multiple_delete",
      sub_query_string: "EXPENSES",
    };
  } else if (screen.includes("services/services")) {
    // singleDeleteApiData = {
    //   service_id: rowData?.service_id,
    //   querystring: "delete_service",
    //   //action: 3,
    // };
    singleDeleteApiData = {
      delete_ids: rowData?.service_id,
      querystring: "multiple_delete",
      sub_query_string: "SERVICES",
    };
  } else if (screen.includes("dailymileage")) {
    // singleDeleteApiData = {
    //   mileage_id: rowData?._id,
    //   querystring: "delete_mileage",
    //   //action: 3,
    // };
    singleDeleteApiData = {
      delete_ids: rowData?._id,
      querystring: "multiple_delete",
      sub_query_string: "STARTING_ENDING_MILEAGE",
    };
  } else if (screen.includes("fillups")) {
    const effDistanceFactor = getDistanceFactorForEff(distance, consumption);
    const effVolumeFactor = getVolumeFactorForEff(volume, consumption);

    const volumeFactor = getVolumeFactor(rowData.qty_unit, volume);
    const distanceFactor = getDistanceFactor(rowData.odo_unit, distance);
    // singleDeleteApiData = {
    //   //querystring: "add_fillup",
    //   action: 3,
    //   card_number: rowData?.card_number,
    //   comments: rowData?.comments,
    //   currency: rowData?.currency,
    //   dist_fact: distanceFactor,
    //   effdistfact: effDistanceFactor,
    //   effvolfact: effVolumeFactor,
    //   //operator_userid: rowData?.operator_userid,
    //   filling_station: rowData?.filling_station,
    //   fillup_date: rowData?.fillup_date,
    //   fillup_id: rowData?.fillup_id,
    //   fuel_brand: rowData?.fuel_brand,
    //   fuel_type: rowData?.fuel_type,
    //   m_fill: rowData?.m_fill,
    //   octane: rowData?.octane,
    //   odo: rowData?.odo,
    //   odo_unit: rowData?.odo_unit ? rowData?.odo_unit : "",
    //   operation: "update",
    //   p_fill: rowData?.p_fill,
    //   qty: rowData?.qty,
    //   qty_unit: rowData?.qty_unit,
    //   receipt_img_name: rowData?.receipt_img_name
    //     ? rowData?.receipt_img_name
    //     : "",
    //   source: "web",
    //   //sync_version: "v2",
    //   total_cost: rowData?.total_cost,
    //   transaction_type: rowData?.transaction_type,
    //   veh_id: rowData?.veh_id,
    //   vol_fact: volumeFactor,
    //   //latitude: rowData?.latitude,
    //   //longitude: rowData?.longitude,
    // };
    singleDeleteApiData = {
      delete_ids: rowData?.fillup_id,
      querystring: "multiple_delete",
      sub_query_string: "FILLUPS",
    };
  } else if (screen.includes("users")) {
    singleDeleteApiData = {
      querystring: "multiple_delete",
      sub_query_string: "USERS",
      delete_ids: rowData.user_id,
    };
    // singleDeleteApiData = {
    //   //screenName: screenName,
    //   delete_ids: rowData.user_id,
    //   querystring: "multiple_delete",
    //   sub_query_string: "USERS",
    // };
  } else if (screen.includes("workorders")) {
    singleDeleteApiData = {
      delete_ids: rowData?.wo_id,
      querystring: "multiple_delete",
      sub_query_string: "WORK_ORDERS",
    };
  } else if (screen.includes("vehicles")) {
    singleDeleteApiData = {
      delete_ids: rowData.veh_id,
      //source: "web",
      sub_query_string: "VEHICLE",
      querystring: "multiple_delete",
    };
    // singleDeleteApiData = {
    //   //screenName: screenName,
    //   delete_ids: rowData.veh_id,
    //   querystring: "multiple_delete",
    //   sub_query_string: "VEHICLE",
    // };
  } else if (screen.includes("trips")) {
    // singleDeleteApiData = {
    //   insertTripAction: 3,
    //   insertTripId: rowData.trip_id,
    //   insertTripVehId: rowData.veh_id,
    //   querystring: "insert_trip",
    //   source: "web",
    // };
    singleDeleteApiData = {
      delete_ids: rowData?.trip_id,
      querystring: "multiple_delete",
      sub_query_string: "TRIPS",
    };
  } else if (screen.includes("parts")) {
    // singleDeleteApiData = {
    //   insertTripAction: 3,
    //   insertTripId: rowData.trip_id,
    //   insertTripVehId: rowData.veh_id,
    //   querystring: "insert_trip",
    //   source: "web",
    // };
    singleDeleteApiData = {
      delete_ids: rowData?.part_id,
      querystring: "multiple_delete",
      sub_query_string: "PARTS",
    };
  }

  //Popups open/close states
  const [showDeleteServicePopup, setShowDeleteServicePopup] = useState(false);
  const [showDeleteConfirmationPopup, setShowDeleteConfirmationPopup] =
    useState(false);

  //Logic for Read More(Notes)
  const [clamped, setClamped] = useState(true);
  const [showReadMoreButton, setShowReadMoreButton] = useState(false);
  const notesRef = React.useRef(null);

  const { collapse } = useSelector((state) => state.sidebar);

  //Effect to set panel height
  useEffect(() => {
    setFixedPanelHeight(height - fixedPanelRef?.current?.clientHeight);
  }, [rowData, currentVehicle]);

  //Effect to toggle editIcon and deleteIcon for all screens
  useEffect(() => {
    const temp = vehiclesList?.find((v) => v.vehicleId === rowData?.veh_id);
    setCurrentVehicle(temp);
    if (
      !screen.includes("vehicles") &&
      !screen.includes("users") &&
      !screen.includes("faultcodes")
    ) {
      if (temp) {
        if (temp.veh_active == 1) {
          if (roleId == 4) {
            if (screen.includes("workorders")) {
              setShowEditIcon(true);
              setShowDeleteIcon(false);
            } else if (screen.includes("expenses")) {
              setShowEditIcon(true);
              setShowDeleteIcon(true);
            } else {
              setShowEditIcon(false);
              setShowDeleteIcon(false);
            }
          } else {
            setShowEditIcon(true);
            setShowDeleteIcon(true);
          }
          //Inactive Vehicles
        } else {
          if (roleId == 4) {
            if (screen.includes("expenses")) {
              setShowEditIcon(false);
              setShowDeleteIcon(true);
            } else {
              setShowEditIcon(false);
              setShowDeleteIcon(false);
            }
          } else {
            setShowEditIcon(false);
            setShowDeleteIcon(true);
          }

          //setShowEditIcon(false);
          //setShowDeleteIcon(false);
        }
      }
    }

    if (screen.includes("vehicles")) {
      if (roleId == 4) {
        setShowEditIcon(false);
        setShowDeleteIcon(false);
      } else {
        setShowEditIcon(true);
        setShowDeleteIcon(true);
      }
    }

    if (screen.includes("users")) {
      if (roleId == 4) {
        setShowDeleteIcon(false);
        if (rowData.user_id === userId) {
          setShowEditIcon(true);
        } else {
          setShowEditIcon(false);
        }
      } else {
        setShowEditIcon(true);
        setShowDeleteIcon(true);
      }
    }

    if (screen.includes("faultcodes")) {
      setShowEditIcon(false);
      setShowDeleteIcon(false);
    }
  }, [vehiclesList, rowData, roleId, userId, screen]);

  useEffect(() => {
    const hasClamping = (el) => {
      const { clientHeight, scrollHeight } = el;
      return clientHeight !== scrollHeight;
    };

    if (!clamped) {
      setClamped(true);
    }

    const checkButtonAvailability = () => {
      if (notesRef.current) {
        // if (!clamped) {
        //   setClamped(true);
        // }
        // Save current state to reapply later if necessary.
        //const hadClampClass = containerRef.current.classList.contains("clamp");
        // Make sure that CSS clamping is applied if aplicable.
        //if (!hadClampClass) containerRef.current.classList.add("clamp");
        // Check for clamping and show or hide button accordingly.

        setShowReadMoreButton(hasClamping(notesRef.current));
        // Sync clamping with local state.
        //if (!hadClampClass) containerRef.current.classList.remove("clamp");
      }
    };

    //const debouncedCheck = lodash.debounce(checkButtonAvailability, 50);

    setTimeout(() => {
      checkButtonAvailability();
    }, 500);
    window.addEventListener("resize", checkButtonAvailability);

    return () => {
      window.removeEventListener("resize", checkButtonAvailability);
    };
  }, [notesRef, collapse, rowData]);

  //Logic to update Logs in case of work order
  useEffect(() => {
    if (screen.includes("workorders")) {
      const workOrderLogs = rowData.logs; //To avoid name conflict with state 'logs'
      if (workOrderLogs) {
        setShowLogs(true);
        const parsedLogs = JSON.parse(workOrderLogs);
        if (parsedLogs && parsedLogs?.length > 0) {
          const logsArr = parsedLogs.map((log) => ({
            ...log,
            user: users.find((u) => u.user_id === log.user_id)?.name,
          }));
          setLogs(logsArr);
        }
      } else {
        if (workOrderLogs === "") {
          setShowLogs(true);
        }
        if (workOrderLogs === null) {
          setShowLogs(false);
        }
      }
    }
  }, [rowData]);

  useEffect(() => {
    //Single service fetch call on edit
    if (screen?.includes("workorders")) {
      (async () => {
        const singleWOApiData = {
          querystring: "fetchSingleWoData",
          wo_id: rowData?.wo_id,
        };
        const singleWORes = await postApi(singleWOApiData, "workOrder");
        let serviceTaskDetails = [];
        if (singleWORes.success) {
          const {
            service_details_data,
            parts_consumed_details,
            parts_batch_data,
          } = singleWORes;

          if (service_details_data?.length > 0) {
            for (let task of service_details_data) {
              const {
                service_task_detail_id,
                service_task_id,
                service_id,
                wo_id,
                labor_cost,
                parts_cost,
                total_cost,
                service_task_name,
              } = task;

              //Parts
              let noOfParts = 0;
              let partsDisplayArr = [];
              let batches = [];
              if (parts_consumed_details.length > 0) {
                let partsConsumedData = parts_consumed_details.map((p) => {
                  const assocBatch = parts_batch_data.find(
                    (b) => b.parts_batch_id === p.parts_batch_id
                  );
                  return {
                    ...p,
                    ...assocBatch,
                    action: 2,
                    unitsConsumed: p.quantity_consumed,
                    availableQuantity: assocBatch.available_quantity,
                    currentlyAvailableQuantity:
                      +assocBatch.available_quantity + +p.quantity_consumed,
                    totalCost:
                      p.quantity_consumed * assocBatch.purchase_cost_per_unit,
                  };
                });
                const assocPartBatches = partsConsumedData.filter(
                  (t) => t.service_task_detail_id === service_task_detail_id
                );
                batches = [...assocPartBatches];
                //Get no of parts used
                noOfParts = [...new Set(assocPartBatches.map((b) => b.part_id))]
                  .length;
              }

              serviceTaskDetails.push({
                service_task_detail_id,
                service_task_id,
                service_id,
                service_task_name,
                wo_id,
                laborCost: +labor_cost ? +labor_cost : "",
                partsCost: +parts_cost ? +parts_cost : "",
                totalCost: +total_cost ? +total_cost : "",
                action: 2,
                label: service_task_name,
                value: service_task_id,
                noOfParts,
                batches,
              });
            }
          }
        }
        setServiceTasks(serviceTaskDetails);
      })();
    }
  }, [rowData]);

  const getColumnHeading = () => {
    if (screen.includes("fillups")) {
      return t("fillup_details");
    } else if (screen.includes("dailymileage")) {
      return t("mileage_details");
    } else if (screen.includes("services")) {
      return t("service_details");
    } else if (screen.includes("inspectionsubmissions")) {
      return t("submission_details");
    } else if (screen.includes("issues")) {
      return t("issue_details");
    } else if (screen.includes("expenses/expense")) {
      return t("expense_details");
    } else if (screen.includes("users")) {
      return t("user_details");
    } else if (screen.includes("workorders")) {
      return t("wo_details");
    } else if (screen.includes("vehicles")) {
      return t("vehicle_details");
    } else if (screen.includes("trips")) {
      return t("trip_details");
    } else if (screen.includes("faultcodes")) {
      return t("fault_code_details");
    } else if (screen.includes("parts")) {
      return t("part_details");
    } else if (screen.includes("usage")) {
      return t("usage_details");
    }
  };

  const [editData, setEditData] = useState({});

  //To get inspection_id for submissions
  //const [printData, setPrintData] = useState({});

  //Print Submissions
  //WO Print
  const contentRef = useRef(null);
  const handleAfterPrint = React.useCallback(() => {
    setPrintBtnLoading(false);
    //return Promise.resolve();
  }, []);

  const [printBtnLoading, setPrintBtnLoading] = useState(false);

  const reactToPrintContent = () => {
    return contentRef.current;
  };

  const handleBeforePrint = React.useCallback(() => {
    if (tag === "free-user") {
      setsubscribePopupErrMsg(t("subscribe_msg"));
      setShowSubscribePopup(true);
      return Promise.reject();
    }
    setPrintBtnLoading(true);
    return Promise.resolve();
  }, []);
  const printFn = useReactToPrint({
    contentRef: contentRef,
    documentTitle: "AwesomeFileName",
    //fonts: CUSTOM_FONTS,
    onAfterPrint: handleAfterPrint,
    onBeforePrint: handleBeforePrint,

    onPrintError: (errLoc, err) => console.log(errLoc, err),
  });

  const handlePrint = React.useCallback(() => {
    printFn();
  }, [printFn]);

  const rightPanelCommonProps = {
    rowData,
    clamped,
    setClamped,
    showReadMoreButton,
    notesRef,
    setShowReadMoreButton,
    editData,
    setEditData,
    //printData,
    //setPrintData,
    //handleEdit
  };

  const getRightPanelComponent = () => {
    if (screen.includes("fillups")) {
      return <FillupsRightPanel {...rightPanelCommonProps} />;
    } else if (screen.includes("dailymileage")) {
      return (
        <DailyMileageRightPanel rowData={rowData} {...rightPanelCommonProps} />
      );
    } else if (screen.includes("services")) {
      return (
        <ServicesRightPanel rowData={rowData} {...rightPanelCommonProps} />
      );
    } else if (screen.includes("inspectionsubmissions")) {
      return (
        <InspectionSubmissionsRightPanel
          rowData={rowData}
          {...rightPanelCommonProps}
        />
      );
    } else if (screen.includes("issues")) {
      return <IssuesRightPanel rowData={rowData} {...rightPanelCommonProps} />;
    } else if (screen.includes("expenses/expenses")) {
      return (
        <ExpensesRightPanel rowData={rowData} {...rightPanelCommonProps} />
      );
    } else if (screen.includes("users")) {
      return (
        <UserManagementRightPanel
          rowData={rowData}
          {...rightPanelCommonProps}
        />
      );
    } else if (screen.includes("workorders")) {
      return (
        <WorkOrdersRightPanel
          rowData={rowData}
          logs={logs}
          setLogs={setLogs}
          showLogs={showLogs}
          setShowLogs={setShowLogs}
          serviceTasks={serviceTasks}
          setServiceTasks={setServiceTasks}
          //{...rightPanelCommonProps}
        />
      );
    } else if (screen.includes("vehicles")) {
      return (
        <VehiclesRightPanel rowData={rowData} {...rightPanelCommonProps} />
      );
    } else if (screen.includes("trips")) {
      return <TripsRightPanel rowData={rowData} {...rightPanelCommonProps} />;
    } else if (screen.includes("faultcodes")) {
      return (
        <FaultCodesRightPanel rowData={rowData} {...rightPanelCommonProps} />
      );
    } else if (screen.includes("parts")) {
      return (
        <PartsRightPanel
          rowData={rowData}
          setSnackbarMsg={setSnackbarMsg}
          {...rightPanelCommonProps}
        />
      );
    } else if (screen.includes("usage")) {
      return <UsageRightPanel rowData={rowData} {...rightPanelCommonProps} />;
    }
  };

  useEffect(() => {
    if (screen.includes("issues")) {
      setEditData({
        route: "/issues/addnew",
        screen: "issues",
        data: { rowData },
      });
    }
    if (screen.includes("services/services")) {
      setEditData({
        route: "/services/services/addnew",
        screen: "services",
        data: { rowData },
      });
    }
    if (screen.includes("expenses/expenses")) {
      setEditData({
        route: "/expenses/expenses/addnew",
        screen: "expenses",
        data: { rowData },
      });
    }
    if (screen.includes("dailymileage")) {
      setEditData({
        route: "/dailymileage/addnew",
        screen: "dailymileage",
        data: { rowData },
      });
    }
    if (screen.includes("fillups")) {
      setEditData({
        route: "/fillups/addnew",
        screen: "fillups",
        data: { rowData },
      });
    }
    if (screen.includes("users")) {
      setEditData({
        route: "/users/addnew",
        screen: "users",
        data: { rowData },
      });
    }
    if (screen.includes("workorders")) {
      setEditData({
        route: "/workorders/addnew",
        screen: "workorders",
        data: { rowData },
      });
    }
    if (screen.includes("vehicles")) {
      setEditData({
        route: "/vehicles/addnew",
        screen: "vehicles",
        data: { rowData },
      });
    }
    if (screen.includes("trips")) {
      setEditData({
        route: "/trips/addnew",
        screen: "trips",
        data: { rowData },
      });
    }
  }, [rowData]);

  const handleEdit = () => {
    if (pathname.includes("parts")) {
      setShowEditPart(true);
      return;
    }

    const { route, screen, data } = editData;
    //const type = "edit";
    history.push(editData.route, {
      type: "edit",
      route,
      screen,
      data,
    });
  };

  const handleDelete = () => {
    if (screen.includes("issues")) {
      if (rowData?.service_id) {
        setShowDeleteServicePopup(true);
      } else {
        setShowDeletePopup(true);
      }
    } else {
      setShowDeletePopup(true);
    }
  };

  // const handlePrint = () => {
  //   if (tag === "free-user") {
  //     setShowSubscribePopup(true);
  //     return;
  //   }
  //   const email = auth.currentUser.email;
  //   const env = process.env.REACT_APP_ENV === "test" ? "test" : "app";
  //   const url = `https://s3.us-east-2.amazonaws.com/web.simplyfleet.app/${env}/submission_report.html?email=${email}&inspection_id=${printData.inspectionId}`;
  //   window.open(url, "_blank", "noreferrer");
  // };

  const handleAddBatch = () => {
    if (tag === "essential-user") {
      setsubscribePopupErrMsg(t("subscribe_to_advanced_msg"));
      setShowSubscribePopup(true);
      return;
    }
    setMode("add-batch");
    setShowPartsForm(true);
  };

  return (
    <>
      {screen.includes("inspectionsubmissions") &&
        editData?.data?.rowData &&
        editData?.data?.submissionsData &&
        editData?.data?.imgArrData && (
          <div style={{ display: "none" }}>
            <InspectionPrintComponent
              {...editData?.data}
              vehicle={currentVehicle}
              ref={contentRef}
            />
          </div>
        )}
      {/* {showSnackbar && (
        <Snackbar
          showSnackbar={showSnackbar}
          setShowSnackbar={setShowSnackbar}
          message={snackbarMsg}
          //snackbarType={snackbarData.type}
        />
      )} */}
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={subscribePopupErrMsg}
        />
      )}
      {showPartsForm && (
        <PartsFormPopup
          showPopup={showPartsForm}
          setShowPopup={setShowPartsForm}
          mode={mode}
          rowData={rowData}
          setSnackbarMsg={setSnackbarMsg}
        />
      )}
      {showEditPart && (
        <EditPartPopup
          showPopup={showEditPart}
          setShowPopup={setShowEditPart}
          rowData={rowData}
          setSnackbarMsg={setSnackbarMsg}
        />
      )}
      <DeleteServicePopup
        showDeleteServicePopup={showDeleteServicePopup}
        setShowDeleteServicePopup={setShowDeleteServicePopup}
        rowData={rowData}
        setShowDeletePopup={setShowDeletePopup}
      />
      {/* <DeleteConfirmationPopup
        rowData={rowData}
        showDeleteConfirmationPopup={showDeleteConfirmationPopup}
        setShowDeleteConfirmationPopup={setShowDeleteConfirmationPopup}
        setVehicleUpdErr={setVehicleUpdErr}
        setVehicleUpdErrData={setVehicleUpdErrData}
      /> */}
      {vehicleUpdErr && (
        <InfoPopup
          message={vehicleUpdErrData.message}
          icon={vehicleUpdErrData.icon}
          showPopup={vehicleUpdErr}
          setShowPopup={setVehicleUpdErr}
        />
      )}
      <RightPanelContainer active={rightPanelData.show} height={height}>
        <div
          className="px-2 pt-2 pb-0"
          style={{
            //height: 108,
            boxShadow: "0px 1px 1px lightgray",
          }}
          ref={fixedPanelRef}
        >
          {/* <Col> */}
          <Row>
            <Col>
              <ColumnHeading style={{ fontSize: "14px" }}>
                {getColumnHeading()}
              </ColumnHeading>
            </Col>
            <Col>
              <Col className="d-flex align-items-center justify-content-end gap-4 cursor-pointer">
                {screen.includes("inspectionsubmissions") &&
                  (tag === "free-user" ? (
                    <Tooltip text={t("subscribe_msg")}>
                      <ActionIconContainer
                        onClick={() => handlePrint(reactToPrintContent)}
                      >
                        <i
                          className="fi fi-rr-print"
                          style={{ position: "relative", zIndex: 1 }}
                        />
                        <i
                          className="fi fi-ss-usd-circle"
                          style={{
                            position: "relative",
                            zIndex: 2,
                            top: 12,
                            left: 12,
                            fontSize: 13,
                            color: colorGreenHover,
                          }}
                        />
                      </ActionIconContainer>
                    </Tooltip>
                  ) : (
                    <ActionIconContainer onClick={handlePrint}>
                      <i
                        className="fi fi-rr-print"
                        style={{ position: "relative", zIndex: 1 }}
                      />
                    </ActionIconContainer>
                  ))}

                <>
                  {screen.includes("parts") && (
                    <Tooltip text={t("add_batch")}>
                      <ActionIconContainer onClick={handleAddBatch}>
                        <i className="fi fi-rr-box-open" />
                      </ActionIconContainer>
                    </Tooltip>
                  )}
                  {showEditIcon &&
                    (screen.includes("parts") ? (
                      <Tooltip text={t("edit_part")}>
                        <ActionIconContainer onClick={handleEdit}>
                          <i className="fi fi-rr-edit" />
                        </ActionIconContainer>
                      </Tooltip>
                    ) : (
                      <ActionIconContainer onClick={handleEdit}>
                        <i className="fi fi-rr-edit" />
                      </ActionIconContainer>
                    ))}
                  {showDeleteIcon &&
                    (screen.includes("parts") ? (
                      <Tooltip text={t("delete_part")}>
                        <ActionIconContainer onClick={handleDelete}>
                          <i className="fi fi-rr-trash" />
                        </ActionIconContainer>
                      </Tooltip>
                    ) : (
                      <ActionIconContainer onClick={handleDelete}>
                        <i className="fi fi-rr-trash" />
                      </ActionIconContainer>
                    ))}
                  {screen.includes("issues") &&
                    rowData.status != 2 &&
                    roleId != 4 && (
                      <Dropdown
                        rowData={rowData}
                        currentVehicle={currentVehicle}
                      />
                    )}
                </>
                {/* )} */}
                {screen.includes("workorders") && (
                  <WOSidePanelDropdown
                    rowData={rowData}
                    pauses={pauses}
                    setRightPanelData={setRightPanelData}
                    currentVehicle={currentVehicle}
                    logs={logs}
                    setLogs={setLogs}
                    serviceTasks={serviceTasks}
                    setServiceTasks={setServiceTasks}
                  />
                )}
                <ActionIconContainer
                  //style={{ fontSize: "18px", top: "1px" }}
                  onClick={() =>
                    setRightPanelData((prev) => ({ ...prev, show: false }))
                  }
                >
                  <i
                    className="fi fi-rr-cross-small"
                    //style={{ fontSize: "16px" }}
                  />
                </ActionIconContainer>
              </Col>
            </Col>
          </Row>

          <Row>
            <Col>
              {screen.includes("users") ? (
                <UserInfoComponent rowData={rowData} screen={screen} />
              ) : screen.includes("parts") ? (
                <PartInfoComponent rowData={rowData} />
              ) : (
                <VehicleInfoComponent
                  rowData={rowData}
                  screen={screen}
                  currentVehicle={currentVehicle}
                />
              )}
            </Col>
            {/* {screen.includes("vehicles") && (
              <Col className="d-flex justify-content-end align-items-end mb-2">
                <VehicleStatus rowData={rowData} />
              </Col>
            )} */}

            {/* {screen.includes("trips") && (
              <Col className="d-flex justify-content-end align-items-end mb-2">
                <span style={{ fontWeight: 400 }}>{rowData.uname}</span>
              </Col>
            )}

            {screen.includes("workorders") && (
              <Col className="d-flex justify-content-end align-items-center">
                <WorkOrderStatus status={rowData.status} />
              </Col>
            )} */}
          </Row>
          {/* </Col> */}
        </div>
        {/* <FillupsRightPanel rowData={rowData} /> */}
        <RightPanelData
          //height={height}
          height={fixedPanelHeight}
        >
          {getRightPanelComponent()}
        </RightPanelData>
        {/* {comments && comments !== "N/A" && (
        <Row>
        <Col sm={12}>
            <div>
              <ItemLabel>Notes</ItemLabel>
              <textarea style={{ minWidth: '100%'}} disabled>{comments}</textarea>
            </div>
          </Col>
        </Row>
      )} */}
        {showDeletePopup && (
          <DeletePopup
            showPopup={showDeletePopup}
            setShowPopup={setShowDeletePopup}
            deleteApiData={singleDeleteApiData}
            screen={screen}
            setShowInfoPopup={setShowInfoPopup}
            setInfoData={setInfoData}
          />
        )}
        {showInfoPopup && (
          <InfoPopup
            showPopup={showInfoPopup}
            setShowPopup={setShowInfoPopup}
            message={infoData?.msg}
            icon={infoData?.icon}
            listMsg={infoData?.listMsg}
          />
        )}
      </RightPanelContainer>
    </>
  );
};

export default RightPanel;

//styles

export const RightPanelContainer = styled.div`
  border-left: 1px solid ${colorLightGray};
  border-bottom: 1px solid ${colorLightGray};
  overflow: hidden;
  overflow-x: hidden;
  ${scrollbarStyles};
  height: ${(p) => p.height}px;

  // @media screen and (min-width: 1280px) {
  //   height: 55vh;
  // }

  // @media screen and (min-width: 1536px) {
  //   height: 60vh;
  // }

  // @media screen and (min-width: 1920px) {
  //   height: 70vh;
  // }

  // @media screen and (min-width: 2880px) {
  //   height: 75vh;
  // }
`;

export const ItemContainer = styled.div`
  min-width: 100px;
  margin: 8px 0px;
`;

export const ItemLabel = styled.p`
  white-space: nowrap;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 2px;
`;
export const ItemValue = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: -webkit-box;
  //-webkit-line-clamp: 2;
  //-webkit-box-orient: vertical;
  //overflow: hidden;
  //text-overflow: ellipsis;
`;

export const ActionIconWrap = styled.span`
  svg {
    width: 16px;
    height: 16px;
    fill: ${colorGray};
    cursor: pointer;
    &:hover {
      fill: ${colorLightGray};
    }
  }
`;

export const IconWrap = styled.span`
  svg {
    margin: 4px 6px 6px 0;
    width: 16px;
    height: 16px;
    fill: ${colorGray};
  }
`;

export const RightPanelData = styled.div`
  ${scrollbarStyles};

  overflow-y: scroll;
  overflow-x: hidden;
  //overflow: auto;
  padding: 0 8px 8px 8px;

  //height: ${(p) => p.height - 108}px;
  height: ${(p) => p.height}px;

  // @media screen and (min-width: 1280px) {
  //   height: 70%;
  // }

  // @media screen and (min-width: 1536px) {
  //   height: 75%;
  // }

  // @media screen and (min-width: 1920px) {
  //   height: 90%;
  // }

  // @media screen and (min-width: 2880px) {
  //   height: 95%;
  // }
`;

// export const ActionIcon = styled.i`
//   position: relative;
//   cursor: pointer;
//   width: 24px;
//   height: 24px;
//   //top: 1px;
//   font-size: 16px;
//   color: ${colorGray};
//   &:hover {
//     color: ${colorLightGray};
//   }
// `;

export const NotesValue = styled.p`
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${(p) =>
    p.clamped &&
    `
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  `};
`;

export const ReadMoreButton = styled.span`
  font-size: 11px;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid ${colorLightGray};
  }
`;

//Sidepanel dropdown for issues
export const Dropdown = ({ rowData, currentVehicle }) => {
  const issueOptionsRef = useRef();
  const [showCloseIssuePopup, setShowCloseIssuePopup] = useState(false);
  const history = useHistory();
  const { t } = useTranslation("common");

  //State to show create wo confirmation popup
  const [showCreateWOPopup, setShowCreateWOPopup] = useState(false);

  //For showing info popup during save
  const [vehicleUpdErrData, setVehicleUpdErrData] = useState({
    message: "",
    icon: { className: "fi fi-rr-triangle-warning", color: "red" },
  });
  const [vehicleUpdErr, setVehicleUpdErr] = useState(false);

  const { tag } = useAuth();

  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!issueOptionsRef?.current?.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [issueOptionsRef]);

  const [open, setOpen] = useState(false);

  const handleCloseIssue = () => {
    if (rowData.priority == 0) {
      history.push("/issues/addnew", {
        mode: "from-issues-table-error",
        data: { rowData },
        type: "edit",
      });
      return;
    }
    setShowCloseIssuePopup(true);
  };

  const handleCloseIssueWithService = () => {
    if (rowData.priority == 0) {
      history.push("/issues/addnew", {
        mode: "from-issues-table-error",
        data: { rowData },
        type: "edit",
      });
      return;
    }
    const vehOutOfService = currentVehicle?.secondaryStatus == 1;
    const issueApiData = {
      querystring: "addEditIssues",
      operation: "update",
      issue_id: rowData?.issue_id,
      org_id: rowData?.org_id,
      veh_id: rowData?.veh_id,
      odo: rowData?.odo,
      issue: rowData?.issue,
      issue_desc: rowData?.issue_desc,
      reported_by: rowData?.reported_by,
      reported_date: rowData?.reported_date,
      close_date: rowData?.close_date,
      source: rowData?.source,
      status: rowData?.status,
      priority: rowData?.priority,
      comments: rowData?.comments,
      inspection_id: rowData?.inspection_id,
      work_order_id: rowData?.work_order_id,
      service_id: rowData?.service_id,
      issue_img_name: rowData?.issue_img_name,
      action: 2,
      //secondary_status: vehOutOfService ? 0 : 1,
    };
    const data = {
      issueApiData,
      issueImagesToS3: [],
      vehOutOfService,
    };
    history.push("/services/services/addnew", {
      type: "add",
      route: "/services/services/addnew",
      screen: "services",
      data,
      mode: "close-issue-with-service",
    });
  };

  const handleCreateWOClick = () => {
    if (tag === "essential-user") {
      setShowSubscribePopup(true);
      return;
    }
    setShowCreateWOPopup(true);
  };

  const handleCreateWO = () => {
    history.push("/workorders/addnew", {
      data: rowData,
      type: "add",
      mode: "from-add-issue",
    });
  };

  return (
    <>
      <CloseIssuePopup
        rowData={rowData}
        showCloseIssuePopup={showCloseIssuePopup}
        setShowCloseIssuePopup={setShowCloseIssuePopup}
        setVehicleUpdErr={setVehicleUpdErr}
        setVehicleUpdErrData={setVehicleUpdErrData}
      />
      {showCreateWOPopup && (
        <ActionPopup
          showPopup={showCreateWOPopup}
          setShowPopup={setShowCreateWOPopup}
          handleYes={handleCreateWO}
          message={t("create_wo_from_issue_confirmation_msg", { count: 1 })}
          handleNo={() => setShowCreateWOPopup(false)}
        />
      )}
      {vehicleUpdErr && (
        <InfoPopup
          message={vehicleUpdErrData.message}
          icon={vehicleUpdErrData.icon}
          showPopup={vehicleUpdErr}
          setShowPopup={setVehicleUpdErr}
        />
      )}
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={t("subscribe_to_advanced_msg")}
        />
      )}
      <div style={{ position: "relative", top: "-2px" }} ref={issueOptionsRef}>
        {/* <FilterButton
          onClick={() => setOpen(!open)}
          style={{
            width: "14px",
            minWidth: "10px",
            height: "18px",
            padding: "4px",
            borderColor: colorGray,
            borderRadius: 5,
          }}
        >
          <Icon
            className="fi fi-rr-menu-dots-vertical m-0"
            style={{ fontSize: "12px" }}
          ></Icon>
        </FilterButton> */}
        <ActionIconContainer
          onClick={() => setOpen(!open)}
          style={{ position: "relative", top: "2px" }}
        >
          <i className="fi fi-rr-menu-dots-vertical" />
        </ActionIconContainer>
        {open && (
          <DropdownWrap>
            {rowData.status == 0 && (
              <OptionsMenu onClick={handleCreateWOClick}>
                <span>{t("create_wo_entry")}</span>
                {tag === "essential-user" && (
                  <SubscribeTooltipIcon
                    message={t("subscribe_to_advanced_msg")}
                  />
                )}
              </OptionsMenu>
            )}
            <OptionsMenu onClick={handleCloseIssueWithService}>
              <span>{t("close_with_service_entry")}</span>
            </OptionsMenu>
            <OptionsMenu onClick={handleCloseIssue}>
              <span>{t("close_issue")}</span>
            </OptionsMenu>
          </DropdownWrap>
        )}
      </div>
    </>
  );
};

export const DropdownWrap = styled.div`
  border: 1px solid ${colorLightGray};
  position: absolute;
  margin: 0.25rem;
  //border-radius: 5px;
  //width: 170px;
  right: -50px;
  //width: 50px !important;
`;

export const DropdownItem = styled.div`
  cursor: pointer;
  font-size: 12px;
  padding: 0.25rem 0.5rem;
  background: white;
  //width: max-content;
  &:hover {
    background: ${colorLightGray};
  }
`;

//Popups for Delete flow for issues

export const DeleteServicePopup = ({
  showDeleteServicePopup,
  setShowDeleteServicePopup,
  rowData,
  setShowDeletePopup,
}) => {
  const { t } = useTranslation("common");

  const handleYes = () => {
    //Delete the associated service
    (async () => {
      // let serviceDeleteApiData = {
      //   querystring: "delete_service",
      //   service_id: rowData?.service_id,
      // };
      let serviceDeleteApiData = {
        delete_ids: rowData?.service_id,
        querystring: "multiple_delete",
        sub_query_string: "SERVICES",
      };
      const response = await postApi(serviceDeleteApiData, "deleteData");
      if (response.success) {
        setShowDeleteServicePopup(false);
        setShowDeletePopup(true);
      }
    })();
  };

  const handleNo = () => {
    setShowDeleteServicePopup(false);
    setShowDeletePopup(true);
  };

  return (
    <Modal
      show={showDeleteServicePopup}
      onHide={() => setShowDeleteServicePopup(!showDeleteServicePopup)}
      centered
      size="md"
    >
      <Modal.Body>
        <div
          className="d-flex justify-content-end"
          style={{ width: "-webkit-fill-available" }}
        >
          <ActionIconContainer onClick={() => setShowDeleteServicePopup(false)}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
          {/* <ActionIcon
            className="fi fi-rr-cross-small"
            onClick={() => setShowDeleteServicePopup(false)}
          ></ActionIcon> */}
        </div>
        <div className="d-flex gap-2 p-2">
          <DisplayIcon className="fi fi-rr-info" color={colorBlue} />
          <p className="d-flex justify-content-center align-items-center">
            {/* <span> */}
            {t("delete_service_with_issue_popup_msg")}
            {/* </span> */}
          </p>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center p-2 gap-3">
          <AddFormButton
            onClick={handleYes}
            type="button"
            style={{ width: 300 }}
          >
            {/* {isPopupSaveLoading ? <CustomLoader /> : t("yes")} */}
            {t("delete_service_and_issue")}
          </AddFormButton>
          <FilterButton onClick={handleNo} type="button" style={{ width: 300 }}>
            {/* {t("no")} */}
            {t("delete_only_issue")}
          </FilterButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

// export const DeleteConfirmationPopup = ({
//   rowData,
//   showDeleteConfirmationPopup,
//   setShowDeleteConfirmationPopup,
//   setVehicleUpdErrData,
//   setVehicleUpdErr,
// }) => {
//   const vehiclesList = useSelector((state) => state.vehicles.list);

//   // const singleDeleteApiData = {
//   //   issue_id: rowData.issue_id,
//   //   action: 3,
//   //   querystring: "addEditIssues",
//   // };
//   const singleDeleteApiData = {
//     delete_ids: rowData?.issue_id,
//     querystring: "multiple_delete",
//     sub_query_string: "ISSUES",
//   };

//   const dispatch = useDispatch();
//   const { t } = useTranslation("common");

//   const selectedVehicle = vehiclesList?.find(
//     (v) => v.vehicleId === rowData?.veh_id
//   );
//   const secondaryStatus = selectedVehicle?.secondaryStatus;

//   //Button Loading States
//   const [yesButtonLoading, setYesButtonLoading] = useState(false);
//   const [noButtonLoading, setNoButtonLoading] = useState(false);
//   const modalContent = {
//     iconClassName:
//       secondaryStatus == 1 ? "fi fi-rr-info" : "fi fi-rr-triangle-warning",
//     iconColor: secondaryStatus == 1 ? colorBlue : colorYellowNew,
//     bodyText:
//       secondaryStatus == 1
//         ? t("veh_back_in_service_popup_msg", {
//             vehicleName: selectedVehicle?.label,
//           })
//         : t("delete_record_one", { count: 1 }), //t("_issue_popup_msg"),
//   };

//   const deleteSingleRecord = async (deleteApiData) => {
//     const response = await postApi(deleteApiData, "issue");
//     if (response.success === 1) {
//       dispatch(updateDeletedData({ query: deleteApiData.query }));
//       //toggleDeleted();
//       setShowDeleteConfirmationPopup(false);
//       dispatch(addMsg(t("rec_del_suc")));
//     }
//   };

//   const handleYes = () => {
//     setYesButtonLoading(true);
//     if (secondaryStatus == 1) {
//       (async () => {
//         const vehicleApiData = {
//           veh_id: selectedVehicle?.vehicleId,
//           secondary_status: 0,
//           veh_active: 1,
//           operation: "update",
//         };
//         const res = await postApi(vehicleApiData, "vehicle");
//         if (res?.success === 1) {
//           (async () => {
//             const vehiclesRes = await getVehicles("getallvehicle", t);
//             dispatch(updateVehiclesList(vehiclesRes));
//           })();
//           const res = await deleteSingleRecord(singleDeleteApiData);
//           if (res?.success === 1) {
//             setYesButtonLoading(false);
//             setShowDeleteConfirmationPopup(false);
//           }
//         } else {
//           const { vehicle_data } = res;
//           if (vehicle_data === "duplicate_entry") {
//             setVehicleUpdErrData((prev) => ({
//               ...prev,
//               message: t("duplicate_entry_veh_err"),
//             }));
//           } else if (vehicle_data === "vehicle_limit_reached") {
//             setVehicleUpdErrData((prev) => ({
//               ...prev,
//               message: t("vehicle_limit_reached_err"),
//             }));
//           } else if (vehicle_data === "trail_vehicle_limit_reached") {
//             setVehicleUpdErrData((prev) => ({
//               ...prev,
//               message: t("trail_vehicle_limit_reached_err"),
//             }));
//           }
//           setYesButtonLoading(false);
//           setVehicleUpdErr(true);
//           setShowDeleteConfirmationPopup(false);
//         }
//       })();
//     } else {
//       (async () => {
//         // const vehicleApiData = {
//         //   veh_id: vehicle?.vehicleId,
//         //   secondary_status: 0,
//         //   veh_active: 1,
//         //   operation: "update",
//         // };
//         const res = await deleteSingleRecord(singleDeleteApiData);
//         if (res?.success === 1) {
//           setYesButtonLoading(false);
//           setShowDeleteConfirmationPopup(false);
//         }
//       })();
//     }
//   };

//   const handleNo = () => {
//     if (secondaryStatus == 1) {
//       setNoButtonLoading(true);
//       (async () => {
//         // const vehicleApiData = {
//         //   veh_id: vehicle?.vehicleId,
//         //   secondary_status: 0,
//         //   veh_active: 1,
//         //   operation: "update",
//         // };
//         const res = await deleteSingleRecord(singleDeleteApiData);
//         if (res.success === 1) {
//           setNoButtonLoading(false);
//           setShowDeleteConfirmationPopup(false);
//         }
//       })();
//     } else {
//       setShowDeleteConfirmationPopup(false);
//     }
//   };

//   return (
//     <Modal
//       show={showDeleteConfirmationPopup}
//       onHide={() =>
//         setShowDeleteConfirmationPopup(!showDeleteConfirmationPopup)
//       }
//       centered
//       size="sm"
//     >
//       <Modal.Body>
//         <div
//           className="d-flex justify-content-end"
//           style={{ width: "-webkit-fill-available" }}
//         >
//           {/* <ActionIcon
//             className="fi fi-rr-cross-small"
//             onClick={() => setShowDeleteConfirmationPopup(false)}
//           ></ActionIcon> */}
//           <ActionIconContainer
//             onClick={() => setShowDeleteConfirmationPopup(false)}
//             id="close-issue-popup-icon-container"
//           >
//             <i className="fi fi-rr-cross-small" id="close-issue-popup-icon" />
//           </ActionIconContainer>
//         </div>
//         <div className="d-flex gap-2 p-2">
//           <DisplayIcon
//             className={modalContent.iconClassName}
//             color={modalContent.iconColor}
//           />
//           <p className="d-flex justify-content-center align-items-center">
//             {/* <span> */}
//             {modalContent?.bodyText}
//             {/* </span> */}
//           </p>
//         </div>
//         <div className="d-flex justify-content-center align-items-center p-2 gap-3">
//           <FilterButton onClick={handleNo} type="button">
//             {noButtonLoading ? <CustomLoader /> : t("no")}
//           </FilterButton>
//           <AddFormButton onClick={handleYes} type="button">
//             {yesButtonLoading ? <CustomLoader /> : t("yes")}
//           </AddFormButton>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// };

export const CloseIssuePopup = ({
  rowData,
  showCloseIssuePopup,
  setShowCloseIssuePopup,
  setVehicleUpdErrData,
  setVehicleUpdErr,
}) => {
  const vehiclesList = useSelector((state) => state.vehicles.list);

  const { setReload } = useAuth();

  const issueJsonData = {
    issue_id: rowData?.issue_id,
    //close_date: getUnixTime(new Date()),
    querystring: "addEditIssues",
    operation: "update",
    org_id: rowData?.org_id,
    veh_id: rowData?.veh_id, //selectedVehicle.vehicleId,
    odo: rowData?.odo,
    issue: rowData?.issue,
    issue_desc: rowData?.issue_desc,
    reported_by: rowData?.reported_by,
    reported_date: getUnixTime(rowData?.reported_date),
    source: rowData?.source,
    status: 2,
    priority: rowData?.priority,
    comments: rowData?.comments,
    inspection_id: rowData?.inspection_id,
    work_order_id: rowData?.work_order_id,
    service_id: rowData?.service_id,
    issue_img_name: rowData?.issue_img_name,
    action: 2,
  };

  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  const selectedVehicle = vehiclesList?.find(
    (v) => v.vehicleId === rowData?.veh_id
  );
  const secondaryStatus = selectedVehicle?.secondaryStatus;

  //Button Loading States
  const [yesButtonLoading, setYesButtonLoading] = useState(false);
  const [noButtonLoading, setNoButtonLoading] = useState(false);
  const modalContent = {
    iconClassName:
      secondaryStatus == 1 ? "fi fi-rr-info" : "fi fi-rr-triangle-warning",
    iconColor: secondaryStatus == 1 ? colorBlue : colorYellowNew,
    bodyText:
      secondaryStatus == 1
        ? t("veh_back_in_service_popup_msg", {
            vehicleName: selectedVehicle?.label,
          })
        : t("close_issue_popup_msg"),
  };
  const handleYes = async () => {
    const issueData = {
      close_date: getUnixTime(new Date()),
      ...issueJsonData,
    };
    setYesButtonLoading(true);
    if (secondaryStatus == 1) {
      // (async () => {
      const vehicleApiData = {
        veh_id: selectedVehicle?.vehicleId,
        secondary_status: 0,
        //veh_active: 1,
        operation: "update",
      };
      const res = await post("vehicle", vehicleApiData);

      //Vehicle call errors
      if (!res?.success) {
        const { code } = res;
        if (code == 1) {
          setVehicleUpdErrData((prev) => ({
            ...prev,
            message: t("vehicle_limit_reached_err"),
          }));
        } else if (code == 2) {
          setVehicleUpdErrData((prev) => ({
            ...prev,
            message: t("duplicate_entry_veh_name_err"),
          }));
        } else if (code == 3) {
          setVehicleUpdErrData((prev) => ({
            ...prev,
            message: t("duplicate_entry_veh_vin_err"),
          }));
        } else if (code == 4) {
          setVehicleUpdErrData((prev) => ({
            ...prev,
            message: t("duplicate_entry_veh_license_err"),
          }));
        } else if (code == 5) {
          setVehicleUpdErrData((prev) => ({
            ...prev,
            message: t("duplicate_entry_veh_err"),
          }));
        } else {
          setVehicleUpdErrData((prev) => ({
            ...prev,
            message: t("form_err_msg"),
          }));
        }
        setYesButtonLoading(false);
        setVehicleUpdErr(true);
        setShowCloseIssuePopup(false);
        return;
      }

      if (res?.success === 1) {
        (async () => {
          const vehiclesRes = await getVehicles("getallvehicle", t);
          dispatch(updateVehiclesList(vehiclesRes));
        })();
        const res = await postApi(issueData, "issue");
        if (res?.success === 1) {
          setReload("issues");
          dispatch(addMsg("Issue Closed"));
          setShowCloseIssuePopup(false);
          setYesButtonLoading(false);
        }
      }
    } else {
      // (async () => {
      // const vehicleApiData = {
      //   veh_id: vehicle?.vehicleId,
      //   secondary_status: 0,
      //   veh_active: 1,
      //   operation: "update",
      // };
      const res = await postApi(issueData, "issue");
      if (res?.success === 1) {
        setReload("issues");
        setShowCloseIssuePopup(false);
        setYesButtonLoading(false);
        //toggleReload();
      }
      // })();
    }
  };

  const handleNo = () => {
    const issueData = {
      close_date: getUnixTime(new Date()),
      ...issueJsonData,
    };
    if (secondaryStatus == 1) {
      setNoButtonLoading(true);
      (async () => {
        // const vehicleApiData = {
        //   veh_id: vehicle?.vehicleId,
        //   secondary_status: 0,
        //   veh_active: 1,
        //   operation: "update",
        // };
        const res = await postApi(issueData, "issue");
        if (res.success === 1) {
          setReload("issues");
          setShowCloseIssuePopup(false);
          setNoButtonLoading(false);
          //toggleReload();
        }
      })();
    } else {
      setShowCloseIssuePopup(false);
    }
  };

  return (
    <Modal
      show={showCloseIssuePopup}
      onHide={() => setShowCloseIssuePopup(!showCloseIssuePopup)}
      centered
      size="sm"
    >
      <Modal.Body>
        <div
          className="d-flex justify-content-end"
          style={{ width: "-webkit-fill-available" }}
        >
          {/* <ActionIcon
            className="fi fi-rr-cross-small"
            onClick={() => setShowCloseIssuePopup(false)}
          ></ActionIcon> */}
          <ActionIconContainer
            onClick={() => setShowCloseIssuePopup(false)}
            id="close-issue-popup-icon-container"
          >
            <i className="fi fi-rr-cross-small" id="close-issue-popup-icon" />
          </ActionIconContainer>
        </div>
        <div className="d-flex gap-2 p-2">
          <DisplayIcon
            className={modalContent.iconClassName}
            color={modalContent.iconColor}
          />
          <p className="d-flex justify-content-center align-items-center">
            {/* <span> */}
            {modalContent?.bodyText}
            {/* </span> */}
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center p-2 gap-3">
          <FilterButton onClick={handleNo} type="button">
            {noButtonLoading ? <CustomLoader /> : t("no")}
          </FilterButton>
          <AddFormButton onClick={handleYes} type="button">
            {yesButtonLoading ? <CustomLoader /> : t("yes")}
          </AddFormButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const VehicleInfoComponent = ({ rowData, screen, currentVehicle }) => {
  const { t } = useTranslation("common");
  const allGroups = useSelector((state) => state.groups.list);
  const { name, org_id, veh_id, veh_img, group_name } = rowData;
  const { integrationsData } = useAuth();

  const groupId = rowData?.group_id;
  let groupName = rowData?.group_name;
  if (groupId) {
    groupName = allGroups?.find((g) => g.group_id === groupId)?.label;
  }

  const imageUrl =
    veh_img && veh_img !== NATEXT
      ? `${s3Url}/org_${org_id}/vehicle_${veh_id}/${veh_img}`
      : defaultVehicleIcon;

  const showTelematicsActive = integrationsData.find(
    (data) =>
      data.provider_type === 1 &&
      ((data.service_type === 1 && data.service_enabled === 1) ||
        (data.service_type === 2 && data.service_enabled === 1))
  )
    ? true
    : false;

  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex gap-2 align-items-center justify-content-start py-2">
        <div
          style={{
            border: `1px solid ${colorBlue}`,
            borderRadius: "20px",
          }}
        >
          <img
            src={imageUrl}
            alt="vehicle-img"
            style={{ width: "40px", height: "40px", objectFit: "cover" }}
            className="rounded-circle"
          />
        </div>
        <div className="d-flex flex-column" style={{ maxWidth: "15vw" }}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: 500,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {/* {name?.length > 20 ? `${name.slice(0, 20)}...` : name} */}
            {name}
          </p>
          <p>{groupName ? groupName : ""}</p>
          {screen.includes("inspectionsubmissions") && (
            <p style={{ fontSize: "12px" }}>{rowData.form_name}</p>
          )}
          {/* {(screen.includes("issues") || screen.includes("workorders")) && */}
          {currentVehicle?.secondaryStatus == 1 &&
            currentVehicle?.veh_active == 1 && (
              <p style={{ fontSize: "12px", color: "red" }}>
                {t("out_of_service")}
              </p>
            )}
        </div>
      </div>
      {screen.includes("faultcodes") && (
        <div className="d-flex align-self-end mb-2">
          <VehicleStatus rowData={rowData} />
        </div>
      )}

      {screen.includes("vehicles") && (
        <div className="d-flex flex-column align-items-end justify-content-end mb-1 gap-1">
          {showTelematicsActive && !!currentVehicle?.provider_type_1 && (
            <div className="d-flex gap-1 align-items-center">
              <div
                style={{
                  height: "10px",
                  width: "10px",
                  backgroundColor: "green",
                  borderRadius: "50%",
                  display: "inlineBlock",
                }}
              ></div>
              <p style={{ fontSize: 12 }}>{t("telematics_active")}</p>
            </div>
          )}
          <VehicleStatus rowData={rowData} />
        </div>
      )}

      {screen.includes("trips") && (
        <div className="d-flex align-self-end mb-2">
          <span style={{ fontWeight: 400 }}>{rowData.uname}</span>
        </div>
      )}

      {screen.includes("workorders") && (
        <div className="d-flex align-self-end mb-2">
          <WorkOrderStatus status={rowData.status} />
        </div>
      )}
    </div>
  );
};

export const UserInfoComponent = ({ rowData, screen }) => {
  const {
    name,
    org_id,
    user_id,
    user_img,
    user_active,
    email,
    phone,
    manager_id,
    mangaer_name,
    role_id,
    role_name,
  } = rowData;

  const imageUrl = `${s3Url}/org_${org_id}/user_${user_id}/profile/${user_img}`;

  let userInitials = "";
  const userNameArr = name.split(" ");
  userNameArr.forEach((name) => (userInitials += name[0]?.toUpperCase()));
  userInitials = userInitials.slice(0, 3);

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex gap-2 align-items-center py-2">
        <div
          style={{
            border: `1px solid ${colorBlue}`,
            borderRadius: "20px",
          }}
        >
          {user_img ? (
            <img
              src={imageUrl}
              alt="user-img "
              style={{ width: "40px", height: "40px" }}
              className="rounded-circle"
            />
          ) : (
            <div
              className="rounded-circle d-flex align-items-center justify-content-center"
              style={{
                width: 40,
                height: 40,
                border: `1px solid ${colorLightBlue}`,
                background: "gray",
                color: "white",

                textAlign: "center",
                //padding: "0.25rem",
              }}
            >
              <span style={{ fontSize: "12px" }}>{userInitials}</span>
            </div>
          )}
        </div>
        <div style={{ overflow: "auto" }}>
          <p style={{ fontSize: "14px", fontWeight: 500 }}>{name}</p>

          <p style={{ fontSize: "12px" }}>{rowData.role_name}</p>
        </div>
      </div>

      <UserStatus status={user_active} />
    </div>
  );
};
