import React, { useRef, useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { Nav, Tab } from "react-bootstrap";
import styled from "styled-components";
import getUnixTime from "date-fns/getUnixTime";
import { FormProvider, useForm } from "react-hook-form";

import { Card, CardBody } from "@/shared/components/Card";
import FormHeader from "../../shared/components/form/FormHeader";
import { NavItem, NavLink, TabsWrap } from "@/shared/components/Tabs";
import { FormContainer } from "../../shared/components/form/FormElements";
import { scrollbarStyles } from "../../utils/palette";
import { addMsg } from "../../features/snackbar/snackbarSlice";
import {
  getToken,
  logErrors,
  post,
  postApi,
  urlConfig,
} from "../../api/getUser";
import InfoPopup from "../../shared/components/InfoPopup";
import { DeletedContext } from "../../containers/App/Router/WrappedRoutes";
import {
  getDisplayDate,
  getDistanceFactor,
  isObjEmpty,
  uploadDocsToS3,
} from "../../shared/helpers";
import VehicleDetailsForm from "./VehicleDetailsForm";
import UsersAssignedForm from "./UsersAssignedForm";
import DocumentsForm from "../UserManagement/DocumentsForm";
import VehicleSpecsForm from "./VehicleSpecsForm";
import { useAuth } from "../../contexts/AuthContext";
import { auth } from "../../api/firebase";
import axios from "axios";
import IntegrationsForm from "./IntegrationsForm";

const VehiclesForm = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  const routeData = location?.state?.data;
  const rowData = routeData?.rowData;
  const screen = location?.state?.screen;
  const type = location?.state?.type;

  const { orgId } = useSelector((state) => state.user);
  const { distance } = useSelector((state) => state.units);

  //let vehicleInfoRef = useRef({});
  let licenseInfo = useRef({});

  const methods = useForm();
  const { watch } = methods;
  const { errors } = methods.formState;
  //console.log(location?.state);

  const vehicleName = watch("vehicleName");
  const licenseNo = watch("licenseNo");
  const vehicleType = watch("vehicleType");
  const vin = watch("vin");
  const status = watch("status");
  const make = watch("make");
  const model = watch("model");
  const year = watch("year");
  const group = watch("group");
  const fuelType = watch("fuelType");
  const primaryMeter = watch("primaryMeter");
  const startingOdo = watch("startingOdo");
  const purchasePrice = watch("purchasePrice");
  const outOfService = watch("outOfService");
  const recommendMaintSchedule = watch("recommendMaintSchedule");

  const telematicsDataSync = watch("telematicsDataSync");
  //console.log(name);

  const [documents, setDocuments] = useState([]);
  const [docsIncomplete, setDocsIncomplete] = useState(false);

  //State to check if any document has been changed to updated last updated date
  const [docUpdated, setDocUpdated] = useState(false);

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  //For showing info popup during save
  const [addVehicleFailedData, setAddVehicleFailedData] = useState({
    message: "",
    icon: { className: "fi fi-rr-triangle-warning", color: "red" },
  });
  const [addVehicleFailed, setAddVehicleFailed] = useState(false);

  //State for users assigned
  const [usersAssigned, setUsersAssigned] = useState([]);
  //State to check if users assigned has been changed
  const [userAssignedUpdated, setUserAssignedUpdated] = useState(false);

  //Ref for VehicleSpecsForm Data
  const vehicleSpecsInfoRef = useRef(null);

  const [activeKey, setActiveKey] = useState("1");

  const [vehiclePhoto, setVehiclePhoto] = useState("");

  const checkDocsIncomplete = () => {
    for (const doc of documents) {
      if (!doc.docName || !doc.image) {
        //console.log(!doc.docName || !doc.image);
        return true;
      }
    }
    return false;
  };

  //console.log(errors);
  const onSubmit = async () => {
    try {
      setDocuments((prev) => {
        let temp = [...prev];
        for (let doc of temp) {
          if (!doc.docName || !doc.image) {
            doc.isError = true;
          } else {
            doc.isError = false;
          }
        }
        return temp;
      });
      const docsIncomplete = checkDocsIncomplete();
      if (docsIncomplete) {
        setDocsIncomplete(true);
        return;
      }

      const vehicleId =
        type === "edit" ? rowData.veh_id : `${orgId}_${Date.now()}`;

      // const {
      //   vehicleName,
      //   licenseNo,
      //   vehicleType,
      //   vin,
      //   make,
      //   group,
      //   model,
      //   vehiclePhoto,
      //   status,
      //   year,
      //   fuelType,
      //   primaryMeter,
      //   purchasePrice,
      //   startingOdo,
      //   outOfService,
      // } = vehicleInfoRef.current;

      setSaveBtnLoading(true);

      //Check if vehicle already exists
      const token = await getToken(); //sessionStorage.getItem("token");
      const email = auth?.currentUser?.email;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const uniqueVehicleApiRes = await axios.post(
        urlConfig.commonNew,
        {
          email,
          querystring: "check_vehicle_exist",
          operation: type === "edit" ? "update" : "create",
          veh_id: vehicleId,
          name: vehicleName ? vehicleName : "",
          vin_no: vin ? vin : "",
          license_no: licenseNo ? licenseNo : "",
        },
        config
      );

      const resData = uniqueVehicleApiRes.data;
      // if (resData.success) {
      const { data } = resData;
      let errMsg = "";
      if (data == 1 || data == 2 || data == 3 || data == 4) {
        if (data == 1) {
          errMsg = t("vehicle_limit_reached_err");
        } else if (data == 2) {
          errMsg = t("duplicate_entry_veh_name_err");
        } else if (data == 3) {
          errMsg = t("duplicate_entry_veh_vin_err");
        } else if (data == 4) {
          errMsg = t("duplicate_entry_veh_license_err");
        }
        setAddVehicleFailedData((prev) => ({
          ...prev,
          message: errMsg,
        }));
        setAddVehicleFailed(true);
        setSaveBtnLoading(false);
        return;
      }
      // }

      const { defaultData, additionalData } = vehicleSpecsInfoRef?.current;

      const {
        width,
        height,
        length,
        weight,
        cargoVol,
        maxPayload,
        frontTireInfo,
        frontTirePSI,
        rearTireInfo,
        rearTirePSI,
        fuelQuality,
        oil,
        oilCapacity,
        fuelTankCapacity,
      } = defaultData;

      let vehicleSpecsFieldsString = "";
      additionalData?.forEach((f) => {
        vehicleSpecsFieldsString += `${f.title}~~${f.author}:::`;
      });
      vehicleSpecsFieldsString = vehicleSpecsFieldsString?.slice(
        0,
        vehicleSpecsFieldsString?.length - 3
      );

      let docs = { All_Documents: [] };

      let imagesToS3 = [];

      documents?.forEach((d) => {
        const currentDate = new Date();
        let items = [];
        if (d.imgUpdated) {
          items.push({
            "Updated date": getDisplayDate(getUnixTime(currentDate)),
          });
        }
        d.fields.forEach((f) => {
          items.push({ [f.title]: f.author ? f.author : "" });
        });

        let imageName = `${d.docName}_${getUnixTime(currentDate)}.${
          d.image.extension
        }`;

        let temp = {};
        temp[d.docName] = {
          items: items,
          image: d.image.file ? imageName : d.image.type,
          visible: d.isVisible,
          //image_format: d.image.imgFormat,
        };
        docs.All_Documents.push(temp);

        if (d.image.file) {
          imagesToS3.push({
            file: d.image.file,
            key: `image_data/org_${orgId}/vehicle_${vehicleId}/vehicle_doc/${imageName}`,
          });
        }
      });

      //const startingTime = startTime ? startTime + ":00" : "00:00:00";
      //const endingTime = endTime ? endTime + ":00" : "00:00:00";

      // const apiData = {
      //   sync_version: "v2",
      //   user_id: type === "edit" ? rowData.user_id : `${orgId}_${Date.now()}`,
      //   user_email: email ? email : "",
      //   fname: name ? name : "",
      //   user_phone: phone ? phone : "",
      //   user_address: address ? address : "",
      //   role_id: role.value,
      //   manager_name:
      //     role.value === 1 || role.value === 4 ? "N/A" : manager.label,
      //   manager_id: role.value === 1 || role.value === 4 ? -1 : manager.user_id,
      //   comments: comments ? comments : "",
      //   user_img: userImg ? userImg.imgName : "",
      //   license_img: licenseImg ? licenseImg.imgName : "",
      //   license_info: licenseData,
      //   dot_card_img: "",
      //   dot_card_info: "",
      //   action: type === "edit" ? 2 : 1,
      //   user_active: status ? "1" : "0",
      //   source: "web",
      //   operation: type === "edit" ? "update" : "create",
      //   jdate: joiningDate ? getUnixTime(joiningDate) : "",
      //   document_object: documents?.length > 0 ? JSON.stringify(docs) : "",
      //   working_days: schedule?.length > 0 ? schedule.join(":") : "",
      //   hourly_rate: rate ? rate : "",
      //   starting_time: startingTime,
      //   ending_time: endingTime,
      // };

      // let secondaryStatus = outOfService ? 1 : 0;
      // if (type === "edit" && rowData.secondary_status == 2) {
      //   secondaryStatus = 2;
      // }

      // if (status) {
      //   secondaryStatus = outOfService ? 1 : 0;
      // }

      //let vehActive = status ? 1 : 0;
      // if (status && outOfService) {
      //   vehActive = 0;
      // }

      const distanceFactor = getDistanceFactor(primaryMeter?.value, distance);
      let startingodoConverted = startingOdo ? startingOdo * distanceFactor : 0;

      const apiData = {
        vehicle_name: vehicleName,
        make: make ? make : "",
        model: model ? model : "",
        veh_type: vehicleType ? vehicleType?.value : "",
        veh_active: status ? 1 : 0,
        secondary_status: outOfService ? 1 : 0,
        year: year ? year : 0,
        license_no: licenseNo ? licenseNo : "",
        vin_no: vin ? vin : "",
        veh_img: vehiclePhoto ? vehiclePhoto.imgName : "",
        starting_odo: startingodoConverted,
        primary_meter: primaryMeter ? primaryMeter?.value : "",
        custom_specs: vehicleSpecsFieldsString,
        width: width ? width : "",
        length: length ? length : "",
        height: height ? height : "",
        weight: weight ? weight : "",
        cargo_value: cargoVol ? cargoVol : "",
        max_payload: maxPayload ? maxPayload : "",
        front_tyre_info: frontTireInfo ? frontTireInfo : "",
        front_tyre_psi: frontTirePSI ? frontTirePSI : "",
        rear_tyre_info: rearTireInfo ? rearTireInfo : "",
        rear_tyre_psi: rearTirePSI ? rearTirePSI : "",
        fuel_type: fuelType ? fuelType?.value : "",
        additional_documents: documents?.length > 0 ? JSON.stringify(docs) : "",
        fuel_quality: fuelQuality ? fuelQuality : "",
        oil: oil ? oil : "",
        oil_capacity: oilCapacity ? oilCapacity : "",
        fuel_tank_capacity: fuelTankCapacity ? fuelTankCapacity : "",
        action: type === "edit" ? 2 : 1,
        operation: type === "edit" ? "update" : "create",
        sync_version: "v2",
        veh_id: vehicleId,
        group_id: group ? group?.group_id : "",
        purchase_price: purchasePrice ? purchasePrice : 0,
        provider_type_1: telematicsDataSync ? 1 : 0,
      };

      //console.log(apiData);
      //return;

      const userssAssignedIds = usersAssigned.map((u) => u.user_id);
      const userApiData = {
        querystring: "vehicle_operator_update",
        operator_id: userssAssignedIds,
        veh_id: vehicleId,
        vehicle_operation: "update",
      };

      const response = await post("vehicle", apiData);
      if (userAssignedUpdated) {
        const vehResponse = await postApi(userApiData, "commonNew");
        //console.log("vehResponse", vehResponse);
      }

      if (!response?.success) {
        const { code } = response;
        if (code == 1) {
          setAddVehicleFailedData((prev) => ({
            ...prev,
            message: t("vehicle_limit_reached_err"),
          }));
        } else if (code == 2) {
          setAddVehicleFailedData((prev) => ({
            ...prev,
            message: t("duplicate_entry_veh_name_err"),
          }));
        } else if (code == 3) {
          setAddVehicleFailedData((prev) => ({
            ...prev,
            message: t("duplicate_entry_veh_vin_err"),
          }));
        } else if (code == 4) {
          setAddVehicleFailedData((prev) => ({
            ...prev,
            message: t("duplicate_entry_veh_license_err"),
          }));
        } else if (code == 5) {
          setAddVehicleFailedData((prev) => ({
            ...prev,
            message: t("duplicate_entry_veh_err"),
          }));
        } else {
          setAddVehicleFailedData((prev) => ({
            ...prev,
            message: t("form_err_msg"),
          }));
        }
        setSaveBtnLoading(false);
        setAddVehicleFailed(true);
        return;
      }
      if (response?.success) {
        //log api error if recommend maint schedule is checked
        if (recommendMaintSchedule) {
          const errLogData = {
            url: "AI schedule requested",
            method_type: vehicleId,
            error: "",
            request: "",
            placeholder: "",
          };
          await logErrors(errLogData);
        }
        const snackbarMsg =
          type === "edit"
            ? t("record_edited_msg", { recordName: t("vehicle") })
            : t("record_added_msg", { recordName: t("vehicle") });
        // let imagesToS3 = [];
        if (vehiclePhoto && vehiclePhoto.file) {
          imagesToS3.push({
            file: vehiclePhoto.file,
            key: `image_data/org_${orgId}/vehicle_${vehicleId}/${vehiclePhoto.imgName}`,
          });
        }

        // if (licenseImg && licenseImg.file) {
        //   imagesToS3.push({
        //     file: licenseImg.file,
        //     key: `image_data/org_${orgId}/user_${vehicleId}/license/${licenseImg.imgName}`,
        //   });
        // }

        // documents?.forEach((doc) => {
        //   if (doc.image.file) {
        //     imagesToS3.push({
        //       file: doc.image.file,
        //       key: `image_data/org_${orgId}/vehicle_${vehicleId}/Documents/${doc.docName}.${doc.image.extension}`,
        //     });
        //   }
        // });

        if (imagesToS3?.length > 0) {
          const docsUploaded = await uploadDocsToS3(imagesToS3);
          if (docsUploaded) {
            if (!recommendMaintSchedule) {
              dispatch(addMsg(snackbarMsg));
            }
            setSaveBtnLoading(false);
            history.push("/vehicles", {
              fromCancel: false,
              recommendMaintSchedule: type !== "edit" && recommendMaintSchedule,
              newVehicleId:
                type !== "edit" && recommendMaintSchedule ? vehicleId : "",
            });
          }
        } else {
          if (!recommendMaintSchedule) {
            dispatch(addMsg(snackbarMsg));
          }
          setSaveBtnLoading(false);
          history.push("/vehicles", {
            fromCancel: false,
            recommendMaintSchedule: type !== "edit" && recommendMaintSchedule,
            newVehicleId:
              type !== "edit" && recommendMaintSchedule ? vehicleId : "",
          });
        }
      }
    } catch (e) {
      setAddVehicleFailedData((prev) => ({
        ...prev,
        message: t("form_err_msg"),
      }));
      setSaveBtnLoading(false);
      setAddVehicleFailed(true);
      return;
    }
  };

  return (
    <FormProvider {...methods}>
      {/* {showUserAddedPopup && (
        <UserAddedPopup
          showPopup={showUserAddedPopup}
          setShowPopup={setShowUserAddedPopup}
          userAdded={userAdded}
          setUserAdded={setUserAdded}
          userData={userData}
        />
      )} */}
      {docsIncomplete && (
        <InfoPopup
          message={t("doc_tab_incomplete_err_msg")}
          icon={{ className: "fi fi-rr-triangle-warning", color: "red" }}
          showPopup={docsIncomplete}
          setShowPopup={setDocsIncomplete}
        />
      )}
      {addVehicleFailed && (
        <InfoPopup
          message={addVehicleFailedData.message}
          icon={addVehicleFailedData.icon}
          showPopup={addVehicleFailed}
          setShowPopup={setAddVehicleFailed}
        />
      )}
      <FormContainer onSubmit={methods.handleSubmit(onSubmit)}>
        <FormHeader
          handleCancel={() =>
            history.push("/vehicles", {
              fromCancel: true,
              recommendMaintSchedule: false,
              newVehicleId: "",
            })
          }
          //disableSave={!vehicle}
          isSaveLoading={saveBtnLoading}
          title={type === "edit" ? t("edit_vehicle") : t("add_vehicle")}
          //handleCancel={handleCancel}
        />
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12} xl={12}>
              <Card>
                <CardBody
                  style={{
                    padding: 0,
                    //height: `${window.innerHeight - 150}px`,
                    //overflow: "auto",
                  }}
                >
                  <TabsWrap>
                    <Tabs
                      rowData={rowData}
                      type={type}
                      //vehicleInfoRef={vehicleInfoRef}
                      licenseInfo={licenseInfo}
                      documents={documents}
                      setDocuments={setDocuments}
                      docUpdated={docUpdated}
                      setDocUpdated={setDocUpdated}
                      errors={errors}
                      usersAssigned={usersAssigned}
                      setUsersAssigned={setUsersAssigned}
                      userAssignedUpdated={userAssignedUpdated}
                      setUserAssignedUpdated={setUserAssignedUpdated}
                      vehicleSpecsInfoRef={vehicleSpecsInfoRef}
                      activeKey={activeKey}
                      setActiveKey={setActiveKey}
                      vehiclePhoto={vehiclePhoto}
                      setVehiclePhoto={setVehiclePhoto}
                    />
                  </TabsWrap>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </FormContainer>
    </FormProvider>
  );
};

export default VehiclesForm;

const Tabs = ({
  rowData,
  type,
  //vehicleInfoRef,
  documents,
  setDocuments,
  licenseInfo,
  docUpdated,
  setDocUpdated,
  errors,
  usersAssigned,
  setUsersAssigned,
  userAssignedUpdated,
  setUserAssignedUpdated,
  vehicleSpecsInfoRef,
  activeKey,
  setActiveKey,
  vehiclePhoto,
  setVehiclePhoto,
}) => {
  const { t } = useTranslation("common");

  const { fetchTelematicsOdo, integrationsData } = useAuth();

  const showIntegrationsTab = integrationsData.find(
    (data) =>
      data.provider_type === 1 &&
      ((data.service_type === 1 && data.service_enabled === 1) ||
        (data.service_type === 2 && data.service_enabled === 1) ||
        (data.service_type === 4 && data.service_enabled === 1))
  )
    ? true
    : false;

  return (
    <Tab.Container activeKey={activeKey} onSelect={(e) => setActiveKey(e)}>
      <TabsWrap>
        <Nav className="nav-tabs">
          <NavItem
            id="vehicle-details-tab"
            $active={activeKey == "1"}
            // style={{
            //   background: activeKey !== "1" ? "aliceblue" : "none",
            // }}
          >
            <NavLink eventKey="1" name="vehicle-details-link">
              {t("vehicle_details")}{" "}
              {!isObjEmpty(errors) && (
                <i
                  className="fi fi-rr-triangle-warning"
                  style={{
                    color: "red",
                    position: "relative",
                    top: "2px",
                  }}
                ></i>
              )}
            </NavLink>
          </NavItem>
          <NavItem
            id="vehicle-specs-tab"
            $active={activeKey == "2"}
            // style={{
            //   background: activeKey !== "2" ? "aliceblue" : "none",
            // }}
          >
            <NavLink eventKey="2" name="vehicle-specs-link">
              {t("vehicle_specifications")}
            </NavLink>
          </NavItem>
          <NavItem
            id="documents-tab"
            $active={activeKey == "3"}
            // style={{
            //   background: activeKey !== "3" ? "aliceblue" : "none",
            // }}
          >
            <NavLink eventKey="3" name="documents-link">
              {documents.length > 0
                ? t("count_documents_added", {
                    count: documents?.length,
                  })
                : t("documents")}
            </NavLink>
          </NavItem>
          <NavItem
            id="users-assigned-tab"
            $active={activeKey == "4"}
            // style={{
            //   background: activeKey !== "4" ? "aliceblue" : "none",
            // }}
          >
            <NavLink eventKey="4" id={"users-assigned-link"}>
              {usersAssigned?.length > 0
                ? t("count_users_assigned", {
                    count: usersAssigned?.length,
                  })
                : t("user_assignment")}
            </NavLink>
          </NavItem>

          {showIntegrationsTab && (
            <NavItem
              id="integrations-tab"
              $active={activeKey == "5"}
              // style={{
              //   background: activeKey !== "5" ? "aliceblue" : "none",
              // }}
            >
              <NavLink eventKey="5" id={"users-assigned-link"}>
                {t("integrations")}
              </NavLink>
            </NavItem>
          )}
        </Nav>

        <Tab.Content>
          <TabPane
            eventKey="1"
            // style={{
            //   height: `${window.innerHeight - 200}px`,
            //   overflow: "auto",
            // }}
          >
            <VehicleDetailsForm
              rowData={rowData}
              type={type}
              //vehicleInfoRef={vehicleInfoRef}
              setUsersAssigned={setUsersAssigned}
              userAssignedUpdated={userAssignedUpdated}
              setUserAssignedUpdated={setUserAssignedUpdated}
              vehiclePhoto={vehiclePhoto}
              setVehiclePhoto={setVehiclePhoto}
            />
          </TabPane>
          <TabPane eventKey="2">
            <VehicleSpecsForm
              rowData={rowData}
              type={type}
              //vehicleInfoRef={vehicleInfoRef}
              vehicleSpecsInfoRef={vehicleSpecsInfoRef}
              //setVehicleSpecsInfo={setVehicleSpecsInfo}
              //vehicleSpecsFields={vehicleSpecsFields}
              //setVehicleSpecsFields={setVehicleSpecsFields}
            />
          </TabPane>
          <TabPane
            eventKey="3"
            // style={{
            //   height: `${window.innerHeight - 198}px`,
            //   overflow: "auto",
            // }}
          >
            <DocumentsForm
              rowData={rowData}
              type={type}
              documents={documents}
              setDocuments={setDocuments}
              licenseInfo={licenseInfo}
              docUpdated={docUpdated}
              setDocUpdated={setDocUpdated}
            />
          </TabPane>
          <TabPane eventKey="4">
            <UsersAssignedForm
              usersAssigned={usersAssigned}
              setUsersAssigned={setUsersAssigned}
              userAssignedUpdated={userAssignedUpdated}
              setUserAssignedUpdated={setUserAssignedUpdated}
              type={type}
              rowData={rowData}
            />
          </TabPane>

          <TabPane eventKey="5">
            <IntegrationsForm type={type} rowData={rowData} />
          </TabPane>
        </Tab.Content>
      </TabsWrap>
    </Tab.Container>
  );
};

export const TabPane = styled(Tab.Pane)`
  ${scrollbarStyles};
  height: ${window.innerHeight - 200}px;
  overflow: auto;
  //padding: 1rem;
`;
