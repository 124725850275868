import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import {
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
  FilterButton,
  Icon,
  RedButton,
} from "../../../../shared/components/TableElements";
import {
  CheckBoxInput,
  FormGroupLabel,
  FormItem,
} from "../../../../shared/components/form/FormElements";
import { Trans, useTranslation } from "react-i18next";
import { formInputStyles } from "../../../../shared/constants/styles";
import CheckboxInputComponent from "../../../../shared/components/form/CheckboxInputComponent";
import { Desc } from "../../../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import streamlineIcon from "@/shared/img/streamline-logo.png";
import simplyFleetIcon from "@/shared/img/simplyFleetLogo.png";
import { useAuth } from "../../../../contexts/AuthContext";
import { useSelector } from "react-redux";
import { post } from "../../../../api/getUser";
import { useDispatch } from "react-redux";
import {
  addMsg,
  addSnackbarData,
} from "../../../../features/snackbar/snackbarSlice";
import ActionPopup from "../../../../shared/components/ActionPopup";
import { ImageIcon } from "../../../../shared/components/table/components/RightPanel/InspectionSubmissionsRightPanel";
import {
  colorLightGray,
  scrollbarStyles,
  taskBgColor,
} from "../../../../utils/palette";
import streamlineScreenshot1 from "@/shared/img/integrations/streamline/streamline-instructions-1.png";
import streamlineScreenshot2 from "@/shared/img/integrations/streamline/streamline-instructions-2.png";
import streamlineScreenshot3 from "@/shared/img/integrations/streamline/streamline-instructions-3.png";
import ZoomModal from "../../../../shared/components/ZoomModal";
import { IntegrationsImg } from "..";
import Tooltip from "../../../../shared/components/Tooltip";
import InfoPopup from "../../../../shared/components/InfoPopup";
import ImportVehiclesPopup from "./ImportVehiclesPopup";

const Streamline = ({
  step,
  setStep,
  setShowSnackbar,
  fetchIntegrationsData,
  handleImportAssets,
}) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const {
    integrationsData,
    setIntegrationsData,
    setFetchTelematicsOdo,
    setShowFaultCodes,
  } = useAuth();
  const { distance } = useSelector((state) => state.units);
  const { roleId } = useSelector((state) => state.user);

  const [apiToken, setApiToken] = useState("");
  const [fetchAllEntities, setFetchAllEntities] = useState({
    allVehicles: true,
    //allUsers: true,
  });
  const [configData, setConfigData] = useState({
    odometer: true,
    dailyMileage: true,
    //faultCodes: true,
    //newUsers: true,
    //newVehicles: true,
  });

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
  const [showDeleteConnectionPopup, setShowDeleteConnectionPopup] =
    useState(false);

  //State to store url of steps/instruction images
  const [imageUrl, setImageUrl] = useState("");

  const [showInsctructionsImg, setShowInstructionsImg] = useState(false);

  const [showInstructions, setShowInstructions] = useState(false);

  const [showErrPopup, setShowErrPopup] = useState(false);

  const [vehicles, setVehicles] = useState([]);

  const [importBtnLoading, setImportBtnLoading] = useState(false);

  //Update config data
  useEffect(() => {
    if (integrationsData?.length > 0) {
      let configObj = {
        odometer: false,
        dailyMileage: false,
        //faultCodes: false,
        //newUsers: false,
        //newVehicles: false,
      };
      for (let intObj of integrationsData) {
        const { provider, provider_type, service_enabled, service_type } =
          intObj;
        if (provider_type === 1 && provider === 3 && service_enabled === 1) {
          if (service_type === 1) {
            configObj.odometer = true;
          }
          if (service_type === 4) {
            configObj.dailyMileage = true;
          }
          // if (service_type === 2) {
          //   configObj.faultCodes = true;
          // }
          // if (service_type === 3) {
          //   configObj.newVehicles = true;
          // }
        }
      }
      setConfigData(configObj);
    }
  }, [integrationsData]);

  const handleFetchAllEntitiesChange = (e) => {
    const { name, checked } = e.target;
    setFetchAllEntities((prev) => ({ ...prev, [name]: checked }));
  };

  const handleConfigChange = (e) => {
    const { name, checked } = e.target;
    setConfigData((prev) => ({ ...prev, [name]: checked }));
  };

  // const fetchIntegrationsData = async () => {
  //   setShowFaultCodes(false);
  //   setFetchTelematicsOdo(false);
  //   //Fetch integrations data
  //   const apiData = {
  //     querystring: "get_integrations_data",
  //   };
  //   const res = await post("integrations", apiData);
  //   if (res.success) {
  //     const { data } = res;
  //     if (data.length > 0) {
  //       for (let intObj of data) {
  //         const { provider, provider_type, service_enabled, service_type } =
  //           intObj;

  //         //Commented out since no fault codes in Streamline
  //         // if (
  //         //   provider_type === 1
  //         //   //&&
  //         //   //service_enabled === 1 &&
  //         //   //service_type === 2
  //         // ) {
  //         //   setShowFaultCodes(true);
  //         // }

  //         if (
  //           provider_type === 1 &&
  //           service_enabled === 1 &&
  //           service_type === 1
  //         ) {
  //           setFetchTelematicsOdo(true);
  //         }

  //         setIntegrationsData(data);
  //       }
  //     }
  //   } else {
  //     setIntegrationsData([]);
  //     //setShowFaultCodes(false);
  //     setFetchTelematicsOdo(false);
  //   }
  // };

  const handleIntegration = async () => {
    setStep(3); //Loading step
    const apiData = {
      api_token: apiToken,
      fetch_all_vehicles: fetchAllEntities.allVehicles ? 1 : 0,
      //fetch_all_users: fetchAllEntities.allUsers ? 1 : 0,
      querystring: "setup_integration",
      provider: 3,
      provider_type: 1,
      primary_meter: distance,
    };
    const res = await post("integrations", apiData);

    if (res.success) {
      await fetchIntegrationsData();

      if (fetchAllEntities.allVehicles) {
        const vehRes = await post("integrations", {
          querystring: "fetch_new_vehicles",
          primary_meter: distance,
        });
        console.log("vehRes", vehRes);

        if (vehRes?.success) {
          const { data } = vehRes;
          if (data?.length > 0) {
            setVehicles(data);
            setStep(5);
          }
        }

        if (!vehRes?.success) {
          if (vehRes.message === "no_new_vehicles") {
            setStep(6);
            dispatch(
              addSnackbarData({
                msg: t("no_new_vehicles_found_msg", {
                  providerName: "Streamline",
                }),
                type: 2,
              })
            );
            setShowSnackbar(true);
            setTimeout(() => {
              setShowSnackbar(false);
              dispatch(
                addSnackbarData({
                  msg: "",
                  type: 1,
                })
              );
            }, 3000);
          }
        }
      }

      if (!fetchAllEntities.allVehicles) {
        setStep(6);
      }
    }

    if (!res.success) {
      setStep(4);
    }
  };

  const handleProceedToConfig = () => {
    setConfigData({
      odometer: true,
      dailyMileage: true,
      //faultCodes: true,
      //newUsers: true,
      //newVehicles: true,
    });
    setStep(7);
  };

  const handleSaveConfig = async () => {
    if (roleId !== 1) {
      setShowErrPopup(true);
      return;
    }
    setSaveBtnLoading(true);
    const apiData = {
      fetch_odometer: configData.odometer ? 1 : 0,
      insert_daily_mileage: configData.dailyMileage ? 1 : 0,
      //fetch_fault_codes: configData.faultCodes ? 1 : 0,
      //fetch_new_vehicles: configData.newVehicles ? 1 : 0,
      querystring: "configure_integration_setup",
      provider: 3,
      provider_type: 1,
      utc_offset: new Date().getTimezoneOffset(),
      localTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    const res = await post("integrations", apiData);
    if (res.success) {
      await fetchIntegrationsData();
      setSaveBtnLoading(false);
      dispatch(addMsg(t("config_updated")));
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
        dispatch(addMsg(""));
      }, 3000);
      setStep(0);
    }
  };

  const handleDeleteConnection = async () => {
    if (roleId !== 1) {
      setShowErrPopup(true);
      return;
    }
    setDeleteBtnLoading(true);
    const apiData = {
      querystring: "delete_integration",
      provider: 3,
      provider_type: 1,
    };

    const res = await post("integrations", apiData);
    if (res.success) {
      await fetchIntegrationsData();
      setDeleteBtnLoading(false);
      dispatch(addMsg(t("connection_deleted_success_msg")));
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
        dispatch(addMsg(""));
      }, 3000);
      setStep(0);
    }
  };

  return (
    <>
      <Modal show={step !== 0} size="lg">
        {showErrPopup && (
          <InfoPopup
            showPopup={showErrPopup}
            setShowPopup={setShowErrPopup}
            message={t("integ_modify_access_restricted_msg")}
            icon={{ className: "fi fi-rr-cross-circle", color: "red" }}
          />
        )}
        {showDeleteConnectionPopup && (
          <ActionPopup
            showPopup={showDeleteConnectionPopup}
            setShowPopup={setShowDeleteConnectionPopup}
            handleYes={handleDeleteConnection}
            handleNo={() => setShowDeleteConnectionPopup(false)}
            message={t("delete_connection_confirm_msg")}
            yesBtnLoading={deleteBtnLoading}
          />
        )}

        {showInsctructionsImg && (
          <ZoomModal
            showModal={showInsctructionsImg}
            setShowModal={setShowInstructionsImg}
            doc={{ url: imageUrl }}
          />
        )}

        {/* {imageUrl && <ImageModal imageUrl={imageUrl} setImageUrl={setImageUrl} />} */}

        <Modal.Header>
          <h5>
            {step === 6
              ? t("configure_provider", { providerName: "Streamline" })
              : t("integrate_with_provider", { providerName: "Streamline" })}
          </h5>
          {step !== 3 && (
            <div className="d-flex justify-content-end">
              <ActionIconContainer onClick={() => setStep(0)}>
                <i className="fi fi-rr-cross-small" />
              </ActionIconContainer>
            </div>
          )}
        </Modal.Header>
        <Modal.Body
        //className="m-0 p-0"
        //style={{ height: "300px" }}
        >
          <div
            className="d-flex justify-content-center"
            style={{ height: window.innerHeight - 220 }}
          >
            {/* step 1 */}
            {step === 1 && (
              <div
                className="d-flex flex-column align-items-center gap-2"
                style={{ width: 500 }}
              >
                <div className="d-flex justify-content-center">
                  <IntegrationsImg
                    src={streamlineIcon}
                    alt="streamline-logo"
                    width={120}
                    height={120}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <CollapseContainer
                    className="d-flex gap-2 justify-content-center align-items-center mb-1"
                    onClick={() => setShowInstructions((prev) => !prev)}
                  >
                    <p style={{ fontWeight: 500 }}>
                      {t("how_to_create_api_token_in_provider", {
                        providerName: "Streamline",
                      })}
                    </p>
                    <CollapseIcon
                      className={`fi fi-rr-angle-${
                        showInstructions ? "up" : "down"
                      }`}
                      //onClick={() => setShowInstructions((prev) => !prev)}
                    />
                  </CollapseContainer>
                  <BodyContainer $showInstructions={showInstructions}>
                    {showInstructions && (
                      <ul>
                        <LI>
                          <Trans
                            i18nKey="streamline_instr_step_1"
                            t={t}
                            components={{
                              anchorlink: (
                                <a href="http://www.mynavistream.com"></a>
                              ),
                            }}
                          />
                        </LI>
                        <LI>
                          <Trans i18nKey="streamline_instr_step_2" t={t} />
                          <ImageIcon
                            className="fi fi-rr-picture"
                            onClick={() => {
                              setShowInstructionsImg(true);
                              setImageUrl(streamlineScreenshot1);
                            }}
                            style={{ marginLeft: "8px" }}
                          ></ImageIcon>
                        </LI>
                        <LI>
                          {/* {t("click_on")}{" "}
                          <strong>{t("add_api_token_btn")}</strong> */}
                          <Trans i18nKey="streamline_instr_step_3" t={t} />
                          <ImageIcon
                            className="fi fi-rr-picture"
                            onClick={() => {
                              setShowInstructionsImg(true);
                              setImageUrl(streamlineScreenshot2);
                            }}
                            style={{ marginLeft: "8px" }}
                          ></ImageIcon>
                        </LI>
                        <LI>
                          <Trans i18nKey="streamline_instr_step_4" t={t} />
                          <ImageIcon
                            className="fi fi-rr-picture"
                            onClick={() => {
                              setShowInstructionsImg(true);
                              setImageUrl(streamlineScreenshot3);
                            }}
                            style={{ marginLeft: "8px" }}
                          ></ImageIcon>
                        </LI>
                        <ul>
                          <LI>{t("streamline_instr_step_4_1")}</LI>
                          <LI>{t("streamline_instr_step_4_2")}</LI>
                          <LI>
                            <Trans i18nKey="streamline_instr_step_4_3" t={t} />
                          </LI>
                          <LI>{t("streamline_instr_step_4_4")}</LI>
                        </ul>
                      </ul>
                    )}
                  </BodyContainer>
                </div>
                <div
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{ width: "-webkit-fill-available" }}
                >
                  <FormGroupLabel id="api-token-label">
                    {t("api_token")}
                  </FormGroupLabel>
                  <FormItem style={{ width: "-webkit-fill-available" }}>
                    <input
                      type="text"
                      value={apiToken}
                      style={formInputStyles}
                      onChange={(e) => setApiToken(e.target.value)}
                    />
                  </FormItem>
                </div>
              </div>
            )}

            {/* step 2 */}
            {step === 2 && (
              <div className="d-flex flex-column gap-5 align-items-center">
                <div className="d-flex justify-content-center">
                  <IntegrationsImg
                    src={streamlineIcon}
                    alt="streamline-logo"
                    width={120}
                    height={120}
                  />
                </div>
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex align-items-center justify-content-center">
                    <CheckboxInputComponent
                      label={t("fetch_all_vehicles_from_provider", {
                        providerName: "Streamline",
                      })}
                      name={"allVehicles"}
                      checked={fetchAllEntities.allVehicles}
                      onChange={handleFetchAllEntitiesChange}
                    />
                  </div>
                  {/* <CheckboxInputComponent
                label={"Fetch all my users from Samsara"}
                name={"allUsers"}
                checked={fetchAllEntities.allUsers}
                onChange={handleFetchAllEntitiesChange}
              /> */}

                  <Desc>{t("samsara_step_2_note")}</Desc>
                </div>
              </div>
            )}

            {/* step 3 */}
            {step === 3 && (
              <div>
                <div className="d-flex justify-content-center align-items-center gap-4">
                  <IntegrationsImg
                    src={streamlineIcon}
                    alt="streamline-logo"
                    width={120}
                    height={120}
                  />
                  <i
                    className="fi fi-br-arrow-right"
                    style={{ fontSize: 24, marginRight: "0.5rem" }}
                  ></i>
                  <IntegrationsImg
                    src={simplyFleetIcon}
                    alt="simply-fleet-logo"
                    width={120}
                    height={120}
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center mt-5">
                  <p>{t("integration_in_prog")}</p>
                </div>
              </div>
            )}

            {/* step 4 */}
            {step === 4 && (
              <div>
                <div className="d-flex justify-content-center align-items-center gap-4">
                  <IntegrationsImg
                    src={streamlineIcon}
                    alt="streamline-logo"
                    width={120}
                    height={120}
                  />
                  <i
                    className="fi fi-rr-cross-circle"
                    style={{
                      fontSize: 28,
                      color: "red",
                      marginRight: "0.5rem",
                    }}
                  ></i>
                  <IntegrationsImg
                    src={simplyFleetIcon}
                    alt="simply-fleet-logo"
                    width={120}
                    height={120}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                  <p
                    style={{ fontWeight: 500, fontSize: 16 }}
                    className="d-flex flex-column align-items-center"
                  >
                    {t("integration_failed")}
                  </p>
                  <br />
                  <p>{t("samsara_correct_api_key_warn_msg")}</p>
                </div>
              </div>
            )}

            {/* step 5 */}
            {step === 5 && (
              <ImportVehiclesPopup
                provider={3}
                vehicles={vehicles}
                setVehicles={setVehicles}
              />
            )}

            {/* step 6 */}
            {step === 6 && (
              <div className="d-flex flex-column gap-5">
                <div className="d-flex justify-content-center align-items-center gap-4">
                  <IntegrationsImg
                    src={streamlineIcon}
                    alt="streamline-logo"
                    width={120}
                    height={120}
                  />
                  <i
                    className="fi fi-rs-check-circle"
                    style={{
                      fontSize: 28,
                      color: "green",
                      marginRight: "0.5rem",
                    }}
                  ></i>
                  <IntegrationsImg
                    src={simplyFleetIcon}
                    alt="simply-fleet-logo"
                    width={120}
                    height={120}
                  />
                </div>
                <div>
                  <p
                    style={{ fontWeight: 500, fontSize: 16 }}
                    className="d-flex flex-column align-items-center"
                  >
                    {t("integration_complete")}
                  </p>
                  <br />
                  <p className="d-flex flex-column align-items-center">
                    {t("provider_setup_success_msg", {
                      providerName: "Streamline",
                    })}
                  </p>
                  <br />
                  <p className="d-flex flex-column align-items-center">
                    {t("provider_config_msg", { providerName: "Streamline" })}
                  </p>
                </div>
              </div>
            )}

            {/* step 7 */}
            {step === 7 && (
              <div className="d-flex flex-column align-items-center gap-5">
                <div className="d-flex justify-content-center align-items-center gap-4">
                  <IntegrationsImg
                    src={streamlineIcon}
                    alt="streamline-logo"
                    width={120}
                    height={120}
                  />
                  <i
                    className="fi fi-rs-check-circle"
                    style={{
                      fontSize: 28,
                      color: "green",
                      marginRight: "0.5rem",
                    }}
                  ></i>
                  <IntegrationsImg
                    src={simplyFleetIcon}
                    alt="simply-fleet-logo"
                    width={120}
                    height={120}
                  />
                </div>
                <div className="d-flex flex-column gap-4">
                  <CheckboxInputComponent
                    label={t("fetch_odo_val_from_provider", {
                      providerName: "Streamline",
                    })}
                    name={"odometer"}
                    checked={configData.odometer}
                    onChange={handleConfigChange}
                  />
                  <div className="d-flex gap-2 align-items-center">
                    <CheckboxInputComponent
                      label={t("auto_populate_mileage", {
                        providerName: "Streamline",
                      })}
                      name={"dailyMileage"}
                      checked={configData.dailyMileage}
                      onChange={handleConfigChange}
                    />
                    <Tooltip
                      text={t("auto_populate_mileage_tooltip", {
                        providerName: "Streamline",
                      })}
                    >
                      <Icon className="fi fi-rr-info"></Icon>
                    </Tooltip>
                  </div>
                  {/* <CheckboxInputComponent
                    label={t("fetch_fault_codes_from_samsara")}
                    name={"faultCodes"}
                    checked={configData.faultCodes}
                    onChange={handleConfigChange}
                  />
                  <CheckboxInputComponent
                    label={t("fetch_new_vehicles_from_samsara")}
                    name={"newVehicles"}
                    checked={configData.newVehicles}
                    onChange={handleConfigChange}
                  /> */}
                  {/* <CheckboxInputComponent
                label={"Fetch users whenever added in Samsara"}
                name={"newUsers"}
                checked={configData.newUsers}
                onChange={handleConfigChange}
              /> */}
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* step 1 */}
          {step === 1 && (
            <div
              className="d-flex justify-content-between"
              style={{ width: "-webkit-fill-available" }}
            >
              <FilterButton onClick={() => setStep(0)}>
                {t("cancel")}
              </FilterButton>

              <AddFormButton
                id="next-2-button"
                type="button"
                onClick={() => setStep(2)}
                disabled={!apiToken}
              >
                {/* {saveBtnLoading ? <CustomLoader /> : t("save")} */}
                {t("next")}
              </AddFormButton>
            </div>
          )}

          {/* step 2 */}
          {step === 2 && (
            <div
              className="d-flex justify-content-between"
              style={{ width: "-webkit-fill-available" }}
            >
              <FilterButton onClick={() => setStep(1)}>
                {t("back")}
              </FilterButton>

              <AddFormButton
                id="next-3-button"
                type="button"
                onClick={handleIntegration}
              >
                {/* {saveBtnLoading ? <CustomLoader /> : t("save")} */}
                {t("next")}
              </AddFormButton>
            </div>
          )}

          {/* step 3 */}
          {step === 3 && <div style={{ height: "32px" }}></div>}

          {/* step 4 */}
          {step === 4 && (
            <div
              className="d-flex justify-content-start"
              style={{ width: "-webkit-fill-available" }}
            >
              <FilterButton
                id="back-button"
                type="button"
                onClick={() => setStep(1)}
              >
                {/* {saveBtnLoading ? <CustomLoader /> : t("save")} */}
                {t("go_back_changed")}
              </FilterButton>
            </div>
          )}

          {/* step 5 */}
          {step === 5 && (
            <div
              className="d-flex justify-content-end"
              style={{ width: "-webkit-fill-available" }}
            >
              <AddFormButton
                id="import-assets-button"
                type="button"
                onClick={() =>
                  handleImportAssets(3, vehicles, setImportBtnLoading)
                }
              >
                {/* {saveBtnLoading ? <CustomLoader /> : t("save")} */}
                {importBtnLoading ? <CustomLoader /> : t("import_assets")}
              </AddFormButton>
            </div>
          )}

          {/* step 6 */}
          {step === 6 && (
            <div
              className="d-flex justify-content-end"
              style={{ width: "-webkit-fill-available" }}
            >
              <AddFormButton
                id="proceed-to-config-button"
                type="button"
                onClick={handleProceedToConfig}
              >
                {/* {saveBtnLoading ? <CustomLoader /> : t("save")} */}
                {t("proceed_to_config")}
              </AddFormButton>
            </div>
          )}

          {/* step 7 */}
          {step === 7 && (
            <div
              className="d-flex justify-content-between"
              style={{ width: "-webkit-fill-available" }}
            >
              <RedButton
                type="button"
                onClick={() => setShowDeleteConnectionPopup(true)}
                //disabled={deleteBtnLoading}
              >
                {t("delete_connection")}
              </RedButton>

              <AddFormButton
                id="save-button"
                type="button"
                onClick={handleSaveConfig}
                disabled={saveBtnLoading}
              >
                {saveBtnLoading ? <CustomLoader /> : t("save")}
              </AddFormButton>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Streamline;

//styles
const LI = styled.li`
  font-weight: 400;
  strong {
    font-weight: 500;
  }
`;

const BodyContainer = styled.div`
  height: ${window.innerHeight - 470}px;
  overflow: auto;
  padding: 0.5rem;
  width: 500px;
  background: ${(p) => (p.$showInstructions ? taskBgColor : "")};
  ${scrollbarStyles};
`;

const CollapseIcon = styled.i`
  position: relative;
  top: 3px;
  // cursor: pointer;
  // &:hover {
  //   color: ${colorLightGray};
  // }
`;

const CollapseContainer = styled.div`
  cursor: pointer;
  padding: 0.2rem;
  border-radius: 5px;
  &:hover {
    background: ${colorLightGray};
  }
`;
