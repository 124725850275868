import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  AutoResizeTextArea,
  FormGroupLabel,
  FormItem,
  FormValueContainer,
  HintText,
  UnitSpan,
} from "../../../shared/components/form/FormElements";
import { Controller, useFormContext } from "react-hook-form";
import Error from "@/shared/components/form/Error";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import DatePicker from "react-datepicker";
import getUnixTime from "date-fns/getUnixTime";
import FormField from "../../../shared/components/form/FormField";
import { formInputStyles, formStyles } from "../../../shared/constants/styles";
import { CustomInput } from "../../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import {
  getDateFnsLocale,
  getDistanceFactor,
  getDistanceUnit,
  handleNegative,
  handleNumberInputOnWheelPreventChange,
  handleVehicleOnChange,
} from "../../../shared/helpers";
import FileUploader from "../../../shared/components/form/FileUploader";
import ImageWithZoom from "../../../shared/components/form/ImageWithZoom";
import { colorBlue, colorGray } from "../../../utils/palette";
import { FileUploaderContainer } from "../../Issues/IssuesForm";
import { dhm } from "../../WorkOrders";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import useFetchOdoHint from "../../../shared/custom-hooks/useFetchOdoHint";
import Tooltip from "../../../shared/components/Tooltip";
import { Icon } from "../../../shared/components/TableElements";
import { useLocation } from "react-router";
import InfoPopup from "../../../shared/components/InfoPopup";
import startOfDay from "date-fns/startOfDay";
import { postApi } from "../../../api/getUser";
import FileUploaderNew from "../../../shared/components/form/FileUploaderNew";
import SubscribeTooltipIcon from "../../../shared/components/SubscribeTooltipIcon";
import { useAuth } from "../../../contexts/AuthContext";
import useAutosizeTextArea from "../../../shared/custom-hooks/useAutosizeTextArea";

const TripDetailsForm = ({
  tripDocs,
  setTripDocs,
  rowData,
  firstTimeEdit,
  userOptions,
  setUserOptions,
}) => {
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation("common");
  const location = useLocation();
  const vehiclesList = useSelector((state) => state.vehicles.list);
  const { userId } = useSelector((state) => state.user);
  const { tag } = useAuth();

  const users = useSelector((state) => state.users.list);
  const vehicles = vehiclesList
    .slice(1, vehiclesList.length)
    .filter((v) => v.veh_active === 1);

  //const [userOptions, setUserOptions] = useState([]);

  const type = location?.state?.type;

  //Hook form variables
  const vehicle = watch("vehicle");
  const driver = watch("driver");
  const startingOdo = watch("startingOdo");
  const endingOdo = watch("endingOdo");
  const depDate = watch("depDate");
  const arrDate = watch("arrDate");
  const comments = watch("comments");

  const textAreaRef = useRef(null);
  useAutosizeTextArea(textAreaRef.current, comments);

  const { odoHint, odoUnit, lastOdoValue } = useFetchOdoHint({
    vehicle,
    filterDateEnd: getUnixTime(depDate),
  });

  const [showHighStartingOdoErr, setShowHighStartingOdoErr] = useState(false);
  const [showHighEndingOdoErr, setShowHighEndingOdoErr] = useState(false);

  //State to show hourly vehicle unavailable popup
  const [showNoHourlyVehiclePopup, setShowNoHourlyVehiclePopup] =
    useState(false);

  useEffect(() => {
    if (
      startingOdo &&
      endingOdo &&
      +endingOdo - +startingOdo > 1000 &&
      !firstTimeEdit.current
    ) {
      setShowHighEndingOdoErr(true);
    } else {
      setShowHighEndingOdoErr(false);
    }

    if (type === "edit" && firstTimeEdit.current) {
      setShowHighEndingOdoErr(false);
      firstTimeEdit.current = false;
    }
  }, [endingOdo, startingOdo]);

  //Effect to set user options
  // useEffect(() => {
  //   (async () => {
  //     if (vehicle) {
  //       const apiData = {
  //         veh_id: vehicle?.vehicleId,
  //         querystring: "getvehicleoperators",
  //       };
  //       const res = await postApi(apiData, "commonNew");
  //       if (res.success) {
  //         const { user_data } = res;
  //         if (user_data?.length > 0) {
  //           let temp = [];
  //           for (let user of user_data) {
  //             let filteredUser = users.find((u) => u.user_id === user.user_id);
  //             temp.push(filteredUser);
  //           }
  //           setUserOptions(temp);
  //         }
  //       }
  //     }
  //   })();
  // }, [vehicle, users]);

  return (
    <>
      {showNoHourlyVehiclePopup && (
        <InfoPopup
          showPopup={showNoHourlyVehiclePopup}
          setShowPopup={setShowNoHourlyVehiclePopup}
          message={t("no_support_for_trips_with_hrs_msg")}
          icon={{ className: "fi fi-rr-info", color: colorBlue }}
          actionIcon={{
            handler: () => {
              setShowNoHourlyVehiclePopup(false);
            },
            label: t("ok"),
          }}
        />
      )}
      <Container className="px-4 pb-4">
        <Row>
          <Col>
            <Row>
              <Col md={4}>
                <FormGroupLabel>{t("vehicle")}</FormGroupLabel>
                <Controller
                  name="vehicle"
                  control={control}
                  //errors={errors}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      options={vehicles}
                      //value={vehicles.find((c) => c.value === value)}
                      value={value}
                      onChange={(veh) => {
                        if (veh.primary_meter === "Hours") {
                          setShowNoHourlyVehiclePopup(true);
                          setValue("vehicle", "");
                        } else {
                          handleVehicleOnChange(
                            veh,
                            onChange,
                            "driver",
                            setValue,
                            setUserOptions,
                            userId
                          );
                        }
                      }}
                      //onCreateOption={handleCreate}
                      placeholder={t("vehicle_placeholder")}
                      styles={formStyles}
                      isDisabled={false}
                    />
                  )}
                  rules={{ required: t("required_err") }}
                />

                {errors?.vehicle && <Error error={errors.vehicle.message} />}
              </Col>
              <Col md={2}></Col>

              {vehicle && (
                <Col md={4}>
                  <FormGroupLabel>{t("trip_by")}</FormGroupLabel>
                  <Controller
                    name="driver"
                    control={control}
                    //errors={errors}
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        options={userOptions}
                        //value={vehicles.find((c) => c.value === value)}
                        value={value}
                        onChange={onChange}
                        //onCreateOption={handleCreate}
                        placeholder={t("role_placeholder")}
                        styles={formStyles}
                        isDisabled={false}
                      />
                    )}
                    rules={{ required: t("required_err") }}
                  />

                  {errors?.driver && <Error error={errors.driver.message} />}
                </Col>
              )}
            </Row>

            {vehicle && (
              <>
                <Row>
                  <Col md={8}>
                    <hr className="mt-4 mb-0 p-0" />
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <FormGroupLabel>{t("dep_date_and_time")}</FormGroupLabel>
                    <Controller
                      name="depDate"
                      //defaultValue={new Date()}
                      control={control}
                      render={({ field: { onChange, value, ref } }) => (
                        <DatePicker
                          maxDate={new Date()}
                          //minDate={new Date()}
                          selected={value}
                          locale={getDateFnsLocale()}
                          dateFormat={"P h:mm aa"}
                          showMonthDropdown
                          showYearDropdown
                          onChange={onChange}
                          showTimeInput
                          customInput={
                            <CustomInput
                              clearable={true} //Not clearable but class added to maintain styling consistency with arrDate
                              //disabled={status == 1 || status == 2}
                            />
                          }
                          placeholderText={t("dep_date_and_time")}

                          //disabled={status == 1 || status == 2}
                        />
                      )}
                      rules={{
                        required: t("required_err"),
                      }}
                    />
                    {errors?.depDate && (
                      <Error error={errors.depDate.message} />
                    )}
                  </Col>
                  <Col md={2}></Col>

                  <Col md={4}>
                    <FormGroupLabel>{t("arr_date_and_time")}</FormGroupLabel>
                    <Controller
                      name="arrDate"
                      //defaultValue={new Date()}
                      control={control}
                      render={({ field: { onChange, value, ref } }) => (
                        <DatePicker
                          maxDate={new Date()}
                          //minDate={new Date()}
                          selected={value}
                          onCalendarOpen={() => {
                            if (!arrDate) {
                              setValue("arrDate", new Date());
                            }
                          }}
                          onChange={onChange}
                          //adjustDateOnChange
                          showTimeInput
                          locale={getDateFnsLocale()}
                          dateFormat={"P h:mm aa"}
                          showMonthDropdown
                          showYearDropdown
                          isClearable
                          customInput={
                            <CustomInput
                              clearable={true}
                              //disabled={status == 1 || status == 2}
                            />
                          }
                          placeholderText={t("arr_date_and_time")}

                          //disabled={status == 1 || status == 2}
                        />
                      )}
                      rules={{
                        required: endingOdo ? t("required_err") : false,
                        min: {
                          value: +depDate + 1000,
                          message: t("dep_date_greater_than_arr_date_err_msg"),
                        },
                      }}
                    />
                    {errors?.arrDate && (
                      <Error error={errors.arrDate.message} />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroupLabel>{t("dep_loc")}</FormGroupLabel>
                    <FormItem
                    //disabled={status == 2}
                    //tabIndex={0}
                    >
                      <FormField
                        name="depLocation"
                        control={control}
                        component="input"
                        //rules={{ required: t("required_err") }}
                        defaultValue=""
                        //isAboveError={isAboveError}
                        placeholder={t("dep_loc")}
                        style={formInputStyles}
                        //disabled={status == 2}
                        //onKeyUp={handleSIOdoChange}
                      />
                    </FormItem>

                    {errors?.depLocation && (
                      <Error error={errors.depLocation.message} />
                    )}
                  </Col>
                  <Col md={2}></Col>

                  <Col md={4}>
                    <FormGroupLabel>{t("arr_loc")}</FormGroupLabel>
                    <FormItem
                    //disabled={status == 2}
                    //tabIndex={0}
                    >
                      <FormField
                        name="arrLocation"
                        control={control}
                        component="input"
                        //rules={{ required: t("required_err") }}
                        defaultValue=""
                        //isAboveError={isAboveError}
                        placeholder={t("arr_loc")}
                        style={formInputStyles}
                        //disabled={status == 2}
                        //onKeyUp={handleSIOdoChange}
                      />
                    </FormItem>

                    {errors?.arrLocation && (
                      <Error error={errors.arrLocation.message} />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroupLabel>{t("starting_odo")}</FormGroupLabel>
                    <FormItem showWarning={showHighStartingOdoErr}>
                      <FormField
                        name="startingOdo"
                        control={control}
                        component="input"
                        type="number"
                        onKeyDown={(e) => handleNegative(e)}
                        onWheel={handleNumberInputOnWheelPreventChange}
                        //rules={{ required: t("required_err") }}
                        //errors={errors}
                        rules={{
                          required: t("required_err"),
                          //   max: {
                          //     value: endingOdo && +endingOdo - 1,
                          //     message:
                          //       "Ending Odo must be greater than Starting Odo",
                          //   },
                        }}
                        defaultValue=""
                        //isAboveError={isAboveError}
                        placeholder={t("starting_odo")}
                        style={formInputStyles}
                        //disabled={status == 2}
                        onKeyUp={(e) => {
                          const { value } = e.target;
                          if (
                            value &&
                            lastOdoValue &&
                            +value - +lastOdoValue > 1000
                          ) {
                            setShowHighStartingOdoErr(true);
                          } else {
                            setShowHighStartingOdoErr(false);
                          }
                        }}
                      />
                      <UnitSpan>{odoUnit}</UnitSpan>
                      {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                    </FormItem>
                    {odoHint && <HintText>{odoHint}</HintText>}

                    {/* {errors?.startingOdo && (
                    <Error error={errors.startingOdo.message} />
                  )} */}
                    {errors?.startingOdo ? (
                      <Error error={errors.startingOdo.message} />
                    ) : (
                      showHighStartingOdoErr && (
                        <Error error={t("high_odo_err_msg")} />
                      )
                    )}
                  </Col>
                  <Col md={2}></Col>

                  <Col md={4}>
                    <FormGroupLabel>{t("ending_odo")}</FormGroupLabel>
                    <FormItem showWarning={showHighEndingOdoErr}>
                      <FormField
                        name="endingOdo"
                        control={control}
                        component="input"
                        type="number"
                        onKeyDown={(e) => handleNegative(e)}
                        onWheel={handleNumberInputOnWheelPreventChange}
                        rules={{
                          required: arrDate ? t("required_err") : false,
                          // min: {
                          //   value: +startingOdo + 1,
                          //   message:
                          //     "Ending Odo must be greater than Starting Odo",
                          // },
                        }}
                        defaultValue=""
                        placeholder={t("ending_odo")}
                        style={formInputStyles}
                        //disabled={status == 2}
                        // onKeyUp={(e) => {
                        //   const { value } = e.target;
                        //   if (
                        //     value &&
                        //     startingOdo &&
                        //     +value - +startingOdo > 1000
                        //   ) {
                        //     setShowHighEndingOdoErr(true);
                        //   } else {
                        //     setShowHighEndingOdoErr(false);
                        //   }
                        // }}
                      />
                      <UnitSpan>{odoUnit}</UnitSpan>
                    </FormItem>

                    {/* {errors?.endingOdo && (
                    <Error error={errors.endingOdo.message} />
                  )} */}
                    {errors?.endingOdo ? (
                      <Error error={errors.endingOdo.message} />
                    ) : (
                      showHighEndingOdoErr && (
                        <Error error={t("high_odo_err_msg")} />
                      )
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col md={10}>
                    <hr className="mt-4 mb-0 p-0" />
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <FormGroupLabel>{t("distance_travelled")}</FormGroupLabel>
                    <FormValueContainer>
                      <span>
                        {`${
                          startingOdo && endingOdo && +endingOdo > +startingOdo
                            ? (endingOdo - startingOdo)?.toFixed(2)
                            : 0
                        } ${getDistanceUnit(vehicle?.primary_meter)}`}
                        {/* getDistanceFactor(
                                  vehicle.primary_meter,
                                  distance
                                ) */}
                      </span>
                    </FormValueContainer>
                  </Col>
                  <Col md={2}></Col>

                  <Col md={4}>
                    <FormGroupLabel>{t("total_time")}</FormGroupLabel>
                    <FormValueContainer>
                      <span>
                        {dhm(differenceInMilliseconds(arrDate, depDate))}
                      </span>
                    </FormValueContainer>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <FormGroupLabel>
                      {t("average_speed")}
                      <Tooltip text={t("average_speed_tooltip")}>
                        <Icon className="fi fi-rr-info px-1"></Icon>
                      </Tooltip>
                    </FormGroupLabel>
                    <FormValueContainer>
                      <span>{`${
                        rowData?.avg_speed ? rowData?.avg_speed : 0
                      } ${getDistanceUnit(vehicle?.primary_meter)}/hr`}</span>
                    </FormValueContainer>
                  </Col>
                </Row>

                <Row>
                  <Col md={10}>
                    <hr className="mt-4 mb-0 p-0" />
                  </Col>
                </Row>

                <Row>
                  <Col md={10}>
                    <FormGroupLabel>{t("notes_tv")}</FormGroupLabel>
                    {/* <FormItem
                    //disabled={status == 2}
                    //tabIndex={0}
                    >
                      <FormField
                        name="comments"
                        control={control}
                        component="input"
                        defaultValue=""
                        //isAboveError={isAboveError}
                        placeholder={t("notes_tv")}
                        style={formInputStyles}
                        disabled={false}
                        //onKeyUp={handleSIOdoChange}
                      />
                    </FormItem> */}

                    <Controller
                      name="comments"
                      control={control}
                      //defaultValue={users?.length > 0 ? users[0] : ""}
                      //errors={errors}
                      render={({ field: { onChange, value, ref } }) => (
                        <AutoResizeTextArea
                          ref={textAreaRef}
                          onChange={onChange}
                          value={value}
                          placeholder={t("notes_tv")}
                          rows={1}
                          //isDisabled={status == 1 || status == 2}
                        />
                      )}
                      //rules={{ required: t("required_err") }}
                    />

                    {errors?.comments && (
                      <Error error={errors.comments.message} />
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col md={10}>
                    <FormGroupLabel>
                      {t("images")}
                      {tag === "free-user" && (
                        <SubscribeTooltipIcon message={t("subscribe_msg")} />
                      )}
                    </FormGroupLabel>
                    <FileUploaderNew
                      disabled={false}
                      images={tripDocs}
                      setImages={setTripDocs}
                      shouldDelete={true}
                    />

                    {/* <FileUploaderContainer>
                      {tripDocs?.map((img) => {
                        return (
                          <ImageWithZoom
                            key={img.id}
                            img={img}
                            //handleDeleteImg={handleDeleteImg}
                            images={tripDocs}
                            setImages={setTripDocs}
                            shouldDelete={true}
                          />
                        );
                      })}
                      {true && (
                        <FileUploader
                          //handleFileUpload={handleFileUpload}
                          disabled={false}
                          images={tripDocs}
                          setImages={setTripDocs}
                        />
                      )}
                      {true && tripDocs?.length === 0 && (
                        <p
                          style={{
                            textAlign: "center",
                            width: "-webkit-fill-available",
                            color: colorGray,
                          }}
                        >
                          No Images Uploaded
                        </p>
                      )}
                    </FileUploaderContainer> */}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TripDetailsForm;
