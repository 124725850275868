import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { NATEXT, s3Url } from "../../../shared/constants";
import defaultVehicleIcon from "@/shared/img/defaultVehicleIcon.png";
import { useSelector } from "react-redux";
import {
  getDisplayDateWithTimeNew,
  getDistanceFactorforOdo,
  getDistanceUnit,
} from "../../../shared/helpers";
import { WorkOrderStatus } from "../WorkOrdersTable";
import { LogDiv, ReadOnlyIssueTag } from "./LogsPopup";
import { colorLightGray } from "../../../utils/palette";

const WOPrintComponent = forwardRef((props, ref) => {
  const { t } = useTranslation("common");
  const { distance, currency } = useSelector((state) => state.units);
  const { orgName, address, country } = useSelector((state) => state.user);
  const {
    rowData,
    vehicle,
    serviceTasksToPrint,
    issuesToPrint,
    logsToPrint,
    selectedTechnician,
    timeToComplete,
  } = props;
  const {
    wo_id,
    wo_number,
    org_id,
    created_by_name,
    create_date,
    start_date,
    end_date,
    status,
    due_date,
    odo,
    total_time,
    discount,
    tax,
    total,
    po_number,
    invoice_number,
    comments,
    logs,
  } = rowData;

  const {
    veh_img,
    veh_id,
    name,
    primary_meter,
    license_no,
    vin_no,
    veh_active,
    secondary_status,
    veh_type,
  } = vehicle;
  const distanceFactor = getDistanceFactorforOdo(primary_meter, distance);

  let odoVal = odo;
  if (odo > 0 && odo !== -1000) {
    odoVal = (+odo / distanceFactor)?.toFixed(2);
  }

  const imageUrl = veh_img
    ? `${s3Url}/org_${org_id}/vehicle_${veh_id}/${veh_img}`
    : defaultVehicleIcon;

  let grandTotal = +total - +discount + +tax;

  let vehicleStatus = "active";
  if (veh_active === 0 || veh_active === 2) {
    vehicleStatus = "inactive";
  }
  if (veh_active === 1 && secondary_status === 1) {
    vehicleStatus = "outOfService";
  }

  const vehicleStatusStyles = {
    active: { text: t("active"), color: "green" },
    inactive: { text: t("inactive"), color: colorLightGray },
    outOfService: { text: t("out_of_service"), color: "red" },
  };

  return (
    <div ref={ref} className="m-5 d-flex flex-column gap-4">
      <div
        className="d-flex justify-content-between"
        //style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>
          <p>{orgName}</p>
          <p>{address}</p>
          <p>{country}</p>
        </div>
        <div>
          <p>{`${t("wo_number")} ${wo_number}`}</p>
        </div>
      </div>

      <div>
        <div className="d-flex gap-2 align-items-center">
          <img
            src={imageUrl}
            alt="vehicle-img"
            width={40}
            height={40}
            // style={{ width: "40px", height: "40px" }}
            className="rounded-circle"
          />
          <div>
            <p style={{ fontSize: 16, fontWeight: 500 }}>{name}</p>
            <div className="d-flex gap-1">
              <p>{veh_type}</p>
              <p>-</p>
              <p style={{ color: vehicleStatusStyles[vehicleStatus]?.color }}>
                {vehicleStatusStyles[vehicleStatus]?.text}
              </p>
            </div>
            <div className="d-flex gap-1">
              <span>{t("vin")}:</span>
              <p>{vin_no || NATEXT}</p>
            </div>
            <div className="d-flex gap-1">
              <span>{t("license")}:</span>
              <p>{license_no || NATEXT}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <div>
          <span>{t("created_by")}</span>
          <p>{created_by_name}</p>
        </div>

        <div>
          <span>{t("created_date")}</span>
          <p>{getDisplayDateWithTimeNew(create_date)}</p>
        </div>

        <div>
          <span>{t("status")}</span>
          <WorkOrderStatus status={status} />
        </div>

        <div>
          <span>{t("due_date")}</span>
          <p>{due_date ? getDisplayDateWithTimeNew(due_date) : NATEXT}</p>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <div>
          <span>{t("accepted_by")}</span>
          <p>{selectedTechnician ? selectedTechnician : NATEXT}</p>
        </div>
        <div>
          <span>{t("started_on")}</span>
          <p>{start_date ? getDisplayDateWithTimeNew(start_date) : NATEXT}</p>
        </div>

        <div>
          <span>{t("ended_on")}</span>
          <p>{end_date ? getDisplayDateWithTimeNew(end_date) : NATEXT}</p>
        </div>

        <div>
          <span>{t("time_taken_to_complete")}</span>
          <p>{timeToComplete || NATEXT}</p>
        </div>

        <div>
          <span>{t("odometer")}</span>
          <p>
            {odo != -1000
              ? `${odoVal} ${getDistanceUnit(primary_meter)}`
              : NATEXT}
          </p>
        </div>
      </div>

      {/* Issues */}
      {issuesToPrint?.length > 0 && (
        <div className="d-flex flex-column gap-2">
          <h5>{t("service_tasks")}</h5>
          <table style={{ width: "100%" }} className="table table-bordered">
            <thead>
              <tr>
                <th>{t("issue")}</th>
                <th>{t("issue_desc")}</th>
                {/* <th>{t("images")}</th> */}
              </tr>
            </thead>
            <tbody>
              {issuesToPrint.map((issue) => (
                <tr key={issue.issue_id}>
                  <td>{issue.issue}</td>
                  <td>{issue.issue_desc || NATEXT}</td>
                  {/* <td>
                    {issue?.images?.length > 0
                      ? issue.images.map((img) => (
                          <div className="d-flex align-items-center">
                            {["png", "jpg", "jpeg"].includes(img?.extension) ? (
                              <img
                                src={img.url}
                                width={40}
                                height={40}
                                alt={`issue-img-${img.id}`}
                              />
                            ) : (
                              <iframe
                                src={img.url}
                                width={40}
                                height={40}
                                //alt={`issue-img-${img.id}`}
                                //src={img.url}
                                //width={40}
                                //height={40}
                              />
                            )}
                          </div>
                        ))
                      : NATEXT}
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Service Tasks, Discount, Tax, Total */}
      {serviceTasksToPrint?.length > 0 && (
        <div className="d-flex flex-column gap-2">
          <h5>{t("service_tasks")}</h5>
          <table style={{ width: "100%" }} className="table table-bordered">
            <thead>
              <tr>
                <th>{t("service_task_name")}</th>
                <th>{`${t("parts_cost")} (${currency})`}</th>
                <th>{`${t("labor_cost")} (${currency})`}</th>
                <th>{`${t("total")} (${currency})`}</th>
              </tr>
            </thead>
            <tbody>
              {serviceTasksToPrint.map((task) => (
                <tr key={task.service_task_id}>
                  <td>{task.service_task_name}</td>
                  <td>{task.partsCost || NATEXT}</td>
                  <td>{task.laborCost || NATEXT}</td>
                  <td>{task.totalCost || NATEXT}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="align-self-end" style={{ minWidth: "20%" }}>
            <div className="d-flex justify-content-between">
              <span>{`${t("discount")}: `}</span>
              <p>{discount ? `${discount} ${currency}` : NATEXT}</p>
            </div>
            <div className="d-flex justify-content-between">
              <span>{`${t("tax")}: `}</span>
              <p>{tax ? `${tax} ${currency}` : NATEXT}</p>
            </div>
            <div className="d-flex justify-content-between">
              <span>{`${t("grand_total")}: `}</span>
              <p>{grandTotal ? `${grandTotal} ${currency}` : NATEXT}</p>
            </div>
          </div>
        </div>
      )}

      <div className="d-flex gap-4">
        <p>{`${t("invoice_no")}: ${invoice_number || NATEXT}`}</p>
        <p>{`${t("po_number")}: ${po_number || NATEXT}`}</p>
      </div>

      <div>
        <span>{t("notes_tv")}</span>
        <p>{comments || NATEXT}</p>
      </div>

      {/* Logs */}
      {logsToPrint?.length > 0 && (
        <div className="d-flex flex-column gap-2">
          <h5>{t("notes_prog_updates")}</h5>
          {logsToPrint.map((log) => (
            <LogDiv
              key={log.log_id}
              //   style={{
              //     borderRadius: "5px",
              //     background: taskBgColor,
              //     padding: "0.5rem",
              //   }}
            >
              <div className="d-flex flex-column gap-1">
                <div className="d-flex justify-content-between">
                  <p
                    style={{
                      fontStyle: "italic",
                      fontSize: "12px",
                      fontWeight: 300,
                      alignSelf: "center",
                    }}
                  >{`${getDisplayDateWithTimeNew(log.time_stamp)} - ${
                    log.user
                  }`}</p>

                  {(log.log_type == 1 ||
                    log.log_type == 2 ||
                    log.log_type == 3 ||
                    log.log_type == 4 ||
                    log.log_type == 5) && (
                    <div>
                      {log.log_type == 1 && (
                        <div className="d-flex gap-1">
                          <i
                            className="fi fi-rr-add"
                            style={{ position: "relative", top: "2px" }}
                          ></i>
                          <span>{t("wo_created")}</span>
                        </div>
                      )}
                      {log.log_type == 2 && (
                        <div className="d-flex gap-1">
                          <i
                            className="fi fi-rs-check-circle"
                            style={{ position: "relative", top: "2px" }}
                          ></i>
                          <span>{t("wo_accepted")}</span>
                        </div>
                      )}
                      {log.log_type == 3 && (
                        <div className="d-flex gap-1">
                          <i
                            className="fi fi-rr-pause-circle"
                            style={{ position: "relative", top: "2px" }}
                          ></i>
                          <span>{t("wo_paused")}</span>
                        </div>
                      )}
                      {log.log_type == 4 && (
                        <div className="d-flex gap-1">
                          <i
                            className="fi fi-rr-play-circle"
                            style={{ position: "relative", top: "2px" }}
                          ></i>
                          <span>{t("wo_resumed")}</span>
                        </div>
                      )}
                      {log.log_type == 5 && (
                        <div className="d-flex gap-1 align-items-center">
                          <i
                            className="fi fi-rr-stop-circle"
                            style={{ position: "relative", top: "2px" }}
                          ></i>
                          <span>{t("wo_closed")}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {log?.message && <p>{log.message}</p>}
                {log?.log_type === 0 &&
                  log?.linkedIssues &&
                  log?.linkedIssues?.length > 0 && (
                    <div className="d-flex gap-2 flex-wrap">
                      {log.linkedIssues.map((issueName, i) => (
                        <ReadOnlyIssueTag key={i}>
                          <span>{issueName}</span>
                        </ReadOnlyIssueTag>
                      ))}
                    </div>
                  )}
              </div>
            </LogDiv>
          ))}
        </div>
      )}
    </div>
  );
});

export default WOPrintComponent;
