import React, { useRef, useState } from "react";
import {
  AutoResizeTextArea,
  CheckBoxInput,
  CostElement,
  CostInput,
  FormContainer,
  FormGroupLabel,
  FormItem,
  HintText,
  Label,
  UnitSpan,
} from "../../shared/components/form/FormElements";
import FormHeader from "../../shared/components/form/FormHeader";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "@/shared/components/Card";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import {
  formInputStyles,
  formStyles,
  openDropdownStyles,
} from "../../shared/constants/styles";
import { useSelector } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import FormField from "../../shared/components/form/FormField";
import { CustomInput } from "../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import {
  getDateFnsLocale,
  getDateWithTime,
  getDistanceFactorforOdo,
  getEditUsers,
  getInitialStartTime,
  getTimestamp,
  handleHighOdoErr,
  handleNegative,
  handleNumberInputOnWheelPreventChange,
  handleVehicleOnChange,
  odoValidation,
  uploadDocsToS3,
} from "../../shared/helpers";
import { colorBg, colorGray, colorLightGray } from "../../utils/palette";
import FileUploader from "../../shared/components/form/FileUploader";
import { FileUploaderContainer } from "../Issues/IssuesForm";
import CreatableSelect from "react-select/creatable";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  AddFormButton,
  ActionIconContainer,
} from "../../shared/components/TableElements";
import styled from "styled-components";
import Error from "@/shared/components/form/Error";
import getUnixTime from "date-fns/getUnixTime";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import ImageWithZoom from "../../shared/components/form/ImageWithZoom";
import { addMsg } from "../../features/snackbar/snackbarSlice";
import { postApi } from "../../api/getUser";
import { useHistory, useLocation } from "react-router";
import format from "date-fns/format";
import AddButton from "../../shared/components/form/AddButton";
import ExpenseTasksFormPopup from "./ExpenseTasksFormPopup";
import { MultiValueOption } from "../VehicleInspection/InspectionForms/InspectionFormsForm";
import useFetchOdoHint from "../../shared/custom-hooks/useFetchOdoHint";
import SubscribeTooltipIcon from "../../shared/components/SubscribeTooltipIcon";
import { useAuth } from "../../contexts/AuthContext";
import useFetchOdo from "../../shared/custom-hooks/useFetchOdo";
import FileUploaderNew from "../../shared/components/form/FileUploaderNew";
import ErrorPopup from "../../shared/components/ErrorPopup";
import { s3Url } from "../../shared/constants";
import useAutosizeTextArea from "../../shared/custom-hooks/useAutosizeTextArea";

const ExpensesForm = () => {
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const vehicles = vehiclesList
    .slice(1, vehiclesList.length)
    .filter((v) => v.veh_active === 1);
  const storedFormData = useSelector((state) => state.formData.storedFormData);

  const { currency, distance } = useSelector((state) => state.units);

  const [showAddExpenseModal, setShowAddExpenseModal] = useState(false);

  //hook-form variables
  const vehicle = watch("vehicle");
  const odometer = watch("odometer");
  const expenseDate = watch("expenseDate");
  const expenseTime = watch("expenseTime");
  const tax = watch("tax");
  const discount = watch("discount");
  const cardNo = watch("cardNo");
  const transactionType = watch("transactionType");
  const vendor = watch("vendor");
  const expenseBy = watch("expenseBy");
  const comments = watch("comments");

  const textAreaRef = useRef(null);
  useAutosizeTextArea(textAreaRef.current, comments);

  const [expenseTasks, setExpenseTasks] = useState([]);
  const [expenseTaskOptions, setExpenseTaskOptions] = useState([]);
  const allExpenseTasks = useSelector((state) => state.expenseTasks.list);
  const users = useSelector((state) => state.users.list);
  const { userId, orgId } = useSelector((state) => state.user);
  const { tag } = useAuth();

  //const [tax, setTax] = useState(0);
  //const [discount, setDiscount] = useState(0);
  const [total, setTotal] = useState("");
  const [grandTotal, setGrandTotal] = useState(0);

  const [expenseImages, setExpenseImages] = useState([]);

  const [showExpenseTasksError, setShowExpenseTasksError] = useState(false);
  const expenseTasksErrorMsg = t("invalid_et_msg");

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  //State to toggle Vehicle out of service popup
  const [showOutOfServicePopup, setShowOutOfServicePopup] = useState(false);

  const [showHighOdoErr, setShowHighOdoErr] = useState(false);

  const transactionTypeOptions = [
    {
      value: "Cash",
      label: t("cash_const"),
    },
    {
      value: "Check",
      label: t("check_const"),
    },
    {
      value: "Credit card",
      label: t("credit_card_const"),
    },
    {
      value: "Debit card",
      label: t("debit_card_const"),
    },
  ];

  const [odoNotReqd, setOdoNotReqd] = useState(false);
  const [lastOdo, setLastOdo] = useState("");

  //To toggle error popup while adding/editing form
  const [showErrPopup, setShowErrPopup] = useState(false);

  const { odoHint, odoUnit, lastOdoValue, nextOdoValue } = useFetchOdoHint({
    vehicle,
    date: expenseDate,
    time: expenseTime,
  });

  const [userOptions, setUserOptions] = useState([]);

  const location = useLocation();
  //const { data, screen, type } = location?.state;
  const rowData = location?.state?.data?.rowData;
  const screen = location?.state?.screen;
  const type = location?.state?.type;
  const mode = location?.state?.mode;
  const routeData = location?.state?.routeData;
  const formData = getValues();

  const { odoFetchedMsg, setOdoFetchedMsg } = useFetchOdo({
    vehicle,
    type,
    setValue,
    lastOdoValue,
    setShowHighOdoErr,
    odoReqd: !odoNotReqd,
    odoReqdPresent: true,
  });

  const existingFormInfo = {
    ...formData,
    expenseDate: JSON.stringify(expenseDate),
    odoNotReqd,
    expenseImages,
    expenseTasks,
  };

  useEffect(() => {
    if (type !== "edit") {
      const signedInUser = users.find((u) => u.user_id === userId);
      setValue("expenseBy", signedInUser);

      let defaultTransactionTypeValue = "Credit card";
      //Check if transaction type preference is already set in local storage
      const transactionTypePref = localStorage.getItem("transactionType");

      if (transactionTypePref) {
        defaultTransactionTypeValue = transactionTypePref;
      }
      setValue(
        "transactionType",
        transactionTypeOptions.find(
          (t) => t.value === defaultTransactionTypeValue
        )
      );
      // setValue(
      //   "transactionType",
      //   transactionTypeOptions.find((t) => t.value === "Credit card")
      // );
      setValue("expenseDate", new Date());
      setValue("expenseTime", getInitialStartTime());
    }

    if (type === "add" && mode === "from-renewals") {
      setValue(
        "vehicle",
        vehicles.find((v) => v.vehicleId === rowData.veh_id)
      );
      setValue(
        "comments",
        t("comments_expense_from_renewal", {
          taskName: rowData.expense_task_name,
        })
      );

      //Logic to set expenseTasks
      let selectedExpenseTask = allExpenseTasks.find(
        (t) => t.expense_task_id === rowData.expense_task_id
      );

      if (selectedExpenseTask) {
        setExpenseTasks([selectedExpenseTask]);
      }
    }
  }, [userId]);

  //Effect to clear odo input field if odoNotReqd is true
  // useEffect(() => {
  //   if (odoNotReqd) {
  //     setValue("odometer", "");
  //   }
  // }, [odoNotReqd]);

  //Effect to update expenseTaskOptions on change of vehicle
  useEffect(() => {
    if (vehicle) {
      (async () => {
        const apiData = {
          querystring: "getallexpensetask",
          expense_veh_id: vehicle?.vehicleId,
        };

        const expenseTasksRes = await updateExpenseTaskOptions(apiData);
        setExpenseTaskOptions(expenseTasksRes);
      })();
    }
  }, [vehicle]);

  //Effect for Edit Service
  useEffect(() => {
    if (
      type === "edit" &&
      vehiclesList?.length > 0 &&
      //users?.length > 0 &&
      orgId
    ) {
      setValue(
        "vehicle",
        vehicles.find((v) => v.vehicleId === rowData?.veh_id)
      );
      if (rowData?.required) {
        //setValue("odometer", rowData?.odo);
        const distanceFactor = getDistanceFactorforOdo(
          vehicles.find((v) => v.vehicleId === rowData?.veh_id)?.primary_meter,
          distance
        );
        const odoUpd = rowData.odo
          ? (rowData?.odo / distanceFactor)?.toFixed(2)
          : 0;
        setValue("odometer", odoUpd);
      }

      setValue("expenseDate", new Date(rowData?.expense_date * 1000));
      setValue(
        "expenseTime",
        format(new Date(rowData?.expense_date * 1000), "HH:mm")
      );

      //Set user and user options
      (async () => {
        const editUsers = await getEditUsers(
          rowData?.veh_id,
          rowData?.expense_by
        );
        if (editUsers?.length > 0) {
          const selectedUser = editUsers?.find(
            (u) => u.user_id === rowData?.expense_by
          );
          setValue("expenseBy", selectedUser);
          setUserOptions(editUsers);
        }
      })();

      // const selectedUser = users?.find(
      //   (u) => u.user_id === rowData?.expense_by
      // );
      // setValue("expenseBy", selectedUser);
      setValue("vendor", rowData?.vendor);
      setValue("cardNo", rowData?.card_number);
      setValue("tax", rowData?.tax);
      setValue("discount", rowData?.discount);

      setValue(
        "transactionType",
        transactionTypeOptions?.find(
          (t) => t.value === rowData.transaction_type
        )
      );
      setValue("comments", rowData?.comments);

      //Logic to set Images
      let imgNames = [];
      const editExpenseImgName = rowData?.receipt_img_name;
      if (editExpenseImgName) {
        imgNames = editExpenseImgName.split(":::");

        const imgs = [];
        imgNames.map((imgName, i) => {
          imgs.push({
            existing: true,
            imgName: imgName,
            id: Date.now() + i,
            url: `${s3Url}/org_${orgId}/expenses/${imgName}`,
          });
        });
        setExpenseImages(imgs);
      }
      //End

      const totalCost = rowData?.total_cost;

      setOdoNotReqd(!rowData?.required);
    }
  }, [rowData, vehiclesList, orgId]);

  useEffect(() => {
    if (type === "edit" && rowData) {
      (async () => {
        let expenseTaskOptions = [];
        let selectedExpenseTasks = [];
        const apiData = {
          querystring: "get_all_expense_tasks",
          expense_veh_id: "All",
        };

        const res = await postApi(apiData, "commonNew");

        if (res.success) {
          expenseTaskOptions = res?.user_data;
          const expenseTaskIds = JSON.parse(rowData?.expense_task_ids);
          const costData = JSON.parse(rowData?.cost);
          const totalCost = rowData?.total_cost;

          expenseTaskIds?.forEach((taskId) => {
            const task = expenseTaskOptions.find(
              (t) => t.expense_task_id === taskId
            );
            let temp = {
              ...task,
              label: task?.expense_task_name,
              value: task?.expense_task_id,
            };
            // if (+costData[taskId]) {
            temp.cost = +costData[taskId] ? costData[taskId] : "";
            // }
            selectedExpenseTasks.push(temp);
          });
        }
        setExpenseTasks(selectedExpenseTasks);
      })();
    }
  }, [rowData]);

  //Effect to set total and grand total
  useEffect(() => {
    if (expenseTasks?.length > 0) {
      setShowExpenseTasksError(false);
      const totalArr = expenseTasks.map((t) => +t.cost);
      let sum = totalArr.reduce((acc, n) => +acc + +n);
      if (+sum) {
        sum = +sum.toFixed(2);
      }
      setTotal(sum);
      let totalCost = sum - +discount + +tax;
      if (+totalCost) {
        totalCost = totalCost.toFixed(2);
      }
      if (totalCost >= 0) {
        setGrandTotal(totalCost);
      } else {
        setGrandTotal(0);
      }
      //setGrandTotal(partsTotal + laborTotal - discount + tax);
    } else {
      //setPartsCostTotal(0);
      //setLaborCostTotal(0);
      setTotal("");
      setGrandTotal(0);
    }
  }, [expenseTasks, discount, tax]);

  //Clear cardNo value on change of transaction type
  useEffect(() => {
    if (
      transactionType?.value === "Cash" ||
      transactionType?.value === "Check"
    ) {
      setValue("cardNo", "");
    }
  }, [transactionType]);

  //Limit card number to 4 digits
  useEffect(() => {
    if (
      (transactionType?.value === "Debit card" ||
        transactionType?.value === "Credit card") &&
      cardNo
    ) {
      if (cardNo?.length > 4) {
        setValue("cardNo", cardNo.slice(0, 4));
      }
    }
  }, [cardNo]);

  const handleExpenseTaskDelete = (id) => {
    setExpenseTasks((prev) => {
      const temp = prev.filter((t) => t.expense_task_id !== id);
      return temp;
    });
  };

  const handleCostChange = (e, id) => {
    const { value } = e.target;
    setExpenseTasks((prev) => {
      const temp = prev.map((item) => {
        if (item.expense_task_id === id) {
          return {
            ...item,
            cost: value,
          };
        } else {
          return item;
        }
      });
      return temp;
    });
  };

  const onSubmit = async () => {
    try {
      if (expenseTasks?.length === 0) {
        setShowExpenseTasksError(true);
        return;
      }
      setSaveBtnLoading(true);

      const distanceFactor = getDistanceFactorforOdo(
        vehicle?.primary_meter,
        distance
      );
      let odoConverted = odometer * distanceFactor;

      let odoValue = lastOdoValue ? lastOdoValue : nextOdoValue;
      if (odometer) {
        //Odo Validation
        const odoCheckApiData = {
          odo: odoConverted,
          veh_id: vehicle.vehicleId,
          datetocheck: getDateWithTime(expenseDate, expenseTime),
          querystring: "get_around_dates_v3",
          check_from: "expenses",
          check_id: type === "edit" ? rowData?.expense_id : "",
        };

        const odoValidationRes = await odoValidation(
          "odometer",
          odoCheckApiData,
          setError,
          setSaveBtnLoading,
          t,
          distanceFactor,
          odometer
        );

        if (!odoValidationRes) {
          return;
        }
      }

      let expenseId =
        type === "edit"
          ? rowData?.expense_id
          : `${vehicle?.vehicleId}_${Date.now()}`;

      // if (mode === "close-issue-with-service") {
      //   serviceId = `${vehicle?.vehicleId}_${Date.now()}`;
      // }

      let expenseDateWithTime = new Date(expenseDate);
      let [hours, minutes] = expenseTime.split(":");
      if (hours < 10) {
        hours = hours[1];
      }

      if (minutes < 10) {
        minutes = minutes[1];
      }
      expenseDateWithTime = setHours(expenseDateWithTime, hours);
      expenseDateWithTime = setMinutes(expenseDateWithTime, minutes);

      let expenseTaskIds = [];
      let costData = {};

      if (expenseTasks?.length > 0) {
        expenseTasks?.forEach((t) => {
          expenseTaskIds.push(t.expense_task_id);
          costData[t.expense_task_id] = +t.cost ? t.cost : 0;
        });
      }

      let imagesToS3 = [];

      let imagesString = "";

      expenseImages?.forEach((img, i) => {
        if (img?.hasOwnProperty("existing")) {
          imagesString += `${img?.imgName}:::`;
        } else {
          if (img.file) {
            const extension = img?.file?.name.split(".").pop();
            const imgName = `${getTimestamp(userId, i)}.${extension}`;
            const key = `image_data/org_${orgId}/expenses/${imgName}`;

            imagesString += `${imgName}:::`;

            imagesToS3.push({
              ...img,
              key: key,
            });
            // return {
            //   ...img,
            //   key: key,
            // };
          }
        }
      });
      imagesString = imagesString.slice(0, imagesString.length - 3);

      const apiData = {
        querystring: "add_expense",
        action: type === "edit" ? 2 : 1,
        sync_version: "v2",
        expense_date: getUnixTime(expenseDateWithTime),
        operator_userid: expenseBy?.user_id,
        source: "web",
        comments: comments ? comments : "",
        operation: type === "edit" ? "update" : "create",
        veh_id: vehicle.vehicleId,
        transaction_type: transactionType ? transactionType.value : "",
        card_number: cardNo ? cardNo : 0,
        receipt_img_name: imagesString,
        expense_id: expenseId,
        currency: currency ? currency : "",
        odo: odoNotReqd ? odoValue : odoConverted,
        expense_task_ids: expenseTaskIds,
        odo_unit: vehicle?.primary_meter === "Hours" ? "Hours" : distance,
        vendor: vendor ? vendor : "",
        total_cost: total ? total : 0,
        cost: costData,
        odo_required: odoNotReqd === true ? 0 : 1,
        veh_max_odo: odoValue,
        tax: tax ? +tax : 0,
        discount: discount ? +discount : 0,
      };

      const snackbarMsg =
        type === "edit"
          ? t("record_edited_msg", { recordName: t("expense") })
          : t("record_added_msg", { recordName: t("expense") });
      //(async () => {
      const response = await postApi(apiData, "commonNew");

      if (response.success === 1) {
        if (imagesToS3?.length > 0) {
          const docsUploaded = await uploadDocsToS3(imagesToS3);
          if (docsUploaded) {
            dispatch(addMsg(snackbarMsg));
            history.push("/expenses/expenses", { fromCancel: false });
            setSaveBtnLoading(false);
          }
        } else {
          dispatch(addMsg(snackbarMsg));
          history.push("/expenses/expenses", { fromCancel: false });

          setSaveBtnLoading(false);
        }
      } else {
        setSaveBtnLoading(false);
        setShowErrPopup(true);
        return;
      }
    } catch (e) {
      setSaveBtnLoading(false);
      setShowErrPopup(true);
      return;
    }
  };

  return (
    <>
      {/* {showOutOfServicePopup && (
        <OutOfServicePopup
          vehOutOfService={vehOutOfService}
          issueApiData={dataFromIssues.issueApiData}
          showOutOfServicePopup={showOutOfServicePopup}
          setShowOutOfServicePopup={setShowOutOfServicePopup}
          updateIssue={updateIssue}
          setSaveBtnLoading={setSaveBtnLoading}
        />
      )} */}
      {showErrPopup && (
        <ErrorPopup showPopup={showErrPopup} setShowPopup={setShowErrPopup} />
      )}
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <AddTaskModal
          showModal={showAddExpenseModal}
          setShowModal={setShowAddExpenseModal}
          taskOptions={expenseTaskOptions}
          setTaskOptions={setExpenseTaskOptions}
          tasks={expenseTasks}
          setTasks={setExpenseTasks}
          existingFormInfo={existingFormInfo}
          mode={"from-add-expense"}
          route={"/expenses/expenses/addnew"}
          selectedVehicleId={vehicle?.vehicleId}
        />
        <FormHeader
          // handleCancel={() =>
          //   history.push("/expenses/expenses", { fromCancel: true })
          // }
          handleCancel={() => {
            if (mode && mode === "from-renewals") {
              history.push("/reminders/renewals", {
                fromCancel: true,
              });
            } else {
              history.push("/expenses/expenses", { fromCancel: true });
            }
          }}
          disableSave={!vehicle}
          isSaveLoading={saveBtnLoading}
          title={type === "edit" ? t("edit_expense") : t("add_expense")}
          //handleCancel={handleCancel}
        />
        <Container>
          <Card>
            <CardBody
              style={{
                height: `${window.innerHeight - 150}px`,
                overflow: "auto",
              }}
            >
              <Row>
                <Col>
                  <Row>
                    <Col md={6}>
                      <FormGroupLabel>{t("vehicle")}</FormGroupLabel>
                      <Controller
                        name="vehicle"
                        control={control}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            options={vehicles}
                            //value={vehicles.find((c) => c.value === value)}
                            value={value}
                            //onChange={onChange}
                            onChange={(v) =>
                              handleVehicleOnChange(
                                v,
                                onChange,
                                "expenseBy",
                                setValue,
                                setUserOptions,
                                userId
                              )
                            }
                            //onCreateOption={handleCreate}
                            placeholder={t("vehicle_placeholder")}
                            styles={formStyles}
                            // isDisabled={
                            //   mode && mode === "close-issue-with-service"
                            // }
                          />
                        )}
                        rules={{ required: t("required_err") }}
                      />

                      {errors?.vehicle && (
                        <Error error={errors.vehicle.message} />
                      )}
                    </Col>
                    <Col md={6}>
                      {/* Odometer */}
                      {vehicle && (
                        <Col>
                          <FormGroupLabel>{t("odometer")}</FormGroupLabel>
                          <FormItem
                            disabled={odoNotReqd}
                            showWarning={showHighOdoErr}
                            //tabIndex={0}
                          >
                            <FormField
                              name="odometer"
                              defaultValue=""
                              control={control}
                              component="input"
                              type="number"
                              onKeyDown={handleNegative}
                              onWheel={handleNumberInputOnWheelPreventChange}
                              rules={{
                                required: odoNotReqd
                                  ? false
                                  : t("required_err"),
                              }}
                              placeholder={t("odometer")}
                              style={formInputStyles}
                              disabled={odoNotReqd}
                              onChange={(e) => {
                                clearErrors("odometer");
                                setValue("odometer", e.target.value);
                                if (odoFetchedMsg) {
                                  setOdoFetchedMsg("");
                                }
                              }}
                              onKeyUp={(e) =>
                                handleHighOdoErr(
                                  e,
                                  lastOdoValue,
                                  setShowHighOdoErr
                                )
                              }
                            />
                            <UnitSpan>{odoUnit}</UnitSpan>
                            {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                          </FormItem>
                          {odoFetchedMsg && (
                            <HintText>{odoFetchedMsg}</HintText>
                          )}
                          {odoHint && <HintText>{odoHint}</HintText>}

                          {showHighOdoErr && (
                            <Error error={t("high_odo_err_msg")} />
                          )}

                          {errors?.odometer && (
                            <Error error={errors.odometer.message} />
                          )}
                          {/* {errors?.odometer ? (
                            <Error error={errors.odometer.message} />
                          ) : (
                            showHighOdoErr && (
                              <Error error={t("high_odo_err_msg")} />
                            )
                          )} */}
                        </Col>
                      )}
                    </Col>
                  </Row>
                  {vehicle && (
                    <>
                      <Row>
                        <Col md={6}>
                          <FormGroupLabel>{t("date_tv")}</FormGroupLabel>
                          <Controller
                            name="expenseDate"
                            //defaultValue={new Date()}
                            control={control}
                            render={({ field: { onChange, value, ref } }) => (
                              <DatePicker
                                maxDate={new Date()}
                                selected={value}
                                onChange={onChange}
                                customInput={
                                  <CustomInput
                                  //disabled={status == 1 || status == 2}
                                  />
                                }
                                placeholderText={t("date_tv")}
                                showMonthDropdown
                                showYearDropdown
                                id="datepicker"
                                locale={getDateFnsLocale()}
                                dateFormat={"P"}
                                //disabled={status == 1 || status == 2}
                              />
                            )}
                            rules={{
                              required: t("required_err"),
                            }}
                          />
                          {errors?.expenseDate && (
                            <Error error={errors.expenseDate.message} />
                          )}
                        </Col>
                        <Col md={6}>
                          <FormGroupLabel>{t("time_tv")}</FormGroupLabel>
                          <FormItem disabled={false}>
                            <FormField
                              defaultValue=""
                              name="expenseTime"
                              control={control}
                              component="input"
                              type="time"
                              //defaultValue={getInitialStartTime}
                              //placeholder={t("odometer")}
                              style={formInputStyles}
                              disabled={false}
                            />

                            {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                          </FormItem>

                          {errors?.expenseTime && (
                            <Error error={errors.expenseTime.message} />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormGroupLabel>{t("expense_by")}</FormGroupLabel>
                          <Controller
                            name="expenseBy"
                            control={control}
                            //errors={errors}
                            render={({ field: { onChange, value, ref } }) => (
                              <Select
                                options={userOptions}
                                //defaultValue={signedInUser}
                                //value={vehicles.find((c) => c.value === value)}
                                value={value}
                                onChange={onChange}
                                //onCreateOption={handleCreate}
                                //placeholder={t("vehicle_placeholder")}
                                styles={formStyles}
                                //isDisabled={status == 1 || status == 2}
                              />
                            )}
                            //rules={{ required: t("required_err") }}
                          />

                          {errors?.expenseBy && (
                            <Error error={errors.expenseBy.message} />
                          )}
                        </Col>
                        <Col md={6}>
                          {/* Service Station */}
                          {vehicle && (
                            <Col>
                              <FormGroupLabel>{t("vendor")}</FormGroupLabel>
                              <FormItem
                              //disabled={status == 2}
                              //tabIndex={0}
                              >
                                <FormField
                                  name="vendor"
                                  control={control}
                                  component="input"
                                  defaultValue=""
                                  //type="number"
                                  rules={{ required: false }}
                                  //errors={errors}
                                  // rules={{
                                  //   required:
                                  //     +serviceIntervalDuration || +serviceIntervalOdo
                                  //       ? false
                                  //       : t("odo_or_duration"),
                                  //   min: {
                                  //     value: 0,
                                  //     message: t("greater_than_zero_err"),
                                  //   },
                                  // }}
                                  //defaultValue=""
                                  //isAboveError={isAboveError}
                                  placeholder={t("vendor")}
                                  style={formInputStyles}
                                  //disabled={status == 2}
                                  //onKeyUp={handleSIOdoChange}
                                />

                                {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                              </FormItem>

                              {errors?.vendor && (
                                <Error error={errors.vendor.message} />
                              )}
                            </Col>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <FormGroupLabel>{t("expenses_title")}</FormGroupLabel>
                          <div style={{ backgroundColor: colorBg }}>
                            <Row className="px-3 py-2">
                              <Col md={5}>
                                <FormGroupLabel className="m-0">
                                  {t("task_name")}
                                </FormGroupLabel>
                              </Col>
                              <Col md={2}></Col>
                              <Col md={4}>
                                <FormGroupLabel className="m-0">
                                  {`${t("total")} (${currency})`}
                                </FormGroupLabel>
                              </Col>
                            </Row>
                            {expenseTasks?.map((task) => {
                              return (
                                <Row
                                  className="px-3 py-2 d-flex align-items-center"
                                  key={task.expense_task_id}
                                >
                                  <Col md={5}>
                                    <p>{task.expense_task_name}</p>
                                  </Col>
                                  <Col md={2}></Col>
                                  <Col md={4}>
                                    <CostInput
                                      type="number"
                                      //min="0"
                                      onKeyDown={(e) => handleNegative(e)}
                                      onWheel={
                                        handleNumberInputOnWheelPreventChange
                                      }
                                      //defaultValue={0}
                                      value={task.cost}
                                      onChange={(e) =>
                                        handleCostChange(
                                          e,
                                          task.expense_task_id
                                        )
                                      }
                                    />
                                  </Col>
                                  {/* <Col md={2}>
                                    <CostInput
                                      type="number"
                                      min="0"
                                      //value={task.laborCost}
                                      //defaultValue={0}
                                      value={task.laborCost}
                                      onKeyDown={(e) => handleNegative(e)}
                                      onChange={(e) =>
                                        handleLaborChange(
                                          e,
                                          task.expense_task_id
                                        )
                                      }
                                    />
                                  </Col> */}
                                  {/* <Col md={2}>
                                    <p>{task?.totalCost}</p>
                                  </Col> */}
                                  <Col md={1}>
                                    <ActionIconContainer
                                      style={{ top: 1 }}
                                      onClick={() =>
                                        handleExpenseTaskDelete(
                                          task.expense_task_id
                                        )
                                      }
                                    >
                                      <i className="fi fi-rr-cross-small" />
                                    </ActionIconContainer>
                                    {/* <ActionIcon
                                      className="fi fi-rr-cross-small"
                                      style={{ top: 3 }}
                                      onClick={() =>
                                        handleExpenseTaskDelete(
                                          task.expense_task_id
                                        )
                                      }
                                    ></ActionIcon> */}
                                  </Col>
                                </Row>
                              );
                            })}
                            <Row className="px-4 py-0">
                              {/* <AddIconContainer
                                type="button"
                                onClick={() => setShowAddExpenseModal(true)}
                                //className="px-3"
                                //style={{ fontSize: "14px" }}
                              >
                                <Icon className="fi fi-rr-plus"></Icon>
                                <span>Add</span>
                              </AddIconContainer> */}
                              <AddButton
                                handleClick={() => setShowAddExpenseModal(true)}
                              />
                            </Row>
                            <Row className="pt-2 pb-0 m-0">
                              <hr className="p-0 m-0" />
                            </Row>
                            <Row className="px-4 py-2">
                              <Col md={5}>
                                <p>{t("total")}</p>
                              </Col>
                              <Col md={2}>{/* <p>{partsCostTotal}</p> */}</Col>
                              <Col md={2}>{/* <p>{laborCostTotal}</p> */}</Col>
                              <Col md={2} className="p-0">
                                <CostElement>{total}</CostElement>
                              </Col>
                            </Row>
                          </div>
                          {showExpenseTasksError && (
                            <Error error={expenseTasksErrorMsg} />
                          )}
                        </Col>
                      </Row>

                      <Row className="py-3">
                        <Col
                          md={12}
                          className="d-flex justify-content-end align-items-end flex-column gap-3"
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            <FormGroupLabel htmlFor="tax" className="m-0 px-2">
                              {t("tax")}
                            </FormGroupLabel>
                            {/* <FormItem>
                              <input
                                id="tax"
                                name="tax"
                                type="number"
                                value={tax}
                                onKeyDown={(e) => handleNegative(e)}
                                onChange={(e) => setTax(e.target.value)}
                                style={{ ...formInputStyles, width: '100px' }}
                              />
                              <UnitSpan>USD</UnitSpan>
                            </FormItem> */}

                            <FormItem
                              style={{ width: "140px" }}
                              //disabled={status == 2}
                              //tabIndex={0}
                            >
                              <FormField
                                name="tax"
                                control={control}
                                component="input"
                                type="number"
                                //min={0}
                                onKeyDown={(e) => handleNegative(e)}
                                onWheel={handleNumberInputOnWheelPreventChange}
                                rules={{ required: false }}
                                defaultValue=""
                                //placeholder={t("odometer")}
                                style={{ ...formInputStyles }}
                                //disabled={status == 2}
                              />
                              <UnitSpan>{currency}</UnitSpan>
                            </FormItem>

                            {/* {errors?.tax && <Error error={errors.tax.message} />} */}
                          </div>
                          <div className="d-flex align-items-center justify-content-center">
                            <FormGroupLabel
                              htmlFor="discount"
                              className="m-0 px-2"
                            >
                              {t("discount")}
                            </FormGroupLabel>
                            <FormItem style={{ width: "140px" }}>
                              <FormField
                                name="discount"
                                control={control}
                                component="input"
                                type="number"
                                //min={0}
                                onKeyDown={(e) => handleNegative(e)}
                                onWheel={handleNumberInputOnWheelPreventChange}
                                rules={{
                                  required: false,
                                  max: {
                                    value: +total + +tax,
                                    message: t(
                                      "discount_greater_than_total_err_msg"
                                    ),
                                  },
                                }}
                                defaultValue=""
                                //placeholder={t("odometer")}
                                style={{ ...formInputStyles }}
                                //disabled={status == 2}
                              />
                              <UnitSpan>{currency}</UnitSpan>
                            </FormItem>
                          </div>
                          {errors?.discount && (
                            <Error error={errors.discount.message} />
                          )}
                          <div
                            className="d-flex align-items-center justify-content-center"
                            //style={{ width: "140px" }}
                          >
                            <FormGroupLabel className="m-0 px-2">
                              {t("grand_total")}
                            </FormGroupLabel>
                            <span style={{ fontWeight: 500, width: "140px" }}>
                              {`${grandTotal} ${currency}`}
                            </span>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          <FormGroupLabel>
                            {t("transaction_type")}
                          </FormGroupLabel>
                          <Controller
                            name="transactionType"
                            control={control}
                            // defaultValue={{
                            //   value: "Credit card",
                            //   label: t("credit_card_const"),
                            // }}
                            //errors={errors}
                            render={({ field: { onChange, value, ref } }) => (
                              <Select
                                options={transactionTypeOptions}
                                // defaultValue={{
                                //   value: "Credit card",
                                //   label: t("credit_card_const"),
                                // }}
                                //value={vehicles.find((c) => c.value === value)}
                                value={value}
                                //onChange={onChange}
                                onChange={(v) => {
                                  onChange(v);
                                  localStorage.setItem(
                                    "transactionType",
                                    v?.value
                                  );
                                }}
                                placeholder={t("transaction_type_placeholder")}
                                styles={formStyles}
                                //isDisabled={status == 1 || status == 2}
                              />
                            )}
                            //rules={{ required: t("required_err") }}
                          />

                          {errors?.transactionType && (
                            <Error error={errors.transactionType.message} />
                          )}
                        </Col>
                        <Col md={6}>
                          <FormGroupLabel>
                            {transactionType?.value === "Check"
                              ? t("check_number")
                              : t("card_number")}
                          </FormGroupLabel>
                          <FormItem
                            disabled={transactionType?.value === "Cash"}
                          >
                            <FormField
                              name="cardNo"
                              control={control}
                              component="input"
                              type="number"
                              defaultValue=""
                              onKeyDown={(e) => handleNegative(e)}
                              onWheel={handleNumberInputOnWheelPreventChange}
                              placeholder={
                                transactionType?.value === "Check"
                                  ? t("check_number")
                                  : t("last_4_digits")
                              }
                              style={formInputStyles}
                              disabled={transactionType?.value === "Cash"}
                            />

                            {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                          </FormItem>

                          {errors?.cardNo && (
                            <Error error={errors.cardNo.message} />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <FormGroupLabel>{t("notes_tv")}</FormGroupLabel>
                          {/* <FormItem disabled={false}>
                            <FormField
                              name="comments"
                              control={control}
                              component="input"
                              defaultValue=""
                              //defaultValue=""
                              placeholder={t("notes_tv")}
                              style={formInputStyles}
                              disabled={false}
                            />
                          </FormItem> */}

                          <Controller
                            name="comments"
                            control={control}
                            //defaultValue={users?.length > 0 ? users[0] : ""}
                            //errors={errors}
                            render={({ field: { onChange, value, ref } }) => (
                              <AutoResizeTextArea
                                ref={textAreaRef}
                                onChange={onChange}
                                value={value}
                                placeholder={t("notes_tv")}
                                rows={1}
                                //isDisabled={status == 1 || status == 2}
                              />
                            )}
                            //rules={{ required: t("required_err") }}
                          />

                          {errors?.comments && (
                            <Error error={errors.comments.message} />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <FormGroupLabel>
                            {t("images")}{" "}
                            {tag === "free-user" && (
                              <SubscribeTooltipIcon
                                message={t("subscribe_msg")}
                              />
                            )}
                          </FormGroupLabel>
                          <FileUploaderNew
                            disabled={false}
                            images={expenseImages}
                            setImages={setExpenseImages}
                            shouldDelete={true}
                          />
                          {/* <FileUploaderContainer>
                            {expenseImages?.map((img) => {
                              return (
                                <ImageWithZoom
                                  key={img.id}
                                  img={img}
                                  //handleDeleteImg={handleDeleteImg}
                                  images={expenseImages}
                                  setImages={setExpenseImages}
                                  shouldDelete={true}
                                />
                              );
                            })}

                            <FileUploader
                              images={expenseImages}
                              setImages={setExpenseImages}
                              //handleFileUpload={handleFileUpload}
                              //disabled={status == 2}
                            />

                            {false && (
                              <p
                                style={{
                                  textAlign: "center",
                                  width: "-webkit-fill-available",
                                  color: colorGray,
                                }}
                              >
                                No Images Uploaded
                              </p>
                            )}
                          </FileUploaderContainer> */}
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
                {/* <Col md={3}></Col> */}
                <Col>
                  {vehicle && (
                    <Row style={{ marginTop: "2.75rem" }}>
                      <Col>
                        <CheckBoxInput
                          type="checkbox"
                          id="odoNotReqd"
                          name="odoNotReqd"
                          value="odoNotReqd"
                          checked={odoNotReqd}
                          onChange={(e) => {
                            const { checked } = e.target;
                            if (checked) {
                              setValue("odometer", "");
                              if (showHighOdoErr) {
                                setShowHighOdoErr(false);
                              }
                              if (odoFetchedMsg) {
                                setOdoFetchedMsg("");
                              }
                            }
                            setOdoNotReqd((prev) => !prev);
                          }}
                          style={{ margin: "0 8px 0 0" }}
                        />
                        <Label htmlFor="odoNotReqd">
                          {t("odo_not_mandatory_label")}
                        </Label>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </FormContainer>
    </>
  );
};

export default ExpensesForm;

//styles

export const AddIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  font-weight: 500;
  color: ${colorGray};
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  &:hover {
    background: ${colorLightGray};
  }
`;

export const AddTaskModal = ({
  showModal,
  setShowModal,
  taskOptions,
  setTaskOptions,
  tasks,
  setTasks,
  existingFormInfo,
  route,
  mode,
  selectedVehicleId,
}) => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedTasks, setSelectedTasks] = useState([]);

  const newTaskInfo = useRef("");
  const [newTaskId, setNewTaskId] = useState("");
  const [showAddTaskFormPopup, setShowAddTaskFormPopup] = useState(false);

  const { currency } = useSelector((state) => state.units);

  //To add newly created task
  useEffect(() => {
    if (newTaskId) {
      setShowModal(true);
      (async () => {
        const apiData = {
          querystring: "getallexpensetask",
          expense_veh_id: selectedVehicleId,
        };
        const expenseTasksRes = await updateExpenseTaskOptions(apiData);
        setTaskOptions(expenseTasksRes);
      })();
    }
  }, [newTaskId]);

  useEffect(() => {
    if (newTaskId) {
      const newTask = taskOptions?.find((t) => t.expense_task_id === newTaskId);
      if (newTask && !tasks?.find((t) => t.expense_task_id === newTaskId)) {
        setTasks((prev) => [...prev, newTask]);
      }
    }
  }, [taskOptions]);

  useEffect(() => {
    if (showModal) {
      setSelectedTasks(tasks);
    }
  }, [showModal, tasks]);

  useEffect(() => {
    setSelectedTasks(tasks);
  }, [tasks]);

  const handleOK = () => {
    setTasks(selectedTasks);
    setShowModal(false);
  };

  const handleCreate = (value) => {
    newTaskInfo.current = { taskName: value, vehicleId: selectedVehicleId };
    setShowModal(false);
    setShowAddTaskFormPopup(true);
  };

  return (
    <>
      <ExpenseTasksFormPopup
        showPopup={showAddTaskFormPopup}
        setShowPopup={setShowAddTaskFormPopup}
        newTaskInfo={newTaskInfo.current}
        newTaskId={newTaskId}
        setNewTaskId={setNewTaskId}
      />
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body
          style={
            {
              //height: "-webkit-fill-available",
              //height: `${window.innerHeight - 100}px`,
            }
          }
        >
          <div className="d-flex justify-content-end mb-2">
            <ActionIconContainer onClick={() => setShowModal((prev) => !prev)}>
              <i className="fi fi-rr-cross-small" />
            </ActionIconContainer>
          </div>
          <div
            style={{
              height: `${window.innerHeight - 170}px`,
            }}
          >
            <CreatableSelect
              options={taskOptions}
              value={selectedTasks}
              onChange={setSelectedTasks}
              onCreateOption={handleCreate}
              placeholder={t("expense_task_placeholder")}
              styles={openDropdownStyles}
              defaultMenuIsOpen
              isMulti
              menuIsOpen
              isClearable
              isSearchable
              closeMenuOnSelect={false}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              components={{
                Option: MultiValueOption,
              }}
              //isDisabled={status == 1 || status == 2}
            />
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <AddFormButton onClick={handleOK}>{t("ok")}</AddFormButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const updateExpenseTaskOptions = async (apiData) => {
  const res = await postApi(apiData, "commonNew");
  if (res.success) {
    const { user_data } = res;
    const temp = [];
    user_data.forEach((item) => {
      temp.push({
        ...item,
        value: item.expense_task_id,
        label: item.expense_task_name,
        cost: "",
      });
    });
    return temp;
  }
};
