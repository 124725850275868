import React, { useEffect, useState, useRef } from "react";
import {
  AutoResizeTextArea,
  CheckBoxInput,
  FormContainer,
  FormGroupLabel,
  FormItem,
  HintText,
  Label,
  UnitSpan,
} from "../../shared/components/form/FormElements";
import FormHeader from "../../shared/components/form/FormHeader";
import { useTranslation } from "react-i18next";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { formInputStyles, formStyles } from "../../shared/constants/styles";
import Select from "react-select";
import Error from "@/shared/components/form/Error";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Card, CardBody } from "@/shared/components/Card";
import { useHistory, useLocation } from "react-router";
import FormField from "../../shared/components/form/FormField";
import DatePicker from "react-datepicker";
import { CustomInput } from "../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import {
  getDateFnsLocale,
  getDateWithTime,
  getDistanceFactorforOdo,
  getEditUsers,
  getInitialStartTime,
  getTimestamp,
  handleNegative,
  handleNumberInputOnWheelPreventChange,
  handleVehicleOnChange,
  isObjEmpty,
  odoValidation,
  uploadDocsToS3,
} from "../../shared/helpers";
import {
  colorBlue,
  colorLightGray,
  colorYellowNew,
  taskBgColor,
} from "../../utils/palette";
import styled from "styled-components";
import {
  ALBUM_BUCKET_NAME,
  BUCKET_REGION,
  IDENTITY_POOL_ID,
  NATEXT,
  s3Url,
} from "../../shared/constants";
import getUnixTime from "date-fns/getUnixTime";
import { getVehicles, post, postApi } from "../../api/getUser";
import { IssueStatus } from "./IssuesTable";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import format from "date-fns/format";
import {
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
  FilterButton,
} from "../../shared/components/TableElements";
import { useDispatch } from "react-redux";
import { addMsg } from "../../features/snackbar/snackbarSlice";
import { updateVehiclesList } from "../../features/vehicles/vehiclesSlice";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import intervalToDuration from "date-fns/intervalToDuration";
import useFetchOdoHint from "../../shared/custom-hooks/useFetchOdoHint";
import InfoPopup from "../../shared/components/InfoPopup";
import { useAuth } from "../../contexts/AuthContext";
import SubscribeTooltipIcon from "../../shared/components/SubscribeTooltipIcon";
import useFetchOdo from "../../shared/custom-hooks/useFetchOdo";
import FileUploaderNew from "../../shared/components/form/FileUploaderNew";
import ErrorPopup from "../../shared/components/ErrorPopup";
import useAutosizeTextArea from "../../shared/custom-hooks/useAutosizeTextArea";

const IssuesForm = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const { tag } = useAuth();

  const location = useLocation();
  const { orgId, userId } = useSelector((state) => state.user);
  //const { data, screen, type } = location?.state;
  const data = location?.state?.data;
  const screen = location?.state?.screen;
  const type = location?.state?.type;
  const mode = location?.state?.mode;
  const rowData = data?.rowData;

  //hook-form variables
  const vehicle = watch("vehicle");
  const odometer = watch("odometer");
  const issue = watch("issue");
  const priority = watch("priority");
  const issueDesc = watch("issueDesc");
  const reportedDate = watch("reportedDate");
  const reportedTime = watch("reportedTime");
  const reportedBy = watch("reportedBy");
  const comments = watch("comments");

  const textAreaRef = useRef(null);
  useAutosizeTextArea(textAreaRef.current, comments);

  const [timeToClose, setTimeToClose] = useState("");

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const vehicles = vehiclesList
    .slice(1, vehiclesList.length)
    .filter((v) => v.veh_active === 1);

  const userData = useSelector((state) => state.users.list); //Confirm if only technicians should be shown

  const { distance } = useSelector((state) => state.units);
  const prioritiesOptions = [
    {
      value: 1,
      label: t("low"),
    },
    {
      value: 2,
      label: t("med"),
    },
    {
      value: 3,
      label: t("high"),
    },
  ];

  const [issueImages, setIssueImages] = useState([]);

  const [status, setStatus] = useState("new");

  const [vehOutOfService, setVehOutOfService] = useState(false);

  const [closeIssueData, setCloseIssueData] = useState("");

  const [confirmClicked, setConfirmClicked] = useState("");
  const confirmed = useRef(null);

  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  //Loading States
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isPopupSaveLoading, setIsPopupSaveLoading] = useState(false);
  const [noButtonLoading, setNoButtonLoading] = useState(false);

  const { odoHint, odoUnit, lastOdoValue } = useFetchOdoHint({
    vehicle,
    date: reportedDate,
    time: reportedTime,
  });

  const [showHighOdoErr, setShowHighOdoErr] = useState(false);

  //For showing info popup during save
  const [vehicleUpdErrData, setVehicleUpdErrData] = useState({
    message: "",
    icon: { className: "fi fi-rr-triangle-warning", color: "red" },
  });
  const [vehicleUpdErr, setVehicleUpdErr] = useState(false);

  const [userOptions, setUserOptions] = useState([]);

  const [showAddIssueErr, setShowAddIssueErr] = useState(false);
  const [showErrPopup, setShowErrPopup] = useState(false);
  const [errMsg, setShowErrMsg] = useState("");

  const { odoFetchedMsg, setOdoFetchedMsg } = useFetchOdo({
    vehicle,
    type,
    setValue,
    lastOdoValue,
    setShowHighOdoErr,
  });

  //Initial Effect
  useEffect(() => {
    if (type !== "edit") {
      setValue("reportedDate", new Date());
      setValue("reportedTime", getInitialStartTime());
    }
  }, []);

  //Effect to set user options
  // useEffect(() => {
  //   (async () => {
  //     if (vehicle) {
  //       const apiData = {
  //         veh_id: vehicle?.vehicleId,
  //         querystring: "getvehicleoperators",
  //       };
  //       const res = await postApi(apiData, "commonNew");
  //       if (res.success) {
  //         const { user_data } = res;
  //         if (user_data?.length > 0) {
  //           let temp = [];
  //           for (let user of user_data) {
  //             let filteredUser = userData.find(
  //               (u) => u.user_id === user.user_id
  //             );
  //             temp.push(filteredUser);
  //           }
  //           setUserOptions(temp);
  //         }
  //       }
  //     }
  //   })();
  // }, [vehicle, userData]);

  //Effect to set default user
  useEffect(() => {
    if (userData?.length > 0 && userId && type !== "edit")
      setValue(
        "reportedBy",
        userData.find((u) => u.user_id === userId)
      );
  }, [userId, userData]);

  //Update vehicle out of service on change of vehicle
  useEffect(() => {
    setVehOutOfService(vehicle?.secondaryStatus);
  }, [vehicle, vehiclesList]);

  //Update all fields on edit
  useEffect(() => {
    if (type === "edit") {
      let selectedVehicle = vehicles.find(
        (v) => v.vehicleId === rowData?.veh_id
      );
      setValue("vehicle", selectedVehicle);
      const distanceFactor = getDistanceFactorforOdo(
        selectedVehicle?.primary_meter,
        distance
      );
      const odoUpd = rowData?.odo
        ? (rowData?.odo / distanceFactor)?.toFixed(2)
        : 0;
      setValue("odometer", odoUpd);
      //setValue("odometer", rowData?.odo);
      setValue("issue", rowData?.issue);
      setValue("issueDesc", rowData?.issue_desc);
      setValue("reportedDate", new Date(rowData?.reported_date * 1000));
      setValue(
        "reportedTime",
        format(new Date(rowData?.reported_date * 1000), "HH:mm")
      );
      // const selectedUser = userData?.find(
      //   (u) => u.user_id === rowData?.reported_by
      // );
      // setValue("reportedBy", selectedUser);

      //Set user and user options
      (async () => {
        const editUsers = await getEditUsers(
          rowData?.veh_id,
          rowData?.reported_by
        );
        if (editUsers?.length > 0) {
          const selectedUser = editUsers?.find(
            (u) => u.user_id === rowData?.reported_by
          );
          setValue("reportedBy", selectedUser);
          setUserOptions(editUsers);
        }
      })();
      setValue("comments", rowData?.comments);
      const selectedPriority = prioritiesOptions?.find(
        (p) => p.value == rowData?.priority
      );
      setValue("priority", selectedPriority);

      let imgNames = [];
      const editIssueimgName = rowData?.issue_img_name;
      if (editIssueimgName) {
        imgNames = editIssueimgName.split(":::");

        const issueImgs = [];
        imgNames.map((imgName, i) => {
          issueImgs.push({
            existing: true,
            imgName: imgName,
            id: Date.now() + i,
            url: `${s3Url}/org_${orgId}/issues/${rowData?.issue_id}/${imgName}`,
          });
        });
        setIssueImages(issueImgs);
      }

      setStatus(rowData?.status);

      if (rowData?.close_date) {
        const durationObj = intervalToDuration({
          start: new Date(rowData?.reported_date * 1000),
          end: new Date(rowData?.close_date * 1000),
        });

        const { days, hours, months, years, minutes, seconds } = durationObj;
        let temp = "";
        if (years > 0) {
          temp += `${years}y `;
        }
        if (months > 0) {
          temp += `${months}m `;
        }
        if (days > 0) {
          temp += `${days}d `;
        }
        if (hours > 0) {
          temp += `${hours}h `;
        }
        if (minutes > 0) {
          temp += `${minutes}m `;
        }
        if (seconds > 0) {
          temp += `${seconds}s `;
        }

        if (temp) {
          setTimeToClose(temp);
        }
      }

      //Handle Priority reqd error from close issue option in issues side panel
      if (mode === "from-issues-table-error") {
        if (!priority) {
          setError(
            "priority",
            { type: "required", message: t("required_err") },
            { shouldFocus: true }
          );
        }
      }
    }
  }, [userData, rowData]);

  const handleFileUpload = (files) => {
    const { length, ...filesObj } = files;
    let uploadedFilesArr = [];
    Object.keys(filesObj).map((key) => {
      uploadedFilesArr.push({
        id: Date.now() + key,
        file: filesObj[key],
        url: URL.createObjectURL(filesObj[key]),
      });
    });

    setIssueImages((prev) => {
      const temp = [...prev, ...uploadedFilesArr];
      return temp;
    });
  };

  const onSubmit = async (data) => {
    let reportedDateWithTime = new Date(reportedDate);
    let [hours, minutes] = reportedTime.split(":");
    if (hours < 10) {
      hours = hours[1];
    }

    if (minutes < 10) {
      minutes = minutes[1];
    }
    reportedDateWithTime = setHours(reportedDateWithTime, hours);
    reportedDateWithTime = setMinutes(reportedDateWithTime, minutes);
    let closeDate = "";
    if (!closeIssueData) {
      setIsSaveLoading(true);
    }

    const distanceFactor = getDistanceFactorforOdo(
      vehicle?.primary_meter,
      distance
    );
    let odoConverted = odometer * distanceFactor;

    //Odo Validation
    const odoCheckApiData = {
      odo: odoConverted,
      veh_id: vehicle.vehicleId,
      datetocheck: getDateWithTime(reportedDate, reportedTime),
      querystring: "get_around_dates_v3",
      check_from: "issues",
      check_id: type === "edit" ? rowData.issue_id : "",
    };

    const odoValidationRes = await odoValidation(
      "odometer",
      odoCheckApiData,
      setError,
      setIsSaveLoading,
      t,
      distanceFactor,
      odometer
    );

    if (!odoValidationRes) {
      if (showConfirmationPopup) {
        setShowConfirmationPopup(false);
      }
      return;
    }

    const issueId =
      type === "edit"
        ? rowData?.issue_id
        : `${vehicle.vehicleId}_${Date.now()}`;

    let imagesToS3 = [];

    let imagesString = "";

    issueImages?.forEach((img, i) => {
      if (img?.hasOwnProperty("existing")) {
        imagesString += `${img?.imgName}:::`;
      } else {
        if (img.file) {
          const extension = img?.file?.name.split(".").pop();
          const imgName = `${getTimestamp(userId, i)}.${extension}`;
          const key = `image_data/org_${orgId}/issues/${issueId}/${imgName}`;

          imagesString += `${imgName}:::`;

          imagesToS3.push({
            ...img,
            key: key,
          });
          // return {
          //   ...img,
          //   key: key,
          // };
        }
      }
    });
    imagesString = imagesString.slice(0, imagesString.length - 3);

    let inspectionId = "";
    let issueStatus = status;
    if (type === "edit") {
      if (rowData?.inspection_id) {
        inspectionId = rowData?.inspection_id;
      } else {
        inspectionId = "";
      }
    } else {
      inspectionId = "";
    }

    let closeIssueVehicleApiData = {};
    if (closeIssueData === "close-issue") {
      if (vehOutOfService) {
        if (confirmed.current === "yes") {
          setIsPopupSaveLoading(true);
          closeIssueVehicleApiData = {
            veh_id: vehicle?.vehicleId,
            secondary_status: 0,
            //veh_active: 1,
            operation: "update",
          };
          closeDate = getUnixTime(new Date());
          issueStatus = 2;
          //setStatus(2);
        } else {
          setNoButtonLoading(true);
          closeIssueVehicleApiData = {
            veh_id: vehicle?.vehicleId,
            secondary_status: 1,
            //veh_active: 1,
            operation: "update",
          };
          closeDate = getUnixTime(new Date());
          issueStatus = 2;
          //setStatus(2);
        }
      } else {
        if (confirmed.current === "yes") {
          setIsPopupSaveLoading(true);
          closeIssueVehicleApiData = {
            veh_id: vehicle?.vehicleId,
            secondary_status: 0,
            //veh_active: 1,
            operation: "update",
          };
          closeDate = getUnixTime(new Date());
          issueStatus = 2;
          //setStatus(2);
        } else {
          setShowConfirmationPopup(false);
          return;
        }
      }
      setCloseIssueData("");
    }

    let source =
      type === "edit"
        ? rowData?.source
        : t("added_via_website_by_user", { userName: reportedBy?.name });

    const apiData = {
      querystring: "addEditIssues",
      operation: type === "edit" ? "update" : "create",
      issue_id: issueId,
      org_id: orgId,
      veh_id: vehicle.vehicleId,
      odo: odometer ? odoConverted : "",
      issue: issue ? issue : "",
      issue_desc: issueDesc ? issueDesc : "",
      reported_by: reportedBy ? reportedBy.user_id : "",
      reported_date: getUnixTime(reportedDateWithTime),
      close_date: closeDate ? closeDate : "",
      source: source,
      status: type === "edit" ? issueStatus : 0,
      priority: priority.value,
      comments: comments ? comments : "",
      inspection_id: inspectionId,
      work_order_id: rowData?.work_order_id || "",
      service_id: rowData?.service_id || "",
      issue_img_name: imagesString,
      action: type === "edit" ? 2 : 1,
      //utc_offset: new Date().getTimezoneOffset(),
      formatted_date: format(reportedDateWithTime, "dd MMM yyyy"),
      //secondary_status: vehOutOfService ? 0 : 1,
    };

    const snackbarMsg =
      type === "edit"
        ? t("record_edited_msg", { recordName: t("issue") })
        : t("record_added_msg", { recordName: t("issue") });

    //If Close with Service, then send issue submit data to add service
    if (closeIssueData === "close-with-service-entry") {
      const data = {
        issueApiData: apiData,
        issueImagesToS3: imagesToS3,
        vehOutOfService,
      };
      history.push("/services/services/addnew", {
        type: "add",
        route: "/services/services/addnew",
        screen: "services",
        data,
        mode: "close-issue-with-service",
      });
      return;
    }

    const vehicleApiData = {
      veh_id: vehicle?.vehicleId,
      secondary_status: vehOutOfService ? 1 : 0,
      //veh_active: 1, //vehOutOfService ? 0 : 1,
      operation: "update",
    };

    let apiDataforVehicle = !isObjEmpty(closeIssueVehicleApiData)
      ? closeIssueVehicleApiData
      : vehicleApiData;

    //Calling Vehicle Api
    const res = await post("vehicle", apiDataforVehicle);

    //Vehicle call errors
    if (!res?.success) {
      const { code } = res;
      if (code == 1) {
        setVehicleUpdErrData((prev) => ({
          ...prev,
          message: t("vehicle_limit_reached_err"),
        }));
      } else if (code == 2) {
        setVehicleUpdErrData((prev) => ({
          ...prev,
          message: t("duplicate_entry_veh_name_err"),
        }));
      } else if (code == 3) {
        setVehicleUpdErrData((prev) => ({
          ...prev,
          message: t("duplicate_entry_veh_vin_err"),
        }));
      } else if (code == 4) {
        setVehicleUpdErrData((prev) => ({
          ...prev,
          message: t("duplicate_entry_veh_license_err"),
        }));
      } else if (code == 5) {
        setVehicleUpdErrData((prev) => ({
          ...prev,
          message: t("duplicate_entry_veh_err"),
        }));
      } else {
        setVehicleUpdErrData((prev) => ({
          ...prev,
          message: t("form_err_msg"),
        }));
      }
      setIsPopupSaveLoading(false);
      setNoButtonLoading(false);
      setVehicleUpdErr(true);
      setShowConfirmationPopup(false);
      return;
    }

    if (res.success === 1) {
      (async () => {
        const vehiclesRes = await getVehicles("getallvehicle", t);
        dispatch(updateVehiclesList(vehiclesRes));
      })();

      const response = await postApi(apiData, "issue");

      if (response.success === 1) {
        if (imagesToS3?.length > 0) {
          const docsUploaded = await uploadDocsToS3(imagesToS3);
          if (docsUploaded) {
            dispatch(addMsg(snackbarMsg));
            history.push("/issues", { fromCancel: false });
            setIsPopupSaveLoading(false);
            setNoButtonLoading(false);
          }
        } else {
          dispatch(addMsg(snackbarMsg));
          history.push("/issues", { fromCancel: false });
          setIsPopupSaveLoading(false);
          setNoButtonLoading(false);
        }
      } else {
        if (response?.action_value === "record_exists") {
          setShowErrMsg(t("dup_issue_err_msg"));
          //setShowAddIssueErr(true);
        } else {
          setShowErrMsg(t("form_err_msg")); //
        }
        setIsSaveLoading(false);
        setShowErrPopup(true);
        return;
      }
    }
  };

  return (
    <>
      {/* {showAddIssueErr && (
        <InfoPopup
          showPopup={showAddIssueErr}
          setShowPopup={setShowAddIssueErr}
          message={t("dup_issue_err_msg")}
          icon={{ className: "fi fi-rr-triangle-warning", color: "red" }}
          // actionIcon={{
          //   handler: () => setShowAddIssueErr(false),
          //   label: t("ok"),
          // }}
        />
      )} */}

      {showErrPopup && (
        <ErrorPopup
          showPopup={showErrPopup}
          setShowPopup={setShowErrPopup}
          message={errMsg}
        />
      )}

      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormHeader
          screen={screen}
          closeIssueData={closeIssueData}
          setCloseIssueData={setCloseIssueData}
          showConfirmationPopup={showConfirmationPopup}
          setShowConfirmationPopup={setShowConfirmationPopup}
          title={type && type === "edit" ? t("edit_issue") : t("add_issue")}
          //title={t("add_issue")}
          //saveButtonText={"Save Reminder"}
          handleCancel={() => {
            if (mode && mode === "from-fault-codes") {
              history.push("/faultcodes", { fromCancel: true });
            } else {
              history.push("/issues", { fromCancel: true });
            }
          }}
          disableSave={!vehicle}
          isSaveLoading={isSaveLoading}
          errors={errors}
          rowData={rowData}
          priority={priority}
          setError={setError}
          clearErrors={clearErrors}
        />
        <Container>
          {/* <Row> */}
          {/* <Col> */}
          <Card>
            <CardBody
              style={{
                height: `${window.innerHeight - 150}px`,
                overflow: "auto",
                //padding: "0px 12px",
              }}
            >
              <Row>
                <Col md={6}>
                  <Row>
                    <Col md={6}>
                      {/* Vehicle */}
                      <FormGroupLabel>{t("vehicle")}</FormGroupLabel>
                      <Controller
                        name="vehicle"
                        control={control}
                        defaultValue={""}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            options={vehicles}
                            //value={vehicles.find((c) => c.value === value)}
                            value={value}
                            //onChange={onChange}
                            onChange={(v) =>
                              handleVehicleOnChange(
                                v,
                                onChange,
                                "reportedBy",
                                setValue,
                                setUserOptions,
                                userId
                              )
                            }
                            //onCreateOption={handleCreate}
                            placeholder={t("vehicle_placeholder")}
                            styles={formStyles}
                            isDisabled={
                              status == 1 ||
                              status == 2 ||
                              mode === "from-fault-codes"
                            }
                          />
                        )}
                        rules={{ required: t("required_err") }}
                      />

                      {errors?.vehicle && (
                        <Error error={errors.vehicle.message} />
                      )}
                    </Col>
                    {/* Odometer */}
                    {vehicle && (
                      <Col>
                        <FormGroupLabel>{t("odometer")}</FormGroupLabel>
                        <FormItem
                          disabled={status == 2}
                          showWarning={showHighOdoErr}
                        >
                          <FormField
                            name="odometer"
                            control={control}
                            component="input"
                            type="number"
                            onKeyDown={handleNegative}
                            onWheel={handleNumberInputOnWheelPreventChange}
                            rules={{ required: t("required_err") }}
                            //errors={errors}
                            // rules={{
                            //   required:
                            //     +serviceIntervalDuration || +serviceIntervalOdo
                            //       ? false
                            //       : t("odo_or_duration"),
                            //   min: {
                            //     value: 0,
                            //     message: t("greater_than_zero_err"),
                            //   },
                            // }}
                            defaultValue=""
                            //isAboveError={isAboveError}
                            placeholder={t("odometer")}
                            style={formInputStyles}
                            disabled={status == 2}
                            onChange={(e) => {
                              clearErrors("odometer");
                              setValue("odometer", e.target.value);
                              if (odoFetchedMsg) {
                                setOdoFetchedMsg("");
                              }
                            }}
                            onKeyUp={(e) => {
                              const { value } = e.target;
                              if (
                                value &&
                                lastOdoValue &&
                                +value - +lastOdoValue > 1000
                              ) {
                                setShowHighOdoErr(true);
                              } else {
                                setShowHighOdoErr(false);
                              }
                            }}
                          />
                          <UnitSpan>{odoUnit}</UnitSpan>
                          {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                        </FormItem>
                        {odoFetchedMsg && <HintText>{odoFetchedMsg}</HintText>}
                        {odoHint && <HintText>{odoHint}</HintText>}

                        {showHighOdoErr && (
                          <Error error={t("high_odo_err_msg")} />
                        )}

                        {errors?.odometer && (
                          <Error error={errors.odometer.message} />
                        )}
                        {/* {errors?.odometer ? (
                        <Error error={errors.odometer.message} />
                      ) : (
                        showHighOdoErr && (
                          <Error error={t("high_odo_err_msg")} />
                        )
                      )} */}
                      </Col>
                    )}
                  </Row>
                  {vehicle && (
                    <>
                      <Row>
                        <Col md={6}>
                          <FormGroupLabel>{t("issue")}</FormGroupLabel>
                          <FormItem disabled={status == 2}>
                            <FormField
                              name="issue"
                              control={control}
                              component="input"
                              rules={{ required: t("required_err") }}
                              type="text"
                              defaultValue=""
                              placeholder={t("issue_placeholder")}
                              style={formInputStyles}
                              disabled={status == 2}
                            />

                            {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                          </FormItem>

                          {errors?.issue && (
                            <Error error={errors.issue.message} />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroupLabel>{t("issue_desc")}</FormGroupLabel>
                          <FormItem disabled={status == 2}>
                            <FormField
                              name="issueDesc"
                              control={control}
                              component="input"
                              type="text"
                              defaultValue=""
                              placeholder={t("issue_desc_placeholder")}
                              style={formInputStyles}
                              disabled={status == 2}
                            />

                            {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                          </FormItem>

                          {errors?.issueDesc && (
                            <Error error={errors.issueDesc.message} />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroupLabel>{t("reported_date")}</FormGroupLabel>
                          <Controller
                            name="reportedDate"
                            defaultValue={""}
                            control={control}
                            render={({ field: { onChange, value, ref } }) => (
                              <DatePicker
                                id="datepicker"
                                locale={getDateFnsLocale()}
                                dateFormat={"P"}
                                dropdownMode="scroll"
                                maxDate={new Date()}
                                showMonthDropdown
                                showYearDropdown
                                selected={value}
                                onChange={onChange}
                                customInput={
                                  <CustomInput
                                    disabled={status == 1 || status == 2}
                                  />
                                }
                                placeholderText={t("date_tv")}
                                disabled={status == 1 || status == 2}
                              />
                            )}
                            rules={{
                              required: t("required_err"),
                            }}
                          />
                          {errors?.reportedDate && (
                            <Error error={errors.reportedDate.message} />
                          )}
                        </Col>
                        <Col>
                          <FormGroupLabel>{t("reported_time")}</FormGroupLabel>
                          <FormItem disabled={status == 1 || status == 2}>
                            <FormField
                              name="reportedTime"
                              control={control}
                              component="input"
                              type="time"
                              defaultValue={""}
                              //defaultValue={getInitialStartTime}
                              //placeholder={t("odometer")}
                              style={formInputStyles}
                              disabled={status == 1 || status == 2}
                            />

                            {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                          </FormItem>

                          {errors?.reportedTime && (
                            <Error error={errors.reportedTime.message} />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {/* Reported By */}
                          <FormGroupLabel>{t("reported_by")}</FormGroupLabel>
                          <Controller
                            name="reportedBy"
                            control={control}
                            defaultValue={""}
                            //defaultValue={userData?.length > 0 ? userData[0] : ""}
                            //errors={errors}
                            render={({ field: { onChange, value, ref } }) => (
                              <Select
                                options={userOptions}
                                //value={vehicles.find((c) => c.value === value)}
                                value={value}
                                onChange={onChange}
                                //onCreateOption={handleCreate}
                                placeholder={t("user_placeholder")}
                                styles={formStyles}
                                isDisabled={status == 1 || status == 2}
                              />
                            )}
                            rules={{ required: t("required_err") }}
                          />

                          {errors?.reportedBy && (
                            <Error error={errors.reportedBy.message} />
                          )}
                        </Col>
                        <Col>
                          <FormGroupLabel className="mt-3">
                            {t("source")}
                          </FormGroupLabel>
                          <div
                            style={{
                              height: "34px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <p className="m-0 p-0">
                              {type === "edit"
                                ? rowData.source
                                : reportedBy
                                ? t("added_via_website_by_user", {
                                    userName: reportedBy?.name,
                                  })
                                : NATEXT}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroupLabel>{t("notes_tv")}</FormGroupLabel>
                          {/* <FormItem>
                            <FormField
                              name="comments"
                              control={control}
                              component="input"
                              type="text"
                              defaultValue={""}
                              placeholder={t("notes_tv")}
                              style={formInputStyles}
                            />
                          </FormItem> */}

                          <Controller
                            name="comments"
                            control={control}
                            //defaultValue={users?.length > 0 ? users[0] : ""}
                            //errors={errors}
                            render={({ field: { onChange, value, ref } }) => (
                              <AutoResizeTextArea
                                ref={textAreaRef}
                                onChange={onChange}
                                value={value}
                                placeholder={t("notes_tv")}
                                rows={1}
                                //isDisabled={status == 1 || status == 2}
                              />
                            )}
                            //rules={{ required: t("required_err") }}
                          />

                          {errors?.comments && (
                            <Error error={errors.comments.message} />
                          )}
                        </Col>
                      </Row>
                      <Row className="px-1 pt-2 pb-3">
                        <FormGroupLabel>
                          {t("images")}{" "}
                          {tag === "free-user" && (
                            <SubscribeTooltipIcon
                              message={t("subscribe_msg")}
                            />
                          )}
                        </FormGroupLabel>
                        <Col>
                          <FileUploaderNew
                            disabled={status == 2}
                            images={issueImages}
                            setImages={setIssueImages}
                            shouldDelete={status !== 2}
                          />
                          {/* <FileUploaderContainer>
                          {issueImages?.map((img) => {
                            return (
                              <ImageWithZoom
                                key={img.id}
                                img={img}
                                //handleDeleteImg={handleDeleteImg}
                                images={issueImages}
                                setImages={setIssueImages}
                                shouldDelete={status === 2 ? false : true}
                              />
                            );
                          })}
                          {status != 2 && (
                            <FileUploader
                              //handleFileUpload={handleFileUpload}
                              disabled={status == 2}
                              images={issueImages}
                              setImages={setIssueImages}
                            />
                          )}
                          {status == 2 && issueImages?.length === 0 && (
                            <p
                              style={{
                                textAlign: "center",
                                width: "-webkit-fill-available",
                                color: colorGray,
                              }}
                            >
                              No Images Uploaded
                            </p>
                          )}
                        </FileUploaderContainer> */}
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>

                <Col md={2}></Col>
                {vehicle && (
                  <Col md={4}>
                    <Row>
                      <Col style={{ marginBottom: "0.5rem" }}>
                        <FormGroupLabel className="pb-2">
                          {t("status")}
                        </FormGroupLabel>
                        {/* <IssueStatus>New</IssueStatus> */}
                        <IssueStatus status={status} />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        {/* Priority */}
                        <FormGroupLabel>{t("priority")}</FormGroupLabel>

                        <Controller
                          name="priority"
                          control={control}
                          defaultValue={""}
                          //errors={errors}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              options={prioritiesOptions}
                              //value={vehicles.find((c) => c.value === value)}
                              value={value}
                              onChange={onChange}
                              //onCreateOption={handleCreate}
                              placeholder={t("priority_placeholder")}
                              styles={formStyles}
                              isDisabled={status == 2}
                            />
                          )}
                          rules={{ required: t("required_err") }}
                        />

                        {errors?.priority && (
                          <Error error={errors.priority.message} />
                        )}
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2.75rem" }}>
                      <Col>
                        <CheckBoxInput
                          type="checkbox"
                          id="vehOutOfService"
                          name="vehOutOfService"
                          value="vehOutOfService"
                          checked={vehOutOfService}
                          onChange={() => setVehOutOfService((prev) => !prev)}
                          style={{ marginRight: "0.5rem" }}
                          disabled={status == 2}
                        />
                        <Label htmlFor="vehOutOfService" disabled={status == 2}>
                          {t("vehicle_out_of_service_label")}
                        </Label>
                        {/* <HintText style={{ marginLeft: "1.3rem" }}>
                        {t("vehicle_out_of_service_desc")}
                      </HintText> */}
                      </Col>
                    </Row>
                    {status == 2 && (
                      <Row style={{ marginTop: "1.25rem" }}>
                        <Col>
                          <FormGroupLabel>{t("Time to close")}</FormGroupLabel>
                          <p>{timeToClose}</p>
                        </Col>
                      </Row>
                    )}
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
          {/* </Col> */}
          {/* </Row> */}
          {showConfirmationPopup && (
            <ConfirmationPopup
              rowData={rowData}
              vehOutOfService={vehOutOfService}
              vehicle={vehicle}
              setVehOutOfService={setVehOutOfService}
              closeIssueData={closeIssueData}
              setCloseIssueData={setCloseIssueData}
              showConfirmationPopup={showConfirmationPopup}
              setShowConfirmationPopup={setShowConfirmationPopup}
              onSubmit={onSubmit}
              errors={errors}
              confirmClicked={confirmClicked}
              setConfirmClicked={setConfirmClicked}
              confirmed={confirmed}
              isPopupSaveLoading={isPopupSaveLoading}
              setIsPopupSaveLoading={setIsPopupSaveLoading}
              noButtonLoading={noButtonLoading}
              setNoButtonLoading={setNoButtonLoading}
            />
          )}
          {vehicleUpdErr && (
            <InfoPopup
              message={vehicleUpdErrData.message}
              icon={vehicleUpdErrData.icon}
              showPopup={vehicleUpdErr}
              setShowPopup={setVehicleUpdErr}
            />
          )}
        </Container>
      </FormContainer>
    </>
  );
};

export default IssuesForm;

//styles

export const FileUploaderContainer = styled.div`
  display: flex;
  padding: 0 0.5rem;
  overflow-y: auto;
  justify-content: start;
  align-items: center;
  gap: 8px;
  border: 1px solid ${colorLightGray};
  border-radius: 5px;
  background: #f2f4f7; //${taskBgColor};
  //width: 400px;
  height: 120px;
`;

export const uploadImgToS3 = async (img) => {
  try {
    const client = new S3Client({
      region: "us-east-2",
      credentials: fromCognitoIdentityPool({
        identityPoolId: IDENTITY_POOL_ID,
        client: new CognitoIdentityClient({ region: BUCKET_REGION }),
      }),
      apiVersion: "2006-03-01",
    });

    const statusCodeArr = [];
    const command = new PutObjectCommand({
      Bucket: ALBUM_BUCKET_NAME,
      Key: img.key,
      Body: img.file,
      ContentDisposition: "",
      ContentType: img.file.type,
      //ACL: "public-read",
    });
    const response = await client.send(command);
    const statusCode = response.$metadata.httpStatusCode;
    return statusCode;
    //statusCodeArr.push(statusCode);
  } catch (err) {
    return err;
  }
};

export const ConfirmationPopup = ({
  rowData,
  vehOutOfService,
  setVehOutOfService,
  vehicle,
  closeIssueData,
  setCloseIssueData,
  showConfirmationPopup,
  setShowConfirmationPopup,
  onSubmit,
  errors,
  confirmClicked,
  setConfirmClicked,
  confirmed,
  isPopupSaveLoading,
  setIsPopupSaveLoading,
  noButtonLoading,
  setNoButtonLoading,
}) => {
  const { t } = useTranslation("common");

  const modalContent = {
    iconClassName: vehOutOfService
      ? "fi fi-rr-triangle-warning"
      : "fi fi-rr-info",
    iconColor: vehOutOfService ? colorYellowNew : colorBlue,
    bodyText: vehOutOfService
      ? t("veh_back_in_service_popup_msg", { vehicleName: vehicle?.label })
      : t("close_issue_popup_msg"),
  };

  const handleYes = () => {
    setConfirmClicked("yes");
    confirmed.current = "yes";
    onSubmit();
  };

  const handleNo = () => {
    confirmed.current = "no";
    onSubmit();
  };

  return (
    <Modal
      show={showConfirmationPopup}
      onHide={() => setShowConfirmationPopup(!showConfirmationPopup)}
      centered
      size="sm"
    >
      <Modal.Body>
        <div
          className="d-flex justify-content-end"
          style={{ width: "-webkit-fill-available" }}
        >
          {/* <ActionIcon
            className="fi fi-rr-cross-small"
            onClick={() => setShowConfirmationPopup(false)}
          ></ActionIcon> */}
          <ActionIconContainer
            onClick={() => setShowConfirmationPopup(false)}
            id="close-issue-popup-icon-container"
          >
            <i className="fi fi-rr-cross-small" id="close-issue-popup-icon" />
          </ActionIconContainer>
        </div>
        <div className="d-flex gap-2 p-2">
          <DisplayIcon
            className={modalContent.iconClassName}
            color={modalContent.iconColor}
          />

          <p className="d-flex justify-content-center align-items-center">
            {/* <span> */}
            {modalContent.bodyText}
            {/* </span> */}
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center p-2 gap-3">
          <FilterButton onClick={handleNo} type="submit">
            {noButtonLoading ? <CustomLoader /> : t("no")}
          </FilterButton>
          <AddFormButton onClick={handleYes} type="submit">
            {isPopupSaveLoading ? <CustomLoader /> : t("yes")}
          </AddFormButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const DisplayIcon = styled.i`
  font-size: 24px;
  color: ${(p) => p.color};
  width: 24px;
  height: 24px;
`;
