import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select, { createFilter } from "react-select";
import styled from "styled-components";
import { read, utils } from "xlsx";
import { formStyles } from "../../constants/styles";
import { Desc } from "../../../components/Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import {
  colorLightBlue,
  colorLightGray,
  colorTextNew,
  scrollbarStyles,
} from "../../../utils/palette";
import { getImportData } from "./importData";
import { HintText } from "../form/FormElements";
import { useSelector } from "react-redux";
import { postApi } from "../../../api/getUser";

const ImportColMappingComponent = ({
  importFile,
  setImportFile,
  errMsg,
  setErrMsg,
  selectValues,
  setSelectValues,
  // dateFormat,
  // setDateFormat,
  screenName,
  setVehicleLimitErrMsg,
  setDisableImport,
}) => {
  const { t } = useTranslation("common");
  const { currency } = useSelector((state) => state.units);

  const dateFormatOptions = [
    { label: "26/09/2008", value: "DD-MM-YYYY" },
    // { label: "26/2008/09", value: "DD-YYYY-MM" },
    { label: "09/26/2008", value: "MM-DD-YYYY" },
    // { label: "09/2008/26", value: "MM-YYYY-DD" },
    { label: "2008/09/26", value: "YYYY-MM-DD" },
    { label: "2008/26/09", value: "YYYY-DD-MM" },
    { label: "26/09/2008 13:23:00", value: "DD-MM-YYYY HH:mm:ss" },
    { label: "09/26/2008 13:23:00", value: "MM-DD-YYYY HH:mm:ss" },
    { label: "2008/09/26 13:23:00", value: "YYYY-MM-DD HH:mm:ss" },
    { label: "2008/26/09 13:23:00", value: "YYYY-DD-MM HH:mm:ss" },
  ];

  function detectDateFormat(input) {
    const dateFormatPatterns = {
      "DD-MM-YYYY": /^\d{2}[-\/]\d{2}[-\/]\d{4}$/,
      "MM-DD-YYYY": /^\d{2}[-\/]\d{2}[-\/]\d{4}$/,
      "YYYY-MM-DD": /^\d{4}[-\/]\d{2}[-\/]\d{2}$/,
      "YYYY-DD-MM": /^\d{4}[-\/]\d{2}[-\/]\d{2}$/,
      "DD-MM-YYYY HH:mm:ss":
        /^\d{2}[-\/]\d{2}[-\/]\d{4} \d{2}[.:]\d{2}[.:]\d{2}$/,
      "MM-DD-YYYY HH:mm:ss":
        /^\d{2}[-\/]\d{2}[-\/]\d{4} \d{2}[.:]\d{2}[.:]\d{2}$/,
      "YYYY-MM-DD HH:mm:ss":
        /^\d{4}[-\/]\d{2}[-\/]\d{2} \d{2}[.:]\d{2}[.:]\d{2}$/,
      "YYYY-DD-MM HH:mm:ss":
        /^\d{4}[-\/]\d{2}[-\/]\d{2} \d{2}[.:]\d{2}[.:]\d{2}$/,
    };
    for (let option of dateFormatOptions) {
      const pattern = dateFormatPatterns[option.value];
      if (pattern && pattern.test(input)) {
        let datePart,
          timePart = "";
        if (input) {
          [datePart, timePart] = input.split(" ");
          if (datePart) {
            datePart = datePart?.toString()?.replace("-", "/")?.trim();
          }
          if (timePart) {
            timePart = timePart?.toString()?.trim();
          }
        }

        if (
          option.value.includes("DD-MM-YYYY") ||
          option.value.includes("MM-DD-YYYY")
        ) {
          const [part1, part2] = datePart?.split("/").map(Number);
          if (part1 > 12) {
            return timePart ? "DD-MM-YYYY HH:mm:ss" : "DD-MM-YYYY";
          } else if (part2 > 12) {
            return timePart ? "MM-DD-YYYY HH:mm:ss" : "MM-DD-YYYY";
          }
          // If both parts are <= 12, check country(currency since country not available
          const mdCurrencies = ["USD"];
          if (mdCurrencies.includes(currency)) {
            return timePart ? "MM-DD-YYYY HH:mm:ss" : "MM-DD-YYYY";
          }
          return timePart ? "DD-MM-YYYY HH:mm:ss" : "DD-MM-YYYY";
        }

        if (
          option.value.includes("YYYY-MM-DD") ||
          option.value.includes("YYYY-DD-MM")
        ) {
          const [part1, part2, part3] = datePart?.split("/").map(Number);
          if (part2 > 12) {
            return timePart ? "YYYY-DD-MM HH:mm:ss" : "YYYY-DD-MM";
          } else if (part2 > 12) {
            return timePart ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD";
          }
          // If both parts are <= 12, set default "YYYY-MM-DD"
          return timePart ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD";
        }
        return option.value; // Return the matching format
        // }
      }
    }
    return null; // Return null if no match
  }

  const { options: importOptions } = getImportData(screenName);

  useEffect(() => {
    if (importFile?.columns?.length > 0) {
      console.log("importFile", importFile);

      //Check if no of vehicle purchased > no of vehicles to import
      if (screenName === "vehicles") {
        (async () => {
          const subRes = await postApi(
            {
              querystring: "get_subscription_status",
            },
            "commonNew"
          );
          if (subRes.success) {
            const { subscription_data } = subRes;
            const { no_of_vehicles, vehiclecount, product } = subscription_data;

            let noOfVehicles = no_of_vehicles;
            if (product == 1) {
              noOfVehicles = 30;
            }
            if (product == 2) {
              noOfVehicles = 100;
            }
            if (product == 3) {
              noOfVehicles = 300;
            }

            const vehiclesToImport = importFile?.rows?.length || 0;
            const totalVehicles = +vehiclesToImport + +vehiclecount;
            if (totalVehicles > noOfVehicles) {
              setVehicleLimitErrMsg(
                t("veh_import_limit_reached_msg", {
                  count: noOfVehicles,
                  vehiclesToImport: totalVehicles,
                })
              );
              setDisableImport(true);
            }
          }
        })();
      }

      let tempSelectKeys = [];
      let selectValues = {};
      //let dateFormat = {};
      importFile?.columns.forEach((column) => {
        selectValues[column.colIndex] = {
          index: 999,
          value: "skip",
          label: t("not_mapped"),
          colName: column.colName,
          checked: false,
          possibleValues: [],
        };

        for (let option of importOptions) {
          if (
            option.possibleValues.includes(column.colName.toLocaleLowerCase())
          ) {
            if (!tempSelectKeys.includes(option.value)) {
              if (!option?.isMultiple) {
                //If option has isMultiple flag(eg. service tasks in services, then it can be selected multiple times)
                tempSelectKeys.push(option.value);
              }

              selectValues[column.colIndex] = {
                ...option,
                index: column.colIndex,
                colName: column.colName,
                checked: true,
              };

              if (option.type === "date") {
                console.log("option", option);
                let detectedDateFormat = null;
                let dateFormatOption = null;
                if (importFile?.rows?.length > 0) {
                  const dateVal = importFile.rows[0][column.colIndex];
                  if (dateVal) {
                    detectedDateFormat = detectDateFormat(dateVal);
                    if (detectedDateFormat) {
                      dateFormatOption = dateFormatOptions.find(
                        (o) => o.value === detectedDateFormat
                      );
                    }
                  }
                }
                //dateFormat[option.value] = dateFormatOption;
                selectValues[column.colIndex] = {
                  ...selectValues[column.colIndex],
                  dateFormat: dateFormatOption,
                };
              }
            }
          }
        }
      });
      setSelectValues(selectValues);
      // setDateFormat(dateFormat);
    }
  }, [currency]);

  //Effect to set date format on change of select values
  // useEffect(() => {
  //   // setDateFormat((prev) => {
  //   //   let temp = { ...prev };
  //   //   const selectValuesOptions = Object.values(selectValues);
  //   //   console.log(selectValuesOptions.filter((o) => o?.type === "date"));
  //   //   selectValuesOptions
  //   //     .filter((o) => o?.type === "date")
  //   //     .forEach((option) => {
  //   //       if (!temp?.hasOwnProperty(option.value)) {
  //   //         temp[option.value] = null;
  //   //       }
  //   //     });
  //   //   return temp;
  //   // });
  //   let temp = {};
  //   const selectValuesOptions = Object.values(selectValues);
  //   //console.log(selectValuesOptions.filter((o) => o?.type === "date"));
  //   selectValuesOptions
  //     .filter((o) => o?.type === "date")
  //     .forEach((option) => {
  //       if (dateFormat?.hasOwnProperty(option.value)) {
  //         temp[option.value] = dateFormat[option.value];
  //       } else {
  //         temp[option.value] = null;
  //       }
  //     });
  //   console.log("temp", temp);
  //   setDateFormat(temp);
  // }, [selectValues]);

  // console.log("dateFormat", dateFormat);
  //console.log("selectValues", selectValues);

  const handleSelectChange = (data, col) => {
    //Each time date is selected in dropdown, clear the corresponding date format
    // if (data.type === "date") {
    //   setDateFormat((prev) => ({ ...prev, [data.value]: null }));
    // }
    //console.log(data);
    setSelectValues((prev) => {
      const temp = { ...prev };
      const selectEntries = Object.entries(temp);
      //Retain the same check value that the previous select had
      const checked = temp[col.colIndex]?.checked;
      if (
        Object.values(temp)
          .map((o) => o.value)
          .filter((v) => v !== "skip")
          .includes(data.value) &&
        !data?.isMultiple
      ) {
        const prevSelected = selectEntries.find(
          (arr) => arr[1].value === data.value
        );

        const [prevSelectedIndex, prevSelectedValue] = prevSelected;

        return {
          ...prev,
          [prevSelectedIndex]: {
            index: 999,
            value: "skip",
            label: t("not_mapped"),
            colName: prevSelectedValue.colName,
            checked: prevSelectedValue.checked,
            possibleValues: [],
          },
          [col.colIndex]: {
            ...data,
            index: col.colIndex,
            colName: col.colName,
            checked: true, //checked,
          },
        };

        //setErrMsg(`${data.label} is already mapped`);
        //return prev;
      } else {
        //setErrMsg("");

        //Retain the same check value that the previous select had
        const checked = temp[col.colIndex]?.checked;
        if (data.value === "skip") {
          return {
            ...prev,
            [col.colIndex]: {
              ...data,
              index: 999,
              colName: col.colName,
              checked: false, //checked,
            },
          };
        } else {
          return {
            ...prev,
            [col.colIndex]: {
              ...data,
              index: col.colIndex,
              colName: col.colName,
              checked: true, //checked,
            },
          };
        }
      }
    });
  };

  const handleDateFormatChange = (data, col) => {
    setErrMsg("");
    //console.log(selectValues[col.colIndex]);
    const optionValue = selectValues[col.colIndex]?.value;
    //setDateFormat((prev) => ({ ...prev, [optionValue]: data }));
    setSelectValues((prev) => {
      let temp = { ...prev };
      prev[col.colIndex].dateFormat = data;
      return temp;
    });
  };

  const handleCheckboxChange = (e, col) => {
    //console.log(e.target.checked, col);
    setSelectValues((prev) => ({
      ...prev,
      [col.colIndex]: {
        ...selectValues[col.colIndex],
        checked: e.target.checked,
      },
    }));
  };

  return (
    <Table className="pb-0 mb-0">
      <thead
        style={{ position: "sticky", top: 0, zIndex: 10, background: "white" }}
      >
        <tr>
          {/* <th style={{ width: "5%" }}>#</th> */}
          <th style={{ width: "15%" }}>{t("csv_column")}</th>
          <th style={{ width: "35%", textAlign: "center" }}>
            {t("include_column")}
          </th>
          <th style={{ width: "50%" }}>{t("simply_fleet_field")}</th>
        </tr>
      </thead>
      <tbody>
        {importFile?.columns.map((col, i) => {
          return (
            <tr key={i}>
              <TD>
                <div
                //className="d-flex flex-column justify-content-center align-items-center"
                >
                  <p>{col.colName}</p>
                  <Desc>{importFile?.firstRow[i]}</Desc>
                </div>
              </TD>
              <TD>
                <div className="d-flex justify-content-center">
                  <input
                    type="checkbox"
                    style={{ marginTop: "2px" }}
                    checked={selectValues[col.colIndex]?.checked || false}
                    onChange={(e) => handleCheckboxChange(e, col)}
                  />
                </div>
              </TD>
              <TD>
                {/* <div className="d-flex gap-2">
                  <Select
                    value={selectValues[col]}
                    options={importOptions}
                    filterOption={createFilter({
                      ignoreAccents: false,
                    })}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        width: "180px",
                      }),
                    }}
                  />
                </div> */}
                {selectValues[col.colIndex]?.type === "date" ? (
                  <div
                    className="d-flex gap-2"
                    style={{ width: "-webkit-fill-available" }}
                  >
                    <Select
                      value={selectValues[col.colIndex]}
                      options={importOptions}
                      onChange={(data) => handleSelectChange(data, col)}
                      filterOption={createFilter({
                        ignoreAccents: false,
                      })}
                      // styles={{
                      //   ...formStyles,
                      //   container: (baseStyles, state) => ({
                      //     ...baseStyles,
                      //     width: "-webkit-fill-available",
                      //   }),
                      // }}
                      styles={{
                        ...formStyles,
                        control: (baseStyles, state) => {
                          return {
                            ...baseStyles,
                            padding: "0px",
                            margin: "0px",
                            cursor: "pointer",
                            color: colorTextNew,
                            minHeight: "34px",
                            boxShadow: state.isFocused ? null : null,
                            borderColor: state.isFocused
                              ? colorLightBlue
                              : selectValues[col.colIndex]?.checked &&
                                selectValues[col.colIndex]?.value === "skip"
                              ? "red"
                              : colorLightGray,
                          };
                        },
                        singleValue: (baseStyles, state) => ({
                          ...baseStyles,
                          fontWeight: 400,
                          paddingBottom: "2px",
                          color:
                            !selectValues[col.colIndex]?.checked &&
                            selectValues[col.colIndex]?.value !== "skip"
                              ? colorLightGray
                              : colorTextNew,
                        }),
                        container: (baseStyles, state) => ({
                          ...baseStyles,
                          minHeight: "34px",
                          width: "-webkit-fill-available",
                        }),
                      }}
                      defaultValue={importOptions[0]}
                    />
                    <Select
                      //value={dateFormat[selectValues[col.colIndex]]?.value}
                      value={selectValues[col.colIndex]?.dateFormat}
                      options={dateFormatOptions}
                      filterOption={createFilter({
                        ignoreAccents: false,
                      })}
                      placeholder={t("select_date_format")}
                      onChange={(data) => handleDateFormatChange(data, col)}
                      styles={{
                        ...formStyles,
                        container: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "-webkit-fill-available",
                        }),
                      }}
                      // styles={{
                      //   container: (baseStyles, state) => ({
                      //     ...baseStyles,
                      //     width: "-webkit-fill-available",
                      //   }),
                      // }}
                    />
                  </div>
                ) : (
                  <Select
                    value={selectValues[col.colIndex]}
                    onChange={(data) => handleSelectChange(data, col)}
                    options={importOptions}
                    filterOption={createFilter({
                      ignoreAccents: false,
                    })}
                    // styles={{
                    //   control: (baseStyles, state) => ({
                    //     ...baseStyles,
                    //     width: "-webkit-fill-available",
                    //   }),
                    // }}
                    styles={{
                      ...formStyles,
                      control: (baseStyles, state) => {
                        return {
                          ...baseStyles,
                          padding: "0px",
                          margin: "0px",
                          cursor: "pointer",
                          color: colorTextNew,
                          minHeight: "34px",
                          boxShadow: state.isFocused ? null : null,
                          borderColor: state.isFocused
                            ? colorLightBlue
                            : selectValues[col.colIndex]?.checked &&
                              selectValues[col.colIndex]?.value === "skip"
                            ? "red"
                            : colorLightGray,
                        };
                      },
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        fontWeight: 400,
                        paddingBottom: "2px",
                        color: !selectValues[col.colIndex]?.checked
                          ? //&& selectValues[col.colIndex]?.value !== "skip"
                            colorLightGray
                          : colorTextNew,
                      }),
                    }}
                    defaultValue={importOptions[0]}
                  />
                )}
                {selectValues[col.colIndex]?.value === "group_id" && (
                  <HintText>{t("ensure_groups_exist_in_system")}</HintText>
                )}
              </TD>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default ImportColMappingComponent;

//styles
const TD = styled.td`
  vertical-align: middle;
`;

// const TBODY = styled.tbody`
//   height: ${window.innerHeight - 400}px;
//   overflow: auto;
//   ${scrollbarStyles}
// `;
