import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ActionIcon, Icon, ActionIconContainer } from "./TableElements";

const Snackbar = ({ setShowSnackbar, message, snackbarType }) => {
  const { msg, type } = useSelector((state) => state.snackbar);

  let iconClassName = "fi fi-rs-check-circle";
  let iconColor = "green";

  let messageType = snackbarType ? snackbarType : type;

  if (messageType === 2) {
    iconClassName = "fi fi-rr-cross-circle";
    iconColor = "red";
  }

  return (
    <>
      {(msg || message) && (
        <SnackbarContainer $success={messageType === 1}>
          {/* <Row> */}
          <Col
            sm={1}
            //style={{ background: "green" }}
            className="d-flex align-items-center justify-content-center p-2"
          >
            <Icon
              className={iconClassName || "fi fi-rs-check-circle"}
              style={{ color: iconColor || "green", fontSize: "24px" }}
            ></Icon>
          </Col>
          <Col
            sm={10}
            //style={{ padding: "0.75rem" }}
            className="d-flex align-items-center justify-content-start p-2"
          >
            <p
            //style={{ whiteSpace: "nowrap" }}
            >
              {message || msg}
            </p>
          </Col>
          <Col
            sm={1}
            className="d-flex align-items-center justify-content-center p-0"
          >
            <ActionIconContainer
              onClick={() => setShowSnackbar(false)}
              //className="d-flex align-items-center justify-content-center p-2"
            >
              <i className="fi fi-rr-cross-small" />
            </ActionIconContainer>
          </Col>
          {/* </Row> */}
        </SnackbarContainer>
      )}
    </>
  );
};

export default Snackbar;

//styles

export const SnackbarContainer = styled(Row)`
  width: 420px !important;
  position: absolute;
  padding: 0 0.5rem;
  width: auto;
  bottom: 5%;
  right: 60%;
  border: 1px solid;
  border-color: ${(p) => (p.$success ? "green" : "red")};
  border-radius: 5px;
  background: white;
  display: flex;
  //justify-content: center;
  align-items: center;
  z-index: 9999;
`;
