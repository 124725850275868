import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  ScrollableDiv,
  Table,
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
} from "../../../../shared/components/TableElements";
import styled from "styled-components";
import {
  CheckBoxInput,
  HintText,
} from "../../../../shared/components/form/FormElements";
import {
  PaginationFormOption,
  PaginationFormSelect,
  PaginationInfo,
  PaginationSelectWrap,
  PaginationWrap,
} from "../../../../shared/components/table/components/ReactTablePagination";
import { Modal, Pagination } from "react-bootstrap";
import { scrollbarStyles } from "../../../../utils/palette";
import { NATEXT, providerNameMapping } from "../../../../shared/constants";
import { useAuth } from "../../../../contexts/AuthContext";
import { Icon } from "./ImportVehiclesPopup";
import { post, postApi } from "../../../../api/getUser";
import { useDispatch } from "react-redux";
import { addSnackbarData } from "../../../../features/snackbar/snackbarSlice";
import InfoPopup from "../../../../shared/components/InfoPopup";

const ImportNewVehiclesPopup = ({
  showPopup,
  setShowPopup,
  vehicles,
  setVehicles,
}) => {
  const { t } = useTranslation("common");
  const { setReload, integrationsData } = useAuth();
  const dispatch = useDispatch();

  const [selectAll, setSelectAll] = useState(true);
  const [importBtnLoading, setImportBtnLoading] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [showErrPopup, setShowErrPopup] = useState(false);

  const manualPageSize = [10, 25, 50, 100];

  const [pageSize, setPageSize] = useState(10);
  const noOfPages = Math.ceil(vehicles?.length / pageSize);

  const [pageIndex, setPageIndex] = useState(0);
  const pageIndexArr = [...Array(noOfPages).keys()];
  const totalRecords = vehicles?.length;

  const colMapping = {
    3: [
      { key: "vehicle_name", label: t("name") },
      { key: "make", label: t("make") },
      { key: "model", label: t("model") },
      { key: "license_no", label: t("license") },
      { key: "vin_no", label: t("vin") },
    ],
  };

  const provider = integrationsData[0]?.provider;
  const providerName = providerNameMapping[provider];
  const cols = colMapping[3];

  useEffect(() => {
    if (vehicles?.length > 0) {
      setVehicles((prev) => {
        let temp = [...prev];
        let result = temp.map((v) => ({ ...v, isChecked: selectAll }));
        return result;
      });
    }
  }, [selectAll]);

  const handleSelectAllChange = () => {
    setSelectAll((prev) => !prev);
  };

  const handleSelectChange = (vehicle) => {
    setVehicles((prev) => {
      let temp = [...prev];
      let result = temp.map((v) => {
        if (v.veh_id === vehicle.veh_id) {
          return { ...v, isChecked: !v.isChecked };
        } else {
          return v;
        }
      });
      if (result.every((d) => d.isChecked)) {
        setSelectAll(true);
      }
      if (result.every((d) => !d.isChecked)) {
        setSelectAll(false);
      }
      return result;
    });
  };

  const handleImportAssets = async () => {
    setImportBtnLoading(true);
    const selectedVehicles = vehicles
      .filter((v) => v.isChecked)
      .map((veh) => {
        const { isChecked, ...rest } = veh;
        return { ...rest };
      });

    //Check if no of vehicles is within subscription count limit
    const subRes = await postApi(
      {
        querystring: "get_subscription_status",
      },
      "commonNew"
    );
    if (subRes.success) {
      const { subscription_data } = subRes;
      const { no_of_vehicles, vehiclecount, product } = subscription_data;

      let noOfVehicles = no_of_vehicles;
      if (product == 1) {
        noOfVehicles = 30;
      }
      if (product == 2) {
        noOfVehicles = 100;
      }
      if (product == 3) {
        noOfVehicles = 300;
      }

      const vehiclesToImport = selectedVehicles?.length || 0;
      const totalVehicles = +vehiclesToImport + +vehiclecount;
      if (totalVehicles > noOfVehicles) {
        setErrMsg(
          t("veh_import_limit_reached_msg", {
            count: noOfVehicles,
            vehiclesToImport: totalVehicles,
          })
        );
        setShowErrPopup(true);
        setImportBtnLoading(false);
        return;
      }
    }

    console.log("selectedVehicles", selectedVehicles);
    const apiData = {
      querystring: "add_telematics_vehicles",
      data: selectedVehicles,
    };
    const res = await post("integrations", apiData);
    setImportBtnLoading(false);
    if (res.success) {
      dispatch(
        addSnackbarData({
          msg: t("count_new_vehicles_fetched_msg", {
            count: selectedVehicles.length,
            providerName,
          }),
          type: 1,
        })
      );
    } else {
      dispatch(
        addSnackbarData({
          msg: t("listing_err_msg"),
          type: 2,
        })
      );
    }
    setReload("vehicles");
    setShowPopup(false);
  };

  return (
    <>
      {showErrPopup && (
        <InfoPopup
          showPopup={showErrPopup}
          setShowPopup={setShowErrPopup}
          message={errMsg}
          icon={{ className: "fi fi-rr-cross-circle", color: "red" }}
        />
      )}
      <Modal show={showPopup} size="lg">
        <Modal.Header>
          {/* <h5>
          {step === 6
            ? t("configure_provider", { providerName: "Streamline" })
            : t("integrate_with_provider", { providerName: "Streamline" })}
        </h5> */}
          <h5>{t("integrate_with_provider", { providerName })}</h5>

          <div className="d-flex justify-content-end">
            <ActionIconContainer onClick={() => setShowPopup(false)}>
              <i className="fi fi-rr-cross-small" />
            </ActionIconContainer>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div
            className="d-flex justify-content-center"
            style={{ height: window.innerHeight - 220 }}
          >
            <div className="w-100">
              <p>{t("assets_found_in_provider_msg", { providerName })}</p>
              <HintText>{t("existing_veh_not_imported_note")}</HintText>
              <ScrollableDiv $height={`${window.innerHeight - 296}px`}>
                <Table bordered className="table-hover">
                  <thead>
                    <tr>
                      <th>
                        <CheckBoxInput
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAllChange}
                        />
                      </th>
                      {cols.map((col) => (
                        <th key={col.key} className="px-2">
                          {col.label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {vehicles
                      ?.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
                      ?.map((v) => (
                        <tr
                          key={v.veh_id}
                          onClick={() => handleSelectChange(v)}
                        >
                          <td>
                            <CheckBoxInput
                              type="checkbox"
                              checked={v.isChecked}
                              readOnly
                              //onChange={(e) => handleBatchClick(e, batch)}
                            />
                          </td>
                          {cols.map((col) => (
                            <td key={col.key}>{v[col.key] || NATEXT}</td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </ScrollableDiv>
              <PaginationWrap className="my-2 mx-2 p-0">
                <Pagination>
                  <Pagination.First
                    disabled={pageIndex === 0}
                    className="pagination__item--arrow"
                    onClick={async () => {
                      setPageIndex(0);
                    }}
                  >
                    {/* <ChevronDoubleLeftIcon /> */}
                    <Icon className="fi fi-rr-angle-double-small-left"></Icon>
                  </Pagination.First>
                  <Pagination.Prev
                    disabled={pageIndex === 0}
                    className="pagination__item--arrow"
                    onClick={async () => {
                      let temp = pageIndex;
                      setPageIndex((prev) => prev - 1);
                    }}
                  >
                    {/* <ChevronLeftIcon /> */}
                    <Icon className="fi fi-rr-angle-small-left"></Icon>
                  </Pagination.Prev>
                  {pageIndexArr?.map((i) => (
                    <Pagination.Item
                      id="page"
                      key={i}
                      active={pageIndex === i}
                      onClick={async () => {
                        setPageIndex(i);
                      }}
                    >
                      {i + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    disabled={pageIndex === pageIndexArr.length - 1}
                    className="pagination__item--arrow"
                    onClick={async () => {
                      let temp = pageIndex;
                      setPageIndex((prev) => prev + 1);
                    }}
                  >
                    {/* <ChevronRightIcon /> */}
                    <Icon className="fi fi-rr-angle-small-right"></Icon>
                  </Pagination.Next>
                  <Pagination.Last
                    disabled={pageIndex === pageIndexArr.length - 1}
                    className="pagination__item--arrow"
                    onClick={async () => {
                      setPageIndex(pageIndexArr.length - 1);
                    }}
                  >
                    {/* <ChevronDoubleRightIcon /> */}
                    <Icon className="fi fi-rr-angle-double-small-right"></Icon>
                  </Pagination.Last>
                </Pagination>
                <PaginationInfo>
                  {/* Showing {pageSize * pageIndex + 1} to{" "}
        {pageSize * pageIndex + page.length} of {noOfRows} */}
                  {t("showing_from_to_no_of_rows", {
                    from: totalRecords > 0 ? pageSize * pageIndex + 1 : 0,
                    to: Math.min(pageSize * (pageIndex + 1), totalRecords),
                    noOfRows: totalRecords,
                  })}
                </PaginationInfo>
                <PaginationSelectWrap>
                  <PaginationFormSelect
                    name="select"
                    id="exampleSelect"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(e.target.value);
                      setPageIndex(0);
                    }}
                  >
                    {manualPageSize.map((item) => (
                      <PaginationFormOption
                        key={item}
                        value={item}
                        id="page-size"
                      >
                        {/* Show {item} */}
                        {t("show_no_of_rows", { noOfRows: item })}
                      </PaginationFormOption>
                    ))}
                  </PaginationFormSelect>
                </PaginationSelectWrap>
              </PaginationWrap>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            className="d-flex justify-content-end"
            style={{ width: "-webkit-fill-available" }}
          >
            <AddFormButton
              id="import-assets-button"
              type="button"
              onClick={handleImportAssets}
            >
              {importBtnLoading ? <CustomLoader /> : t("import_assets")}
            </AddFormButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImportNewVehiclesPopup;
