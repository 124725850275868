import React, { useContext, useState } from "react";
import FormHeader from "../../shared/components/form/FormHeader";
import {
  FormContainer,
  FormGroupLabel,
  FormItem,
  UnitSpan,
} from "../../shared/components/form/FormElements";
import { Col, Container, Row } from "react-bootstrap";
import { Card, CardBody } from "@/shared/components/Card";
import { Controller, useForm } from "react-hook-form";
import { formInputStyles, formStyles } from "../../shared/constants/styles";
import { useSelector } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import FormField from "../../shared/components/form/FormField";
import { CustomInput } from "../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import {
  getDateFnsLocale,
  getDateWithTime,
  getInitialStartTime,
  handleNegative,
} from "../../shared/helpers";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Error from "@/shared/components/form/Error";
import getUnixTime from "date-fns/getUnixTime";
import { addMsg } from "../../features/snackbar/snackbarSlice";
import { postApi } from "../../api/getUser";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { DeletedContext } from "../../containers/App/Router/WrappedRoutes";
import FileUploader from "../../shared/components/form/FileUploader";
import { FileUploaderContainer } from "../Issues/IssuesForm";
import ImageWithZoom from "../../shared/components/form/ImageWithZoom";
import format from "date-fns/format";
import jsPDF from "jspdf";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import {
  ALBUM_BUCKET_NAME,
  BUCKET_REGION,
  IDENTITY_POOL_ID,
} from "../../shared/constants";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import ErrorPopup from "../../shared/components/ErrorPopup";

const DailyTimeSheetForm = () => {
  const history = useHistory();
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation("common");

  const vehiclesList = useSelector((state) => state.vehicles.list);

  const vehicles = vehiclesList
    .slice(1, vehiclesList.length)
    .filter((v) => v.veh_active === 1);

  const users = useSelector((state) => state.users.list);
  const { userId, orgId } = useSelector((state) => state.user);

  //hook-form variables
  const vehicle = watch("vehicle");
  const operator = watch("operator");
  const date = watch("date");
  const time = watch("time");

  const [timeSheetImgs, setTimeSheetImgs] = useState([]);

  const rowData = location?.state?.data?.rowData;
  const screen = location?.state?.screen;
  const type = location?.state?.type;

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const [imgsErr, setImgsErr] = useState("");

  const [userOptions, setUserOptions] = useState([]);

  //To toggle error popup while adding/editing form
  const [showErrPopup, setShowErrPopup] = useState(false);

  //Effect to set user options
  useEffect(() => {
    (async () => {
      if (vehicle) {
        const apiData = {
          veh_id: vehicle?.vehicleId,
          querystring: "getvehicleoperators",
        };
        const res = await postApi(apiData, "commonNew");
        if (res.success) {
          const { user_data } = res;
          if (user_data?.length > 0) {
            let temp = [];
            for (let user of user_data) {
              let filteredUser = users.find((u) => u.user_id === user.user_id);
              temp.push(filteredUser);
            }
            setUserOptions(temp);
          }
        }
      }
    })();
  }, [vehicle, users]);

  useEffect(() => {
    setValue(
      "operator",
      users.find((u) => u.user_id === userId)
    );
    setValue("date", new Date());
    setValue("time", getInitialStartTime());
  }, [users, userId]);

  const uploadPdfToS3 = async (doc) => {
    try {
      const client = new S3Client({
        region: "us-east-2",
        credentials: fromCognitoIdentityPool({
          identityPoolId: IDENTITY_POOL_ID,
          client: new CognitoIdentityClient({ region: BUCKET_REGION }),
        }),
        apiVersion: "2006-03-01",
      });

      const command = new PutObjectCommand({
        Bucket: ALBUM_BUCKET_NAME,
        Key: doc.key,
        Body: doc.file,
        ContentDisposition: "inline",
        ContentType: "application/pdf",
        //ACL: "public-read",
      });
      const response = await client.send(command);
      const statusCode = response.$metadata.httpStatusCode;
      console.log("statusCode in method", statusCode);
      return statusCode;
      //statusCodeArr.push(statusCode);
    } catch (err) {
      console.error("s3 error", err);
      return err;
    }
  };

  const onSubmit = async () => {
    try {
      if (timeSheetImgs.length === 0) {
        setImgsErr(t("one_doc_reqd_err_msg"));
        return;
      }
      setSaveBtnLoading(true);
      const timeSheetDateUnix = getDateWithTime(date, time);
      const timeSheetDate = new Date(timeSheetDateUnix * 1000);
      const day = format(timeSheetDate, "d");
      const month = format(timeSheetDate, "LLL");
      const year = format(timeSheetDate, "yy");

      const timeSheetImgName = `${day}_${month}_${year}_${vehicle?.name?.replace(
        /\s/g,
        ""
      )}_${operator?.name?.replace(/\s/g, "")}.pdf`;

      let docToUpload = {
        key: `image_data/org_${orgId}/user_${userId}/daily_sheet/${timeSheetImgName}`,
        file: null,
      };

      if (timeSheetImgs.length > 0) {
        if (timeSheetImgs[0]?.file?.type?.includes("image")) {
          const doc = new jsPDF("l", "pt", "a4");
          const width = doc.internal.pageSize.getWidth();
          const height = doc.internal.pageSize.getHeight();
          timeSheetImgs.forEach((img, i) => {
            const { file } = img;
            doc.addImage(img.url, file.type, 0, 0, width, height);
            if (i + 1 < timeSheetImgs?.length) {
              doc.addPage();
            }
          });
          //doc.save('abc.pdf');
          const pdfDoc = doc.output("blob");
          docToUpload.file = pdfDoc;
        }

        if (timeSheetImgs[0]?.file?.type?.includes("pdf")) {
          docToUpload.file = timeSheetImgs[0].file;
        }
      }

      const apiData = {
        timesheet_date: timeSheetDateUnix,
        source: "web",
        timesheet_img: timeSheetImgName,
        veh_id: vehicle?.vehicleId,
        dts_user_id: operator?.user_id,
        querystring: "insert_daily_sheet",
      };

      const res = await postApi(apiData, "commonNew");
      if (res.success) {
        const statusCode = await uploadPdfToS3(docToUpload);
        if (statusCode === 200) {
          setSaveBtnLoading(false);
          //history.goBack();
          history.push("/dailytimesheet", { fromCancel: false });
        }
      } else {
        setSaveBtnLoading(false);
        setShowErrPopup(true);
        return;
      }
    } catch (e) {
      setSaveBtnLoading(false);
      setShowErrPopup(true);
      return;
    }
  };

  return (
    <>
      {showErrPopup && (
        <ErrorPopup showPopup={showErrPopup} setShowPopup={setShowErrPopup} />
      )}
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormHeader
          //title={t("add_service")}
          handleCancel={() =>
            history.push("/dailytimesheet", { fromCancel: true })
          }
          disableSave={!vehicle}
          isSaveLoading={saveBtnLoading}
          title={type === "edit" ? t("edit_timesheet") : t("add_timesheet")}
          //handleCancel={handleCancel}
        />
        <Container>
          {/* <Row> */}
          {/* <Col> */}
          <Card>
            <CardBody
              style={{
                height: `${window.innerHeight - 150}px`,
                overflow: "auto",
              }}
            >
              <Row>
                <Col md={3}>
                  <FormGroupLabel>{t("vehicle")}</FormGroupLabel>
                  <Controller
                    name="vehicle"
                    control={control}
                    //errors={errors}
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        options={vehicles}
                        //value={vehicles.find((c) => c.value === value)}
                        value={value}
                        onChange={onChange}
                        //onCreateOption={handleCreate}
                        placeholder={t("vehicle_placeholder")}
                        styles={formStyles}
                        isDisabled={false}
                      />
                    )}
                    rules={{ required: t("required_err") }}
                  />

                  {errors?.vehicle && <Error error={errors.vehicle.message} />}
                </Col>

                {vehicle && (
                  <Col md={3}>
                    <FormGroupLabel>{t("operator")}</FormGroupLabel>
                    <Controller
                      name="operator"
                      control={control}
                      //errors={errors}
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          options={userOptions}
                          //defaultValue={signedInUser}
                          //value={vehicles.find((c) => c.value === value)}
                          value={value}
                          onChange={onChange}
                          //onCreateOption={handleCreate}
                          //placeholder={t("vehicle_placeholder")}
                          styles={formStyles}
                          //isDisabled={status == 1 || status == 2}
                        />
                      )}
                      rules={{ required: t("required_err") }}
                    />

                    {errors?.operator && (
                      <Error error={errors.operator.message} />
                    )}
                  </Col>
                )}
              </Row>

              {vehicle && (
                <>
                  <Row>
                    <Col md={3}>
                      <FormGroupLabel>{t("date_tv")}</FormGroupLabel>
                      <Controller
                        name="date"
                        defaultValue={new Date()}
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <DatePicker
                            //minDate={new Date()}
                            selected={value}
                            onChange={onChange}
                            customInput={
                              <CustomInput
                              //disabled={status == 1 || status == 2}
                              />
                            }
                            placeholderText={t("date_tv")}
                            locale={getDateFnsLocale()}
                            dateFormat={"P"}
                            dropdownMode="scroll"
                            maxDate={new Date()}
                            showMonthDropdown
                            showYearDropdown
                            //disabled={status == 1 || status == 2}
                          />
                        )}
                        rules={{
                          required: t("required_err"),
                        }}
                      />
                      {errors?.date && <Error error={errors.date.message} />}
                    </Col>
                    <Col md={3}>
                      <FormGroupLabel>{t("time_tv")}</FormGroupLabel>
                      <FormItem disabled={false}>
                        <FormField
                          name="time"
                          control={control}
                          component="input"
                          type="time"
                          //defaultValue={getInitialStartTime}
                          placeholder={t("time_tv")}
                          style={formInputStyles}
                          disabled={false}
                        />

                        {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                      </FormItem>

                      {errors?.time && <Error error={errors.time.message} />}
                    </Col>
                  </Row>

                  <Row
                  //className="px-1 pt-2 pb-3"
                  >
                    <FormGroupLabel>{t("images")}</FormGroupLabel>
                    <Col md={6}>
                      <FileUploaderContainer>
                        {timeSheetImgs?.map((img) => {
                          return (
                            <ImageWithZoom
                              key={img.id}
                              img={img}
                              //handleDeleteImg={handleDeleteImg}
                              images={timeSheetImgs}
                              setImages={setTimeSheetImgs}
                              shouldDelete={true}
                            />
                          );
                        })}

                        {!timeSheetImgs[0]?.file?.type?.includes("pdf") && (
                          <FileUploader
                            //handleFileUpload={handleFileUpload}
                            disabled={false}
                            images={timeSheetImgs}
                            setImages={setTimeSheetImgs}
                          />
                        )}
                      </FileUploaderContainer>
                      {imgsErr && <Error error={imgsErr} />}
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      </FormContainer>
    </>
  );
};

export default DailyTimeSheetForm;
