import React, { useContext, useRef, useState } from "react";
import FormHeader from "../../shared/components/form/FormHeader";
import {
  AutoResizeTextArea,
  FormContainer,
  FormGroupLabel,
  FormItem,
  HintText,
  UnitSpan,
} from "../../shared/components/form/FormElements";
import { Col, Container, Row } from "react-bootstrap";
import { Card, CardBody } from "@/shared/components/Card";
import { Controller, useForm } from "react-hook-form";
import { formInputStyles, formStyles } from "../../shared/constants/styles";
import { useSelector } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import FormField from "../../shared/components/form/FormField";
import { CustomInput } from "../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import {
  getDateFnsLocale,
  getDateWithTime,
  getDistanceFactorforOdo,
  getEditUsers,
  handleNegative,
  handleNumberInputOnWheelPreventChange,
  handleVehicleOnChange,
  odoValidation,
} from "../../shared/helpers";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Error from "@/shared/components/form/Error";
import getUnixTime from "date-fns/getUnixTime";
import { addMsg } from "../../features/snackbar/snackbarSlice";
import { post, postApi } from "../../api/getUser";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { DeletedContext } from "../../containers/App/Router/WrappedRoutes";
import useFetchOdoHint from "../../shared/custom-hooks/useFetchOdoHint";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import getHours from "date-fns/getHours";
import { useAuth } from "../../contexts/AuthContext";
import CheckboxInputComponent from "../../shared/components/form/CheckboxInputComponent";
import ErrorPopup from "../../shared/components/ErrorPopup";
import useAutosizeTextArea from "../../shared/custom-hooks/useAutosizeTextArea";

const DailyMileageForm = () => {
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { toggleReload } = useContext(DeletedContext);
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const vehicles = vehiclesList
    .slice(1, vehiclesList.length)
    .filter((v) => v.veh_active === 1);
  const users = useSelector((state) => state.users.list);
  const { userId, orgId } = useSelector((state) => state.user);

  const rowData = location?.state?.data?.rowData;
  const screen = location?.state?.screen;
  const type = location?.state?.type;

  const { currency, distance } = useSelector((state) => state.units);

  const { fetchTelematicsOdo } = useAuth();

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  //hook-form variables
  const vehicle = watch("vehicle");
  const startingOdo = watch("startingOdo");
  const endingOdo = watch("endingOdo");
  const operator = watch("operator");
  const date = watch("date");
  const comments = watch("comments");
  const odoDistance = watch("odoDistance");

  const textAreaRef = useRef(null);
  useAutosizeTextArea(textAreaRef.current, comments);

  const { odoHint, odoUnit, lastOdoValue } = useFetchOdoHint({
    vehicle,
    date,
    time: "00:01",
    filterDateEnd: getUnixTime(startOfDay(date)),
  });

  //const [highOdoMsg, setHighOdoMsg] = useState("");
  const [showHighStartingOdoErr, setShowHighStartingOdoErr] = useState(false);
  const [showHighEndingOdoErr, setShowHighEndingOdoErr] = useState(false);
  const firstTimeEdit = useRef(false);

  const [userOptions, setUserOptions] = useState([]);

  const [startingOdoFetchedMsg, setStartingOdoFetchedMsg] = useState("");
  const [endingOdoFetchedMsg, setEndingOdoFetchedMsg] = useState("");

  const [odoNotReqd, setOdoNotReqd] = useState(false);

  //To toggle error popup while adding/editing form
  const [showErrPopup, setShowErrPopup] = useState(false);

  //Effects - Start

  //Effect to fetch telematics odo
  useEffect(() => {
    (async () => {
      if (vehicle && type !== "edit" && fetchTelematicsOdo && !odoNotReqd) {
        let odoSetter = null;
        let odoKey = "";
        const hours = getHours(new Date());
        if (hours >= 12) {
          odoSetter = setEndingOdoFetchedMsg;
          odoKey = "endingOdo";
        } else {
          odoSetter = setStartingOdoFetchedMsg;
          odoKey = "startingOdo";
        }
        if (vehicle.provider_type_1) {
          setValue(odoKey, "");

          odoSetter(t("fetching_data_via_telematics"));

          const odoApiData = {
            querystring: "get_current_obd_odometer",
            veh_id: vehicle.veh_id,
            provider_type: 1,
          };
          const res = await post("integrations", odoApiData);
          if (res.success) {
            let odoVal = +res.data.odometer_in_meters;
            if (+odoVal) {
              odoVal = odoVal?.toFixed(2);
              setValue(odoKey, odoVal);
            }
            odoSetter(t("fetched_via_telematics_device"));
          } else {
            setValue(odoKey, "");
            odoSetter("");
          }
        } else {
          setValue(odoKey, "");
          odoSetter("");
        }
      }
    })();
  }, [vehicle]);

  //Effect to set high odo warning for starting odo !!after odo is fetched from telematics!!
  useEffect(() => {
    if (vehicle && vehicle.provider_type_1 && type !== "edit") {
      if (
        +startingOdo &&
        +lastOdoValue &&
        +startingOdo - +lastOdoValue > 1000
      ) {
        setShowHighStartingOdoErr(true);
      } else {
        setShowHighStartingOdoErr(false);
      }
    }
  }, [vehicle, lastOdoValue, startingOdo]);

  //Effect to set high ending odo warning
  useEffect(() => {
    if (
      startingOdo &&
      endingOdo &&
      +endingOdo - +startingOdo > 1000 &&
      !firstTimeEdit.current
    ) {
      setShowHighEndingOdoErr(true);
    } else {
      setShowHighEndingOdoErr(false);
    }

    if (type === "edit" && firstTimeEdit.current) {
      setShowHighEndingOdoErr(false);
      firstTimeEdit.current = false;
    }
  }, [endingOdo, startingOdo]);

  //Effect to set user options
  // useEffect(() => {
  //   (async () => {
  //     if (vehicle) {
  //       const apiData = {
  //         veh_id: vehicle?.vehicleId,
  //         querystring: "getvehicleoperators",
  //       };
  //       const res = await postApi(apiData, "commonNew");
  //       if (res.success) {
  //         const { user_data } = res;
  //         if (user_data?.length > 0) {
  //           let temp = [];
  //           for (let user of user_data) {
  //             let filteredUser = users.find((u) => u.user_id === user.user_id);
  //             temp.push(filteredUser);
  //           }
  //           setUserOptions(temp);
  //         }
  //       }
  //     }
  //   })();
  // }, [vehicle, users]);

  //Effect to set current initial values in Add Mileage
  useEffect(() => {
    if (type !== "edit") {
      setValue(
        "operator",
        users.find((u) => u.user_id === userId)
      );
      setValue("date", new Date());
    }
  }, [users]);

  //Effect on edit
  useEffect(() => {
    if (type === "edit") {
      firstTimeEdit.current = true;
      setValue(
        "vehicle",
        vehicles.find((v) => v.vehicleId === rowData?.veh_id)
      );

      //Set user and user options
      (async () => {
        const editUsers = await getEditUsers(rowData?.veh_id, rowData?.user_id);
        if (editUsers?.length > 0) {
          const selectedUser = editUsers?.find(
            (u) => u.user_id === rowData?.user_id
          );
          setValue("operator", selectedUser);
          setUserOptions(editUsers);
        }
      })();

      const distanceFactor = getDistanceFactorforOdo(
        rowData?.primary_meter,
        distance
      );

      const startingOdoUpd = rowData.starting_odo
        ? (rowData?.starting_odo / distanceFactor)?.toFixed(2)
        : 0;

      const endingOdoUpd = rowData.ending_odo
        ? (rowData?.ending_odo / distanceFactor)?.toFixed(2)
        : 0;

      if (rowData?.odo_require) {
        console.log("endingOdo", endingOdoUpd);
        setValue("startingOdo", startingOdoUpd);
        setValue("endingOdo", endingOdoUpd);
      } else {
        setValue("odoDistance", +endingOdoUpd - +startingOdoUpd);
        setOdoNotReqd(true);
      }

      setValue("date", new Date(rowData?.timestamp * 1000));

      setValue("comments", rowData?.comments);
    }
  }, [rowData, vehiclesList]);

  //Effects - End

  const handleOdoNotReqdChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setValue("startingOdo", "");
      setValue("endingOdo", "");
      if (showHighStartingOdoErr) {
        setShowHighStartingOdoErr(false);
      }
      if (showHighEndingOdoErr) {
        setShowHighEndingOdoErr(false);
      }
      if (startingOdoFetchedMsg) {
        setStartingOdoFetchedMsg("");
      }
      if (endingOdoFetchedMsg) {
        setEndingOdoFetchedMsg("");
      }
      clearErrors("startingOdo");
      clearErrors("endingOdo");
    }
    setOdoNotReqd((prev) => !prev);
  };

  const onSubmit = async () => {
    try {
      if (endingOdo && +startingOdo > +endingOdo) {
        setError("endingOdo", {
          type: "max",
          message: "Ending Odo must be greater than or equal to Starting Odo",
        });
        return;
      }
      const snackbarMsg =
        type === "edit"
          ? t("record_edited_msg", { recordName: t("se_mileage") })
          : t("record_added_msg", { recordName: t("se_mileage") });
      let mileageId =
        type === "edit" ? rowData?._id : `${vehicle.vehicleId}_${Date.now()}`;

      setSaveBtnLoading(true);
      //Odo Validation
      const distanceFactor = getDistanceFactorforOdo(
        vehicle?.primary_meter,
        distance
      );

      let startingOdoVal = startingOdo;
      let endingOdoVal = endingOdo;

      //If odo not reqd, then starting and ending odo values calculated as follows
      if (odoNotReqd) {
        startingOdoVal = lastOdoValue;
        endingOdoVal = lastOdoValue + +odoDistance;
      }

      let startingOdoConverted = startingOdoVal * distanceFactor;
      let endingOdoConverted = endingOdoVal * distanceFactor;

      //Do odo validaton only if odo not reqd is unchecked(odo is reqd)
      if (!odoNotReqd) {
        //Odo Validation

        //startingOdo
        const startingOdoCheckApiData = {
          odo: startingOdoConverted,
          veh_id: vehicle.vehicleId,
          datetocheck: getUnixTime(startOfDay(date)), //getDateWithTime(date, "00:01"),
          querystring: "get_around_dates_v3",
          check_from: "starting_ending_mileage",
          check_id: type === "edit" ? rowData._id : "",
        };

        const startingOdoValidationRes = await odoValidation(
          "startingOdo",
          startingOdoCheckApiData,
          setError,
          setSaveBtnLoading,
          t,
          distanceFactor,
          startingOdo
        );

        if (!startingOdoValidationRes) {
          return;
        }

        //endingOdo
        if (endingOdo) {
          const endingOdoCheckApiData = {
            odo: endingOdoConverted,
            veh_id: vehicle.vehicleId,
            datetocheck: getUnixTime(endOfDay(date)), //getDateWithTime(date, "23:59"),
            querystring: "get_around_dates_v3",
            check_from: "starting_ending_mileage",
            check_id: type === "edit" ? rowData._id : "",
          };

          const endingOdoValidationRes = await odoValidation(
            "endingOdo",
            endingOdoCheckApiData,
            setError,
            setSaveBtnLoading,
            t,
            distanceFactor,
            endingOdo
          );

          if (!endingOdoValidationRes) {
            return;
          }
        }
      }

      const apiData = {
        mileage_user_id: operator.user_id,
        starting_odo: startingOdoConverted,
        ending_odo: endingOdoConverted,
        timestamp: getUnixTime(endOfDay(date)), //getUnixTime(date),
        comments: comments,
        mileage_veh_id: vehicle?.vehicleId,
        mileage_id: mileageId,
        querystring: type === "edit" ? "update_mileage_v2" : "insert_mileage",
        action: type === "edit" ? 2 : 1,
        sync_version: "v2",
        odo_require: odoNotReqd ? 0 : 1,
      };

      const response = await postApi(apiData, "commonNew");
      if (response.success === 1) {
        setSaveBtnLoading(false);
        history.push("/dailymileage", { fromCancel: false });
        dispatch(addMsg(snackbarMsg));
      } else {
        setSaveBtnLoading(false);
        setShowErrPopup(true);
        return;
      }
    } catch (e) {
      setSaveBtnLoading(false);
      setShowErrPopup(true);
      return;
    }
  };

  return (
    <>
      {showErrPopup && (
        <ErrorPopup showPopup={showErrPopup} setShowPopup={setShowErrPopup} />
      )}
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormHeader
          //title={t("add_service")}
          handleCancel={() =>
            history.push("/dailymileage", { fromCancel: true })
          }
          disableSave={!vehicle}
          isSaveLoading={saveBtnLoading}
          title={type === "edit" ? t("edit_sem") : t("add_sem")}
          //handleCancel={handleCancel}
        />
        <Container>
          {/* <Row> */}
          {/* <Col> */}
          <Card>
            <CardBody
              style={{
                height: `${window.innerHeight - 150}px`,
                overflow: "auto",
              }}
            >
              <Row>
                <Col md={3}>
                  <FormGroupLabel>{t("vehicle")}</FormGroupLabel>
                  <Controller
                    name="vehicle"
                    control={control}
                    //errors={errors}
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        options={vehicles}
                        //value={vehicles.find((c) => c.value === value)}
                        value={value}
                        //onChange={onChange}
                        onChange={(v) =>
                          handleVehicleOnChange(
                            v,
                            onChange,
                            "operator",
                            setValue,
                            setUserOptions,
                            userId
                          )
                        }
                        placeholder={t("vehicle_placeholder")}
                        styles={formStyles}
                        isDisabled={false}
                      />
                    )}
                    rules={{ required: t("required_err") }}
                  />

                  {errors?.vehicle && <Error error={errors.vehicle.message} />}
                </Col>

                {vehicle && (
                  <Col md={3}>
                    <FormGroupLabel>{t("entered_by")}</FormGroupLabel>
                    <Controller
                      name="operator"
                      control={control}
                      //errors={errors}
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          options={userOptions}
                          //defaultValue={signedInUser}
                          //value={vehicles.find((c) => c.value === value)}
                          value={value}
                          onChange={onChange}
                          //onCreateOption={handleCreate}
                          //placeholder={t("vehicle_placeholder")}
                          styles={formStyles}
                          //isDisabled={status == 1 || status == 2}
                        />
                      )}
                      rules={{ required: t("required_err") }}
                    />

                    {errors?.operator && (
                      <Error error={errors.operator.message} />
                    )}
                  </Col>
                )}
              </Row>
              {vehicle && (
                <>
                  <Row>
                    <Col md={3}>
                      <FormGroupLabel>{t("date_tv")}</FormGroupLabel>
                      <Controller
                        name="date"
                        //defaultValue={new Date()}
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <DatePicker
                            //minDate={new Date()}
                            maxDate={new Date()}
                            selected={value}
                            onChange={onChange}
                            customInput={
                              <CustomInput
                              //disabled={status == 1 || status == 2}
                              />
                            }
                            placeholderText={t("date_tv")}
                            showMonthDropdown
                            showYearDropdown
                            locale={getDateFnsLocale()}
                            dateFormat={"P"}
                            //disabled={status == 1 || status == 2}
                          />
                        )}
                        rules={{
                          required: t("required_err"),
                        }}
                      />
                      {errors?.date && <Error error={errors.date.message} />}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <FormGroupLabel>{t("starting_odo")}</FormGroupLabel>
                      <FormItem
                        showWarning={showHighStartingOdoErr}
                        disabled={odoNotReqd}
                        //tabIndex={0}
                      >
                        <FormField
                          name="startingOdo"
                          control={control}
                          component="input"
                          type="number"
                          onKeyDown={(e) => handleNegative(e)}
                          onWheel={handleNumberInputOnWheelPreventChange}
                          onKeyUp={(e) => {
                            const { value } = e.target;
                            if (
                              value &&
                              lastOdoValue &&
                              +value - +lastOdoValue > 1000
                            ) {
                              setShowHighStartingOdoErr(true);
                            } else {
                              setShowHighStartingOdoErr(false);
                            }
                          }}
                          onChange={(e) => {
                            clearErrors("startingOdo");
                            setValue("startingOdo", e.target.value);
                            if (startingOdoFetchedMsg) {
                              setStartingOdoFetchedMsg("");
                            }
                          }}
                          rules={{
                            required: odoNotReqd ? false : t("required_err"),
                          }}
                          defaultValue=""
                          //isAboveError={isAboveError}
                          placeholder={t("starting_odo")}
                          style={formInputStyles}
                          disabled={odoNotReqd}
                          //onKeyUp={handleSIOdoChange}
                        />
                        <UnitSpan>{odoUnit}</UnitSpan>
                        {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                      </FormItem>
                      {startingOdoFetchedMsg && (
                        <HintText>{startingOdoFetchedMsg}</HintText>
                      )}
                      {odoHint && <HintText>{odoHint}</HintText>}
                      {showHighStartingOdoErr && (
                        <Error error={t("high_odo_err_msg")} />
                      )}

                      {errors?.startingOdo && (
                        <Error error={errors.startingOdo.message} />
                      )}
                      {/* {highOdoMsg ? (
                      <Error error={highOdoMsg} />
                    ) : (
                      errors?.startingOdo && (
                        <Error error={errors.startingOdo.message} />
                      )
                    )} */}
                      {/* {errors?.startingOdo ? (
                      <Error error={errors.startingOdo.message} />
                    ) : (
                      showHighStartingOdoErr && <Error error={t("high_odo_err_msg")} />
                    )} */}
                    </Col>

                    <Col md={3}>
                      <FormGroupLabel>{t("ending_odo")}</FormGroupLabel>
                      <FormItem
                        showWarning={showHighEndingOdoErr}
                        disabled={odoNotReqd}
                      >
                        <FormField
                          name="endingOdo"
                          control={control}
                          component="input"
                          type="number"
                          onKeyDown={(e) => handleNegative(e)}
                          onWheel={handleNumberInputOnWheelPreventChange}
                          rules={{ required: false }}
                          defaultValue=""
                          placeholder={t("ending_odo")}
                          style={formInputStyles}
                          onChange={(e) => {
                            clearErrors("endingOdo");
                            setValue("endingOdo", e.target.value);
                            if (endingOdoFetchedMsg) {
                              setEndingOdoFetchedMsg("");
                            }
                          }}
                          disabled={odoNotReqd}
                          //onKeyUp={handleSIOdoChange}
                        />
                        <UnitSpan>{odoUnit}</UnitSpan>
                      </FormItem>
                      {endingOdoFetchedMsg && (
                        <HintText>{endingOdoFetchedMsg}</HintText>
                      )}
                      {showHighEndingOdoErr && (
                        <Error error={t("high_odo_err_msg")} />
                      )}

                      {errors?.endingOdo && (
                        <Error error={errors.endingOdo.message} />
                      )}
                    </Col>

                    <Col>
                      <div style={{ marginTop: "2.75rem" }}>
                        <CheckboxInputComponent
                          checked={odoNotReqd}
                          setChecked={setOdoNotReqd}
                          onChange={handleOdoNotReqdChange}
                          label={t("enter_total_distance_driven")}
                        />
                      </div>
                    </Col>
                  </Row>

                  {odoNotReqd && (
                    <Row>
                      <Col md={3}>
                        <FormGroupLabel>{t("distance")}</FormGroupLabel>
                        <FormItem>
                          <FormField
                            name="odoDistance"
                            control={control}
                            component="input"
                            type="number"
                            onKeyDown={(e) => handleNegative(e)}
                            onWheel={handleNumberInputOnWheelPreventChange}
                            rules={{
                              required: odoNotReqd ? t("required_err") : false,
                            }}
                            defaultValue=""
                            placeholder={t("distance")}
                            style={formInputStyles}
                            //disabled={odoNotReqd}
                            //onKeyUp={handleSIOdoChange}
                          />
                          <UnitSpan>{odoUnit}</UnitSpan>
                        </FormItem>

                        {errors?.odoDistance && (
                          <Error error={errors.odoDistance.message} />
                        )}
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col md={6}>
                      <FormGroupLabel>{t("notes_tv")}</FormGroupLabel>
                      {/* <FormItem disabled={false}>
                        <FormField
                          name="comments"
                          control={control}
                          component="input"
                          defaultValue=""
                          placeholder={t("notes_tv")}
                          style={formInputStyles}
                          disabled={false}
                        />
                      </FormItem> */}

                      <Controller
                        name="comments"
                        control={control}
                        //defaultValue={users?.length > 0 ? users[0] : ""}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <AutoResizeTextArea
                            ref={textAreaRef}
                            onChange={onChange}
                            value={value}
                            placeholder={t("notes_tv")}
                            rows={1}
                            //isDisabled={status == 1 || status == 2}
                          />
                        )}
                        //rules={{ required: t("required_err") }}
                      />

                      {errors?.comments && (
                        <Error error={errors.comments.message} />
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
          {/* </Col> */}
          {/* </Row> */}
        </Container>
      </FormContainer>
    </>
  );
};

export default DailyMileageForm;
